import reducer from './seller.reducer';

export {
  SellerTypes,
  getSeller,
  getSellerPage,
  getSellerFilter,
} from './seller.action';

export default reducer;
