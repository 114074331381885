import { toastr } from 'react-redux-toastr';
import Api from '../../services/api';

export const SellerTypes = {
  FETCH: '@seller/fetch',
  ERROR: '@seller/error',
  GET_RECEIVE: '@seller/get/receive',

  PAGE_FETCH: '@seller/page/fetch',
  PAGE_ERROR: '@seller/page/error',
  PAGE_RECEIVE: '@seller/page/receive',

  FILTERS_FETCH: '@seller/filters/fetch',
  FILTERS_ERROR: '@seller/filters/error',
  FILTERS_RECEIVE: '@seller/filters/receive',

};

let objOfAlreadyUsingFilters = '';

const toastrOptions = {
  onToastrClick: () => window.location.replace('https://app.mercadoacelerado.com.br/select-change-seller'),
};

export const getSeller = () => (dispatch) => {
  dispatch({ type: SellerTypes.FETCH });

  Api.get('/seller?limit=all')
    .then((resp) => {
      const payload = resp.data;
      dispatch({ type: SellerTypes.GET_RECEIVE, payload });
    }).catch((err) => {
      toastr.error('Falha ao carregar vendas.');
      const payload = err.response.data;
      dispatch({ type: SellerTypes.ERROR, payload });
    });
};

export const getSellerPage = ({
  page = 1,
  limit = 10,
  search = '',
  querys = [],
  order = null,
  orderby = null,
  userIdRedux = 371,
} = {}) => (dispatch) => {
  dispatch({ type: SellerTypes.PAGE_FETCH });
  console.log(userIdRedux);

  const query = [
    `limit=${limit}`,
    `page=${page}`,
    order && `order=${order}`,
    orderby && `orderby=${orderby}`,
    querys && querys.map((q) => `${q.key}=${q.value}`).join('&'),
    search && `search=${search}`,
    userIdRedux && `userIdRedux=${userIdRedux}`,
  ]
    .filter((q) => q)
    .join('&');

  console.log(query);
  Api.get(`/seller?${query}`)
    .then((resp) => {
      const payload = resp.data;
      console.log(payload);
      dispatch({ type: SellerTypes.PAGE_RECEIVE, payload });
    }).catch((err) => {
      toastr.error('Falha ao carregar vendas.');
      const payload = err.response.data;
      dispatch({ type: SellerTypes.PAGE_ERROR, payload });
    });
};

export const getSellerFilter = ({ id, valueOfFilter, keyOfFilter }) => (dispatch) => {
  dispatch({ type: SellerTypes.FILTERS_FETCH });
  const { value } = valueOfFilter;

  if (value) {
    const { id: idValue } = JSON.parse(value);
    objOfAlreadyUsingFilters = { ...objOfAlreadyUsingFilters, [keyOfFilter.key]: idValue };
  }

  Api.post(`/seller/filters/${id}`, { objOfAlreadyUsingFilters })
    .then((resp) => {
      const payload = resp.data;
      dispatch({ type: SellerTypes.FILTERS_RECEIVE, payload });
    }).catch((err) => {
      toastr.error('Você não possui monitoramentos ativos. Clique aqui para ativar.', toastrOptions);
      const payload = err.response.data;
      dispatch({ type: SellerTypes.FILTERS_ERROR, payload });
    });
};
