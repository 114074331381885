/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable import/prefer-default-export */
import Api from './api';

export function recoverPassword(email) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api.post('auth/recoverPassword', { email });
      return resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}
// e4633202209c827f85e617988c22871e4fc10cd6
export function confirmCode(code, email) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api.post('auth/confirmCode', { code, email });
      return resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getMLAccessToken(id, code) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api.post('auth/accessToken', { userId: id, code });
      return resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function changePassword(id, newPassword, confirmationCode) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api.post('auth/newPassword', { id, newPassword, confirmationCode });
      return resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function mobileWarning(email) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api.get(`auth/mobileWarning/${email}`);
      return resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}
