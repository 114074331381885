import reducer from './layout.reducer';

export {
  LayoutTypes,
  changeLayout,
  changePreloader,
  changeSidebarTheme,
  changeLayoutWidth,
  changeSidebarType,
  changeTopbarTheme,
  activateNonAuthLayout,
  toggleSidebar,
  toggleRightSidebar,
  hideRightSidebar,
  changeTheme,
} from './layout.action';

export default reducer;
