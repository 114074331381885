import React from 'react';
import {
  // Row,
  Card,
  CardBody,
  // Alert,
  Button,
} from 'reactstrap';
import './index.module.scss';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutlined';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { mobileWarning } from '../../services/auth';

const AccountCreated = ({ location }) => {
  const history = useHistory();
  const email = useSelector(({ auth }) => auth.user.email);

  const handleSubmit = (e) => {
    e.preventDefault();
    sessionStorage.removeItem('@newlyCreated');
    history.push('/first-steps');
  };

  const handleSubmitBlock = async (e) => {
    e.preventDefault();
    sessionStorage.removeItem('@newlyCreated');
    const response = await mobileWarning(email);
    toast.success(response.msg);
  };

  return (
    <Media query={{ minWidth: '700px' }}>
      {(matches) => (matches ? (
        <Card
          style={{
            height: '100%',
            width: '60%',
            margin: '0 auto',
            borderRadius: '8px',
            overflow: 'hidden',
          }}
          className="h-100 justify-content-center"
        >

          <CardBody className="pt-2">
            <div>
              <h1 style={{ color: '#395C81', textAlign: 'center', fontSize: '2.5rem' }}>
                {location.state.msg}
              </h1>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <CheckCircleOutlineIcon className="mb-2" style={{ height: '160px', width: '160px', color: '#395C81' }} />
            </div>
            <div>

              <h2
                style={{
                  color: '#23364A',
                  textAlign: 'center',
                  fontSize: '1.2rem',
                  fontWeight: '500',
                }}
              >
                Comece agora mesmo e saiba como os dados
                podem te ajudar a tomar as melhores decisões

              </h2>
            </div>
            <div className="d-flex align-items-center justify-content-center py-3 mb-0">
              <Button
                color="primary"
                size="lg"
                style={{ height: '55px', width: '270px' }}
                className="w-md font-weight-bold waves-effect waves-light mt-2"
                onClick={handleSubmit}
              >
                Começar

              </Button>

            </div>

          </CardBody>

        </Card>
      ) : (
        <Card>
          <CardBody>
            <h1 style={{ textAlign: 'center', marginBottom: '1rem' }}>
              {location.state.msg}
            </h1>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CheckCircleOutlineIcon
                className="mb-2"
                style={{
                  height: '160px', width: '160px', color: '#395C81',
                }}
              />
            </div>
            <div style={{ textAlign: 'center', fontWeight: '650', color: '#23364A' }}>
              <p>A nossa plataforma foi criada para uso no computador.</p>
              <p>
                Abra em seu navegador de
                preferência e saiba como os dados
                podem te ajudar a tomar as melhores decisões
                {' '}
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-center py-3 mb-0">
              <Button
                color="primary"
                size="lg"
                style={{ height: '55px', width: '270px' }}
                className="w-md font-weight-bold waves-effect waves-light mt-2"
                onClick={handleSubmitBlock}
              >
                Enviar link no e-mail

              </Button>

            </div>

          </CardBody>
        </Card>
      )
      )}

    </Media>
  );
};
AccountCreated.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
};

export default AccountCreated;
