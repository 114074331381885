import React from 'react';
import { Card } from 'reactstrap';
import PropTypes from 'prop-types';
import styles from './plancard.module.scss';
import { ReactComponent as Silver } from '../../assets/images/SilverCheck.svg';
import { ReactComponent as Gold } from '../../assets/images/GoldCheck.svg';
import { ReactComponent as Platinum } from '../../assets/images/PlatinumCheck.svg';

const PlanCard = (props) => {
  const {
    plan, price, categoryNumber, empty,
  } = props;

  const colorHandler = {
    silver: {
      color: '#B3B3A1',
      badge: <Silver />,
    },
    gold: {
      color: '#ecb42d',
      badge: <Gold />,
    },
    platinum: {
      color: '#72848C',
      badge: <Platinum />,
    },
  };

  return (
    <>
      <Card className={[styles.mainCard, empty ? styles.mainCardEmpty : null]}>
        <table>
          <tr>
            <td className={styles.tdSelectPlan}>Plano selecionado</td>
            <td className={styles.tdBackgroundSquare}>
              <div
                className={styles.divSquarePlan}
                style={{ background: colorHandler[plan]?.color }}
              >
                {plan.toUpperCase()}
              </div>
            </td>
          </tr>
          <tr className={styles.trPricePlan}>
            <td className={styles.tdMonthSign}>Assinatura mensal</td>
            <td className={styles.tdpricePlan}>
              {price}
              {' '}
              <br />
              {' '}
              usuário/mês
            </td>
          </tr>
          <tr className={styles.trCategories}>
            <td className={styles.tdTitleCategories}>Categorias monitoradas</td>
            <td className={styles.tdCategories}>
              {categoryNumber > 1 ? `${categoryNumber} Categorias` : '1 Categoria'}
            </td>
          </tr>
          <tr className={styles.trStudy}>
            <td className={styles.tdStudyTrends}>Estudos de tendências</td>
            <td>
              {colorHandler[plan]?.badge}
            </td>
          </tr>
        </table>
      </Card>
    </>
  );
};

export default PlanCard;

PlanCard.defaultProps = {
  plan: '',
  price: '',
  categoryNumber: '',
  empty: false,
};

PlanCard.propTypes = {
  plan: PropTypes.string,
  price: PropTypes.string,
  categoryNumber: PropTypes.number,
  empty: PropTypes.bool,
};
