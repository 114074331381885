import React from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AppRoute from '../components/appRoute';
import HorizontalLayout from '../components/Layout/HorizontalLayout';
import NonAuthLayout from '../components/NonAuthLayout';
// import Categories from '../screens/categories';
import Dashboard from '../screens/dashboard/Dashboard';
import Home from '../screens/home';
import Loading from '../screens/loading';
import Sales from '../screens/sales';
import Signin from '../screens/homePageHandler';
import Signup from '../screens/signup';
import AccountCreated from '../screens/accountCreated';
import MlToken from '../screens/mlToken';
import Activating from '../screens/activating';
import Welcome from '../screens/welcomeStepper';
import CancelPlan from '../screens/cancelPlan';
import UserData from '../screens/userData/UserData';
import MpToken from '../screens/mpToken';
import confirmUpgrade from '../screens/confirmUpgrade';
import payDone from '../screens/payDone';
import SelectChangeCategory from '../screens/selectChangeCategory';
import Done from '../screens/fakesteps';
import redirectPortal from '../screens/redirectPortal';
import EmBreve from '../screens/emBreve';
import VerificationUser from '../screens/VerificationUser/verificationUser';
import BlockMobile from '../screens/blockMobile/BlockMobile';
import StepSettings from '../screens/stepSettings/StepSettings';
import SelectChangeSeller from '../screens/selectChangeSeller';
import Seller from '../screens/seller';

const Routes = () => {
  const isLoading = useSelector((state) => state.auth.isLoading);
  const largura = window.screen.width;

  if (isLoading) return <Loading />;

  return (
    <Router>
      <Switch>
        <AppRoute
          title="Home"
          exact
          path="/"
          privateRoute={false}
          layout={NonAuthLayout}
          component={() => <Redirect to="/home" />}
        />
        <AppRoute
          title="Login"
          path="/login"
          privateRoute={false}
          layout={HorizontalLayout}
          component={Signin}
          bgColor="#567faa"
        />
        <AppRoute
          title="Cadastre-se"
          path="/register"
          privateRoute={false}
          layout={HorizontalLayout}
          component={Signup}
          bgColor="#567faa"
        />
        <AppRoute
          title="Conta criada"
          path="/account-created"
          privateRoute={false}
          isAccountCreated
          layout={HorizontalLayout}
          component={AccountCreated}
          bgColor="#567faa"
        />
        <AppRoute
          title="Primeiros passos"
          path="/first-steps"
          privateRoute
          layout={HorizontalLayout}
          component={largura < 575 ? BlockMobile : Welcome}

        />
        <AppRoute
          title="Portal redirect"
          path="/portal-stripe"
          privateRoute
          layout={HorizontalLayout}
          component={redirectPortal}

        />

        <AppRoute
          title="Feito!"
          path="/success/:id"
          privateRoute
          layout={HorizontalLayout}
          component={Done}

        />
        <AppRoute
          title="Reativar Conta"
          path="/reactivate-account"
          privateRoute
          layout={HorizontalLayout}
          component={largura < 575 ? BlockMobile : CancelPlan}
        />
        <AppRoute
          title="Reativar Conta"
          path="/activating-account/:id"
          privateRoute
          layout={HorizontalLayout}
          component={Activating}
        />
        <AppRoute
          title="Verificação de plano"
          path="/verification-user"
          privateRoute
          layout={HorizontalLayout}
          component={VerificationUser}
        />
        {/* <AppRoute
          title="Monitorar"
          path="/monitor"
          privateRoute={false}
          layout={HorizontalLayout}
          component={Monitorar}

        /> */}

        <AppRoute
          title="Authorization"
          path="/access-token"
          privateRoute
          layout={HorizontalLayout}
          component={MlToken}
        />
        <AppRoute
          title="Pagamento recebido"
          path="/payment-received"
          privateRoute={false}
          layout={HorizontalLayout}
          component={MpToken}
        />
        <AppRoute
          title="Meus dados"
          path="/user"
          privateRoute
          layout={HorizontalLayout}
          component={UserData}
        />
        {/* <AppRoute
          title="Recuperar senha"
          path="/recover-password"
          privateRoute={false}
          layout={HorizontalLayout}
          component={RecoverPassword}
          bgColor="#567faa"

        /> */}

        <AppRoute
          title="Home"
          path="/home"
          privateRoute={false}
          layout={HorizontalLayout}
          component={Home}
          bgColor="#567faa"

        />

        <AppRoute
          title="Categorias"
          path="/categories"
          exact
          privateRoute
          layout={HorizontalLayout}
          component={StepSettings}
        />
        <AppRoute
          title="Resumo"
          path="/dashboard"
          exact
          privateRoute
          layout={HorizontalLayout}
          component={Dashboard}
        />

        <AppRoute
          title="Mudança de Plano"
          path="/confirm-change-plan"
          exact
          privateRoute
          layout={HorizontalLayout}
          component={confirmUpgrade}
        />

        <AppRoute
          title="Pagamento concluído"
          path="/pay-done"
          exact
          privateRoute
          layout={HorizontalLayout}
          component={payDone}
        />

        <AppRoute
          title="Selecionar categorias"
          path="/select-change-category"
          exact
          privateRoute
          layout={HorizontalLayout}
          component={SelectChangeCategory}
        />
        <AppRoute
          title="Selecionar vendedores"
          path="/select-change-seller"
          exact
          privateRoute
          layout={HorizontalLayout}
          component={SelectChangeSeller}
        />

        <AppRoute
          title="Mais vendidos"
          path="/sales"
          privateRoute
          layout={HorizontalLayout}
          component={largura < 575 ? BlockMobile : Sales}
        />

        <AppRoute
          title="Concorrência"
          path="/seller"
          privateRoute
          layout={HorizontalLayout}
          component={Seller}
        />

        <AppRoute
          title="Bloqueado"
          path="/block-mobile"
          privateRoute
          layout={HorizontalLayout}
          component={BlockMobile}
        />

        <AppRoute
          title="Trends"
          path="/trends"
          privateRoute
          layout={HorizontalLayout}
          component={EmBreve}
        />

        <AppRoute
          title="Loaders"
          path="/loaders"
          privateRoute
          layout={HorizontalLayout}
          component={EmBreve}
        />

        <AppRoute
          title="Home"
          path="*"
          privateRoute={false}
          layout={NonAuthLayout}
          component={() => <Redirect to="/home" />}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
