import React, { useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';

// import FirstStep from './components/FirstStep';
// import CatMonitorar from './components/catMonitorar';
// import ConfirmCategory from './components/confirmCategory';
// import Pay from './components/pay/index';
import LastSteps from './components/last';
import styles from './styles.module.scss';

const Done = () => {
  const [step, setStep] = useState(4);
  const [totalWidth, setTotalWidth] = useState();

  const stepHandler = {
    0: <></>,
    1: <></>,
    2: <></>,
    3: <></>,
    4: <LastSteps onClick={() => setStep((currentStep) => currentStep + 1)} />,

  };

  useEffect(() => {
    const widthContainer = document.getElementById('stepperContainer').clientWidth;
    setTotalWidth(widthContainer / (4 / step));
  }, [step]);

  const stepNames = {
    0: 'Boas Vindas',
    1: 'Monitorar',
    2: 'Confirmar',
    3: 'Pagamento',
    4: 'Feito!',
  };

  return (
    <Container>
      <Card
        style={{
          maxWidth: '1100px', minHeight: '500px', boxShadow: '0 0 0.5rem rgba(112,112,112, 0.4)', margin: '0 auto',
        }}
      >
        <CardBody
          className={styles.cardBody}
          style={{ textAlign: 'center' }}
        >
          <div
            id="stepperContainer"
            style={{
              height: '1px', width: '93%', marginLeft: '2.4rem', position: 'relative',
            }}
          >
            <div style={{
              width: totalWidth, height: '1px', backgroundColor: '#c7cdd1', position: 'absolute', left: '0px', top: '31.5px',
            }}
            />
          </div>
          <div className={styles.stepper}>
            {Object.keys(stepHandler).map((item) => (
              <div className={styles.step}>
                <span className={step >= Number(item)
                  ? styles.stepDotActive : styles.stepDotInactive}
                />
                <span className={step >= Number(item)
                  ? styles.stepText : styles.stepTextWhite}
                >
                  {stepNames[item]}

                </span>
              </div>
            ))}
          </div>
          {stepHandler[step]}
        </CardBody>
      </Card>
    </Container>
  );
};

export default Done;
