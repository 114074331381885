import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getMLAccessToken } from '../../services/auth';
import { restoreAuth } from '../../store/auth';
import Loading from '../loading/Loading';

const MlToken = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  function newlyCreatedUser() {
    sessionStorage.setItem('@newlyCreated', true);
  }

  async function getMLToken(user, code, token) {
    try {
      const { newUser } = await getMLAccessToken(user, code);
      dispatch(restoreAuth({ user: newUser, token }));
      return newUser;
    } catch (error) {
      return 'Erro em integrar conta.';
    }
  }

  useEffect(() => {
    const { user, token } = JSON.parse(sessionStorage.getItem('@userAuth'));
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const error = urlParams.get('error');

    if (!error && !code) {
      history.push('/');
    }

    if (user && !user.plan) {
      if (error) {
        newlyCreatedUser();
        history.push({
          pathname: '/account-created',
          state: { msg: 'Conta criada com sucesso!' },
        });
        return;
      }
      getMLAccessToken(user.id, code)
        .then(() => {
          newlyCreatedUser();
          history.push({
            pathname: '/account-created',
            state: { msg: 'Conta criada e integrada com sucesso!' },
          });
        });
      return;
    }

    if (error) {
      history.push({
        pathname: '/user',
        state: { msg: 'Erro ao integrar conta com o Mercado Livre!', error },
      });
      return;
    }

    getMLToken(user.id, code, token)
      .then(() => {
        history.push('/user');
      });
  }, []);

  return (
    <Loading />
  );
};

export default MlToken;
