/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { hideRightSidebar } from '../../store/layout/layout.action';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './rightbar.scss';

const RightSideBar = () => {
  const dispatch = useDispatch();

  const hideSidebar = useCallback(() => {
    document.body.classList.remove('right-bar-enabled');
    dispatch(hideRightSidebar());
  }, [dispatch]);

  return (
    <>
      <div className="right-bar">
        <PerfectScrollbar className="h-100">
          <div className="rightbar-title px-3 py-4">
            <Link
              to="#"
              onClick={hideSidebar}
              className="right-bar-toggle float-right"
            >
              <i className="mdi mdi-close noti-icon" />
            </Link>
            <h5 className="m-0">Settings</h5>
          </div>

          <hr className="my-0" />
        </PerfectScrollbar>
      </div>
      <div className="rightbar-overlay" />
    </>
  );
};

export default RightSideBar;
