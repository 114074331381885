import React from 'react';
import {
  Button,
  Col,
} from 'reactstrap';

import './styles.scss';

const Planbox = ({
  // eslint-disable-next-line react/prop-types
  title, price, categoryNumber, color, badge, onClick, active,
  //  buttonText,
}) => (
  <Col xs="auto" style={{ margin: '0 5px' }} className={`planbox ${active ? 'planbox-active' : null}`}>
    <div className="planbox-title" style={{ backgroundColor: color || '#fff9' }}>
      {title}
    </div>
    <div>
      {price}
      <br />
      usuário/mês
    </div>
    <div className="planbox-text">
      {categoryNumber > 1 ? `${categoryNumber} Categorias` : '1 Categoria'}
    </div>
    <div>
      {badge}
    </div>
    <div>
      <Button
        className="planbox-button"
        style={{
          backgroundColor: color || '#fff9',
        }}
        onClick={onClick}
      >
        Selecionar plano
        {/* {active ? 'PLANO ATUAL' : (buttonText || 'Selecionar plano')} */}
      </Button>

    </div>

  </Col>
);

export default Planbox;
