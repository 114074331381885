/* eslint-disable max-len */
import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  Row, Col, Card, CardBody, Button,
} from 'reactstrap';
import Media from 'react-media';
import { ReactComponent as LogoLogin } from '../../assets/images/Logo_login.svg';
import Signin from '../signin';
import RecoverPassword from '../recoverPassword';
import RecoveryCode from '../recoveryCode';
import RedefinePassword from '../redefinePassword';

const HomePageHandler = () => {
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const [stepperCount, setStepperCount] = useState(1);
  const [user, setUser] = useState();
  const [confirmCode, setConfirmCode] = useState();
  const [email, setEmail] = useState('');

  const stepper = {
    1: (<Signin setStepper={setStepperCount} />),
    2: (<RecoverPassword setStepper={setStepperCount} email={email} setEmail={setEmail} />),
    3: (<RecoveryCode setStepper={setStepperCount} setUser={setUser} setConfirmationCode={setConfirmCode} confirmationCode={confirmCode} email={email} />),
    4: (<RedefinePassword user={user} confirmCode={confirmCode} setStepper={setStepperCount} />),
  };

  return (
    <Card
      style={{
        maxWidth: '900px',
        margin: '0 auto',
        borderRadius: '8px',
        overflow: 'hidden',
      }}
    >
      <Row
        className="justify-content-center"
        style={{ background: '  #D3DDE9' }}
      >
        {stepper[stepperCount]}
        {/* <Col md={6} className="px-5">
          <CardBody className="pt-5 pb-5 h-100">
            <div className="p-3">
              {errors.length > 0 && (
                <Alert color="danger">Falha ao entrar</Alert>
              )}
              <AvForm className="form-horizontal mt-2">

                <AvField
                  name="email"
                  label="Login"
                  placeholder="Coloque seu email"
                  type="text"
                  value={email}
                  onChange={onChange(setEmail)}
                  validate={{
                    email: { value: true, errorMessage: 'Email inválido.' },
                    required: { value: true, errorMessage: 'Preencha o campo.' },
                  }}

                />

                <label htmlFor="password">Senha</label>
                <AvField
                  name="password"
                  type="password"
                  placeholder="Coloque sua senha"
                  value={password}
                  onChange={onChange(setPassword)}
                  required
                  errorMessage="Preencha o campo."
                  // validate={validByPathAsync('password')}
                />
                <div
                  className="form-group mb-0 row"
                  style={{ color: '#23364a' }}
                >
                  <Col xs="12" className="mt-2">
                    {' Esqueceu a senha? '}
                    <Link to="/recover-password" className=" text-info">
                      <u>Clique aqui</u>
                    </Link>
                  </Col>
                </div>
                <div className="form-group row mt-3">
                  <Col xs="6">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customControlInline"
                        value={remember}
                        onChange={onChangeRemember}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customControlInline"
                        style={{ cursor: 'pointer' }}
                      >
                        Lembrar
                      </label>
                    </div>
                  </Col>
                  <Col xs="12" className="mt-4 p-0">
                    <Button
                      size="lg"
                      block
                      color="primary"
                      className="w-md font-weight-bold waves-effect waves-light"
                      type="submit"
                      onClick={onSignin}
                      disabled={isFetching}
                    >
                      Login
                    </Button>
                  </Col>
                </div>
              </AvForm>
            </div>
          </CardBody>
        </Col> */}
        <Media query={{ minWidth: '500px' }}>
          {(matches) => (matches ? (
            <Col
              md={6}
              style={{ background: '#fff', boxShadowLeft: '1px solid #F5F7FA' }}
            >
              <CardBody className="pt-5 px-5 pb-5 h-100">
                <div className="pt-3 px-3" style={{ paddingBottom: '0.7rem' }}>
                  <div
                    style={{ maxHeight: '62px', maxWidth: '215px' }}
                    className="mb-5"
                  >
                    <Link to="/" className="logo logo-admin">
                      <LogoLogin />
                    </Link>
                  </div>
                  <h2
                    className="font-size-34 font-weight-bold mb-1"
                    style={{ color: '#23364A' }}
                  >
                    É novo por aqui? Teste Grátis!
                  </h2>
                  <p className="font-size-19" style={{ color: '#23364A' }}>
                    Comece hoje mesmo gratuitamente e use os dados para tomadas de
                    decisões assertivas e mais vendas na sua operação online.
                  </p>
                  <Row xs="12">
                    <Button
                      size="lg"
                      block
                      color="primary"
                      className="w-md font-weight-bold waves-effect waves-light mt-4"
                      onClick={() => history.push('/register')}
                    >
                      Teste grátis
                    </Button>
                  </Row>
                </div>
              </CardBody>
            </Col>
          ) : (''))}

        </Media>
      </Row>
    </Card>
  );
};

export default HomePageHandler;
