import React, { useMemo, useCallback } from 'react';
import DoubleScrollbar from 'react-double-scrollbar';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { Col, Row, Table as BSTable } from 'reactstrap';
import Progress from '../progress';
import './table.css';
import '../../libs/lexa-dashboard/src/datatables.scss';

const classOrders = {
  desc: 'sorting_desc',
  asc: 'sorting_asc',
  [null]: '',
};

const HeaderCell = ({ column, ordering, onChangeOrder }) => {
  const orderAvailable = useMemo(() => ordering
    ?.availableOrderby?.some((o) => o === column.id)
      || false,
  [ordering, column]);

  const classes = useMemo(() => {
    const ordered = ordering.column === column.id;
    const order = ordered ? ordering.order : null;

    return [
      orderAvailable && 'sorting',
      classOrders[order],
    ]
      .filter((f) => f)
      .join(' ');
  }, [orderAvailable, ordering, column]);

  const onOrder = useCallback(() => {
    if (!orderAvailable) return;

    const getOrder = () => {
      if (column.id === 'itemRelevance') {
        const isDesc = ordering.order === 'desc';
        return isDesc ? 'asc' : 'desc';
      }
      if (column.id === ordering.column) {
        const isAsc = ordering.order === 'asc';
        return isAsc ? 'desc' : 'asc';
      }
      return 'desc';
    };
    const order = getOrder();

    onChangeOrder({ orderby: column.id, order });
  }, [onChangeOrder, column, ordering, orderAvailable]);

  return (
    <th key={column.id} className={classes} onClick={onOrder}>
      {column.title}
    </th>
  );
};

HeaderCell.defaultProps = {
  ordering: {
    column: 'id',
    order: 'asc',
    availableOrderby: [],
  },
};

HeaderCell.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    Component: PropTypes.func,
  }).isRequired,
  ordering: PropTypes.shape({
    column: PropTypes.string,
    order: PropTypes.string,
    availableOrderby: PropTypes.arrayOf(PropTypes.string),
  }),
  onChangeOrder: PropTypes.func.isRequired,
};

const Table = ({
  rows,
  isFetching,
  paging,
  onPageChange,
  onChangeOrder,
  columns,
  showPaging = true,
  ordering,
}) => (
  <>
    <div className="table-responsive">
      <DoubleScrollbar>
        <BSTable className={`table-striped mb-0 dataTable ${isFetching ? 'table-loading' : ''}`}>
          <thead>
            {(isFetching) ? ('')
              : (
                <tr>
                  {columns.map((column) => (
                    <HeaderCell
                      key={column.id}
                      column={column}
                      ordering={ordering}
                      onChangeOrder={onChangeOrder}
                      style={column.style && column.style}
                    />
                  ))}
                </tr>
              )}
          </thead>
          <tbody>
            {isFetching ? (
              <tr>
                <td colSpan={columns.length}>
                  <Progress />
                </td>
              </tr>
            ) : (
            rows?.map((item, index) => (
              <tr key={item.id || index}>
                {columns.map((column) => {
                  if (column.Component) {
                    const { Component } = column;

                    return (
                      <Component
                        key={column.id}
                        item={item}
                        column={column}
                        style={column.style && column.style}
                      />
                    );
                  }
                  return (
                    <td
                      style={column.style && column.style}
                      key={column.id}
                    >
                      {item[column.id] || '-'}
                      {' '}
                    </td>
                  );
                })}
              </tr>
            ))
            )}
          </tbody>
        </BSTable>
      </DoubleScrollbar>
    </div>
    <Row xl="12" className="pagination-row">
      <Col lg={6}>
        <p>{`Mostrando ${rows?.length} de ${paging.totalItems}.`}</p>
      </Col>
      {showPaging && (
      <Col lg={6}>
        <ReactPaginate
          forcePage={Number(paging.page - 1)}
          pageCount={paging.maxPage}
          onPageChange={onPageChange}
          containerClassName="pagination"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          activeClassName="active"
          previousLabel="Anterior"
          nextLabel="Próximo"
        />
      </Col>
      )}
    </Row>

  </>
);

Table.defaultProps = {
  showPaging: true,
  ordering: {
    column: 'id',
    order: 'asc',
    availableOrderby: [],
  },
};

Table.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isFetching: PropTypes.bool.isRequired,
  paging: PropTypes.shape({
    limit: PropTypes.number.isRequired,
    totalItems: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    maxPage: PropTypes.number.isRequired,
  }).isRequired,
  onPageChange: PropTypes.func.isRequired,
  onChangeOrder: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      Component: PropTypes.func,
    }),
  ).isRequired,
  showPaging: PropTypes.bool,
  ordering: PropTypes.shape({
    column: PropTypes.string,
    order: PropTypes.string,
    availableOrderby: PropTypes.arrayOf(PropTypes.string),
  }),

};

export default Table;
