import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Button,
  FormGroup,
} from 'reactstrap';
import ReactExport from 'react-export-excel';
import './exportTableSeller.css';
import Media from 'react-media';
import { toastr } from 'react-redux-toastr';
import Api from '../../services/api';

const filteredToQuery = (filtered) => (
  Object.keys(filtered)
    .map((k) => {
      const v = filtered[k];
      if (!v) return null;
      return { key: k, value: v };
    })
    .filter((q) => q)
);

const formatedDataForExcel = (arrayExport) => {
  if (!Array.isArray(arrayExport)) {
    return [];
  }
  return arrayExport.map((item) => {
    if (item.real_sold_quantity === null) {
      return item;
    }

    const commonItems = {
      ...item,
      created_at: item.created_at.split('T')[0].split('-').reverse().join('/'),
      real_available_quantity: item.real_available_quantity,
      real_sold_quantity: item.real_sold_quantity,
      price: item.price,
      gmv: item.gmv,
      conversion: item.conversion * 100,
      buybox: item.buybox === 0 ? 'Não' : 'Sim',
      visits: item.visits,
    };

    if (item.comparative_sells) {
      return {
        ...commonItems,
        comparative_sells: item.comparative_sells,
        comparative_conversion: Number(item.comparative_conversion),
        comparative_gmv: Number(item.comparative_gmv),
        comparative_visits: item.comparative_visits,
      };
    }
    return commonItems;
  });
};

const ExportTableSeller = ({
  user, filtered, limitForQuery,
}) => {
  const [arrayExport, setArrayExport] = useState([]);
  const [exportButton, setExportButton] = useState(true);

  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;

  const getExportPage = async ({
    page = 1,
    search = '',
    querys = filteredToQuery(filtered),
    order = null,
    orderby = null,
    userIdRedux = user,
  } = {}) => {
    setExportButton(true);

    const query = [
      `limit=${limitForQuery}`,
      `page=${page}`,
      order && `order=${order}`,
      orderby && `orderby=${orderby}`,
      querys && querys.map((q) => `${q.key}=${q.value}`).join('&'),
      search && `search=${search}`,
      userIdRedux && `userIdRedux=${userIdRedux}`,
    ]
      .filter((q) => q)
      .join('&');
    try {
      const response = await Api.get(`/seller?${query}`);
      const payload = response.data;

      setArrayExport(formatedDataForExcel(payload.page));
      setExportButton(false);
    } catch (err) {
      toastr.error('Erro ao exportar');
    }
  };

  useEffect(() => {
    if (limitForQuery !== 0) {
      getExportPage();
    }
  }, [limitForQuery, filtered]);

  return (
    <Media query={{ minWidth: '780px' }}>
      <Col>
        <FormGroup
          className="form-inline"
          style={{
            margin: '0px', 'flex-wrap': 'nowrap', display: 'flex', justifyContent: 'center', alignItems: 'center',
          }}
        >
          <ExcelFile
            filename="Os vendedores monitorados"
            element={(
              <Button
                disabled={exportButton}
                className="search-btn"
                style={{
                  fontSize: 'min(0.75vw, 0.8125rem)', border: '1px solid #567faa', background: '#395C81', margin: '0', borderRadius: '0.3rem', height: '2.3rem',
                }}
              >
                Exportar dados da tabela (.xlsx)
              </Button>

    )}
          >
            <ExcelSheet data={arrayExport} name="Os vendedores monitorados">
              <ExcelColumn label="POSIÇÃO DO ANÚNCIO" value="itemRelevance" />
              <ExcelColumn label="MARCA" value="brand" />
              <ExcelColumn label="VENDEDOR" value="seller" />
              <ExcelColumn label="CATÁLOGO" value="buybox" />
              <ExcelColumn label="ID DO ANÚNCIO" value="id" />
              <ExcelColumn label="TÍTULO" value="title" />
              <ExcelColumn label="PREÇO ATUAL" value="price" />
              <ExcelColumn label="VENDAS TOTAIS" value="real_sold_quantity" />
              <ExcelColumn label="VENDAS PERÍODO" value="comparative_sells" />
              <ExcelColumn label="ESTOQUE DISPONÍVEL" value="real_available_quantity" />
              <ExcelColumn label="FATURAMENTO TOTAL" value="gmv" />
              <ExcelColumn label="FATURAMENTO PERÍODO" value="comparative_gmv" />
              <ExcelColumn label="VISITAS TOTAIS" value="visits" />
              <ExcelColumn label="VISITAS PERÍODO" value="comparative_visits" />
              <ExcelColumn label="CONVERSÃO %" value="conversion" />
              <ExcelColumn label="CONVERSÃO PERÍODO %" value="comparative_conversion" />
              <ExcelColumn label="CRIADO EM" value="created_at" />
              <ExcelColumn label="LINK" value="permalink" />

            </ExcelSheet>
          </ExcelFile>
        </FormGroup>
      </Col>
    </Media>
  );
};

ExportTableSeller.defaultProps = {
  filtered: [],
  limitForQuery: 0,
};

ExportTableSeller.propTypes = {
  user: PropTypes.number.isRequired,
  filtered: PropTypes.bool,
  limitForQuery: PropTypes.number,
};

export default ExportTableSeller;
