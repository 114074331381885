import React, { useEffect, useState } from 'react';
// import {
//   Button,
// } from 'reactstrap';
import {
  Button, Card, CardBody, Container,
} from 'reactstrap';
import 'react-dom';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { ReactComponent as Reactive } from '../../assets/images/Reactive.svg';
import {
  getFaturamento, getStatus, getStripeSessionById, resetCategories,
} from '../../services/users';
import { restoreAuth } from '../../store/auth';
import { clearCategories } from '../../store/auth/auth.action';

const Activating = () => {
  const userEmail = useSelector(({ auth }) => auth.user.email);
  const userId = useSelector(({ auth }) => auth.user.id);
  const token = useSelector(({ auth }) => auth.token);
  const [next, setNext] = useState(true);
  const dispatch = useDispatch();

  // const userSession = useSelector(({ auth }) => auth.user.sessionID);

  // eslint-disable-next-line react/prop-types
  const { id } = useParams();

  async function getStripeSession() {
    await getStripeSessionById(id);
  }
  async function status() {
    await getStatus(userEmail);
  }
  async function faturamento() {
    const responseUser = await getFaturamento(userEmail);
    dispatch(restoreAuth({ user: responseUser?.user, token }));
  }

  const history = useHistory();

  useEffect(() => {
    getStripeSession();
    if (userEmail) {
      setTimeout(() => {
        faturamento();
        status();
        setNext(false);
      }, 6000);
    }
  }, []);

  const handleClick = async () => {
    dispatch(clearCategories);
    await resetCategories(userId);
    history.push('/categories');
  };
  return (
    <>
      <Container>
        <Card
          style={{
            maxWidth: '1200px', minHeight: '550px', boxShadow: '0 0 0.5rem rgba(112,112,112, 0.4)',
          }}
        >
          <CardBody
            style={{ textAlign: 'center', margin: '0' }}
          >
            <section className={styles.container}>

              <div className={styles.title}>Conta reativada com sucesso</div>
              <div>
                {' '}
                <Reactive width="450px" height="350px" />
                {' '}
              </div>
              <div className={styles.subTitle}>
                Aguarde enquanto
                preparamos o Dashboard para você.

              </div>
              <Button
                disabled={next}
                onClick={handleClick}
                color="primary"
                style={{ width: '20%', height: '3rem', fontWeight: 'bold' }}
              >
                Continuar
              </Button>
            </section>
          </CardBody>
        </Card>
      </Container>
    </>

  );
};

export default Activating;
