import React, { useEffect, useState } from 'react';
import { Button, Card } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PlanCard from '../../components/plancard';
import styles from './payDone.module.scss';
import { ReactComponent as Check } from '../../assets/images/bigCheck.svg';
import { planOfUser } from '../../services/users';
import { planIsUptade } from '../../store/categories/categories.action';

const PayDone = () => {
  const userIdRedux = useSelector(({ auth }) => auth.user.id);

  const [userFuturePlan, setUserFuturePlan] = useState();
  const history = useHistory();
  const dispatch = useDispatch();

  async function getPlan() {
    const planFuture = await planOfUser(userIdRedux);
    setUserFuturePlan(planFuture);
  }

  const handleClick = () => {
    dispatch(planIsUptade(true));
    history.push('/categories');
  };

  useEffect(() => {
    getPlan();
    if (!userIdRedux) {
      history.push('/category-plan');
    }
  }, []);

  return (
    <div className={styles.mainGrid}>
      <div className={styles.headerMain}>
        <p>
          Feito!
        </p>
      </div>
      <div className={styles.gridBody}>
        <div className={styles.planCard}>
          <PlanCard
            plan={userFuturePlan?.name}
            price={userFuturePlan?.priceFormated}
            categoryNumber={userFuturePlan?.qtd_categories}
            empty
          />
        </div>
        <div className={styles.holdCard}>
          <Card className={styles.cardCheck}>

            <Check className={styles.check} />
            <p className={styles.textBackDashboard}>
              Sua assinatura foi
              efetuada com sucesso
            </p>
            <Button
              color="primary"
              className={styles.btnDashboard}
              onClick={handleClick}
            >
              Voltar para o Dashboard
            </Button>

          </Card>
        </div>
      </div>
    </div>
  );
};

export default PayDone;
