import { SalesTypes } from './sales.action';

const INITIAL_STATE = {
  list: [],
  isFetching: false,
  error: false,
  errors: [],

  page: [],
  paging: {
    error: false,
    errors: [],
    fetching: false,
    offset: 0,
    page: 1,
    limit: 30,
    totalItems: 0,
    maxPage: 0,
  },

  ordering: {
    column: null,
    orderby: null,
    availableOrderby: [],
  },

  filters: [],
  filtersLoading: false,

};

const onFetching = (state) => ({ ...state, isFetching: true, errors: [] });

const onError = (state, action) => {
  const { errors } = action.payload;
  return {
    ...state,
    error: true,
    errors,
    isFetching: false,
  };
};

const onGetReceive = (state, action) => {
  const { list } = action.payload;

  return {
    ...state,
    list,
    isFetching: false,
    error: false,
    errors: [],
  };
};

const onPageError = (state, action) => {
  const { errors } = action.payload;
  const { paging } = state;
  return {
    ...state,
    paging: {
      ...paging,
      error: true,
      errors,
    },
  };
};

const onPageFetch = (state) => {
  const { paging } = state;

  return {
    ...state,
    paging: {
      ...paging,
      fetching: true,
      error: false,
      errors: [],
    },
  };
};

const onPageReceive = (state, action) => {
  const oldPaging = state.paging;
  const { page, paging, ordering } = action.payload;

  return {
    ...state,
    page,
    ordering,
    paging: {
      ...oldPaging,
      fetching: false,
      error: false,
      errors: [],
      ...paging,
    },
  };
};

const onFiltersError = (state) => ({ ...state, filtersLoading: false });

const onFilterFetch = (state) => ({ ...state, filtersLoading: true });

const onFilterReceive = (state, action) => {
  const { filters } = action.payload;

  return {
    ...state,
    filters,
    filtersLoading: false,
  };
};

const salesReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SalesTypes.FETCH: return onFetching(state);
    case SalesTypes.ERROR: return onError(state, action);
    case SalesTypes.GET_RECEIVE: return onGetReceive(state, action);
    case SalesTypes.PAGE_ERROR: return onPageError(state, action);
    case SalesTypes.PAGE_FETCH: return onPageFetch(state, action);
    case SalesTypes.PAGE_RECEIVE: return onPageReceive(state, action);
    case SalesTypes.FILTERS_ERROR: return onFiltersError(state, action);
    case SalesTypes.FILTERS_FETCH: return onFilterFetch(state, action);
    case SalesTypes.FILTERS_RECEIVE: return onFilterReceive(state, action);
    default: return state;
  }
};

export default salesReducers;
