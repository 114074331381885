import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const NonAuthLayout = ({ children, title }) => {
  useEffect(() => {
    document.title = `${title} | Mercado Acelerado`;
  }, [title]);

  return (
    <>
      {children}
    </>
  );
};

NonAuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default NonAuthLayout;
