import React from 'react';
import PropTypes from 'prop-types';
import './ModalComponent.scss';
import {
  Button,
} from 'reactstrap';
import { ReactComponent as Voltar } from '../../assets/images/Arrow-back.svg';

const ModalComponent = ({
  title,
  children,
  ModalActive,
  notShowHeader,
  width,
  height,
  heightContainer,
  flexDirectionContainer,
  padding,
}) => (
  <div className="MainContainer">
    <div className="CardContainer" style={{ width, height, padding }}>
      {!notShowHeader
      && (
      <header className="HeaderContent">
        <Button className="w-0 no-bg waves-effect waves-light" onClick={ModalActive} style={{ width: '50px', marginBotton: '0.5rem' }}>
          <Voltar width="25px" height="25px" />
        </Button>
        <div className="ModalTitle">
          {title}
        </div>
      </header>
      )}
      <main
        className="ContentContainer"
        style={{
          height: `${heightContainer}`,
          flexDirection: `${flexDirectionContainer}`,
        }}
      >
        {children}
      </main>

    </div>
  </div>
);

ModalComponent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
  ModalActive: PropTypes.bool,
  notShowHeader: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  heightContainer: PropTypes.string,
  padding: PropTypes.string,
  flexDirectionContainer: PropTypes.string,
};

ModalComponent.defaultProps = {
  title: '',
  children: '',
  ModalActive: '',
  notShowHeader: false,
  width: '65%',
  height: '80%',
  heightContainer: '90%',
  padding: '2rem',
  flexDirectionContainer: 'row',
};
export default ModalComponent;
