/* eslint-disable no-console */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import {
  Col,
  CardBody,
  Button,
} from 'reactstrap';
import styles from '../signup/index.module.scss';
import { changePassword } from '../../services/auth';

const RedefinePassword = ({ user, confirmCode, setStepper }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  const onChange = (setState) => (event) => setState(event.target.value);

  const updatePassword = useCallback(async () => {
    if (password && confirmPassword && password === confirmPassword) {
      try {
        setIsFetching(true);
        const response = await changePassword(user.id, password, confirmCode);
        if (response) {
          toast.success('Senha alterada com sucesso!');
          setTimeout(() => {
            setStepper(1);
          }, 1500);
        }
      } catch (error) {
        setIsFetching(false);
        toast.error(`Erro: ${error.response.data.errorName}`);
      }
    }
  }, [password, confirmPassword, user.id, confirmCode, setStepper]);

  return (
    <Col md={6} className="d-flex align-items-center px-5">
      <CardBody className="d-flex flex-column pt-5 pb-5" style={{ height: '95%' }}>
        <h2
          className="font-size-34 font-weight-bold pb-4"
          style={{ color: '#23364A' }}
        >
          Redefina sua Senha

        </h2>
        <AvForm id="redefinePassword-form" onSubmit={updatePassword}>
          <AvField
            name="password"
            type="password"
            placeholder="Preencha sua nova senha"
            label="Nova Senha"
            value={password}
            onChange={onChange(setPassword)}
            className={styles.white}
            required
            errorMessage="Preencha o campo."
            validate={{
              minLength: { value: 6, errorMessage: 'A senha deve ter no mínimo 6 caracteres.' },
              maxLength: { value: 256 },
            }}
          />
          <AvField
            name="confirmPassword"
            type="password"
            placeholder="Confirme sua nova senha"
            label="Confirmar Nova Senha"
            value={confirmPassword}
            onChange={onChange(setConfirmPassword)}
            className={styles.white}
            required
            errorMessage="Preencha o campo."
            validate={{
              match: { value: 'password', errorMessage: 'As senhas devem ser idênticas.' },
              minLength: { value: 6, errorMessage: 'A senha deve ter no mínimo 6 caracteres.' },
              maxLength: { value: 256 },

            }}
          />
        </AvForm>

        <div className="mt-auto">
          <Button
            type="submit"
            form="redefinePassword-form"
            size="lg"
            disabled={isFetching}
            block
            color="primary"
            className="w-md font-weight-bold waves-effect waves-light"
          >
            Confirmar
          </Button>
          <ToastContainer />
        </div>
      </CardBody>
    </Col>
  );
};

RedefinePassword.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  confirmCode: PropTypes.object.isRequired,
  setStepper: PropTypes.func.isRequired,
};

export default RedefinePassword;
