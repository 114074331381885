import React, { useEffect, useState } from 'react';
import {
  Col, Row,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
// import { ReactComponent as Silver } from '../../../../assets/images/SilverCheck.svg';
// import { ReactComponent as Gold } from '../../../../assets/images/GoldCheck.svg';
// import { ReactComponent as Platinum } from '../../../../assets/images/PlatinumCheck.svg';
import Planbox from '../../../../components/planbox';
import { plansCategories } from '../../../../store/categories/categories.action';
import { planOfUser, reactiveCreateCheckout } from '../../../../services/users';

const CategoryPlan = () => {
  const userIdRedux = useSelector(({ auth }) => auth.user.id);
  const userEmail = useSelector(({ auth }) => auth.user.email);
  const [selectedPlan, setSelectedPlan] = useState();
  // const [allPlan, setAllPlan] = useState();
  const dispatch = useDispatch();

  async function setIdPlan() {
    const plan = await planOfUser(userIdRedux);
    setSelectedPlan(plan.id);
    // setAllPlan(plan);
  }

  async function handleClick(id) {
    if (id === 1) {
      const response = await reactiveCreateCheckout('price_1L7jVfExpBzPZXyvfABfV2E7', userEmail);
      window.location = response;
    }
    if (id === 2) {
      const response = await reactiveCreateCheckout('price_1L7jahExpBzPZXyvGFv16TLs', userEmail);
      window.location = response;
    }
    if (id === 3) {
      const response = await reactiveCreateCheckout('price_1L7jbNExpBzPZXyvOnxDgPhP', userEmail);
      window.location = response;
    }
    if (id === 4) {
      const response = await reactiveCreateCheckout('price_1L7jc3ExpBzPZXyvfistvrGO', userEmail);
      window.location = response;
    }
    if (id === 5) {
      const response = await reactiveCreateCheckout('price_1L7jeKExpBzPZXyvv1IAP9WK', userEmail);
      window.location = response;
    }
    setSelectedPlan(id);
    dispatch(plansCategories(id));
  }

  const plans = [
    {
      id: 1,
      title: 'SILVER',
      price: 'R$ 69,90',
      categoryNumber: 1,
      color: '#B3B3A1',
      // badge: <Silver style={{ margin: '10px auto 45px' }} />,
    },
    {
      id: 2,
      title: 'GOLD',
      price: 'R$ 259,60',
      categoryNumber: 4,
      color: '#ECB42D',
      // badge: <Gold style={{ margin: '10px auto 45px' }} />,
    },
    {
      id: 3,
      title: 'PLATINUM',
      price: 'R$ 599,00',
      categoryNumber: 10,
      color: '#72848C',
      // badge: <Platinum style={{ margin: '10px auto 45px' }} />,
    }, {
      id: 4,
      title: 'BLACK',
      price: 'R$ 1.098,00',
      categoryNumber: 20,
      color: '#000000',
      // badge: <Platinum style={{ margin: '10px auto 45px' }} />,
    }, {
      id: 5,
      title: 'DIAMOND',
      price: 'R$ 2.495,00',
      categoryNumber: 50,
      color: '#b4bed1',
      // badge: <Platinum style={{ margin: '10px auto 45px' }} />,
    },
  ];

  useEffect(() => {
    setIdPlan();
  }, []);

  return (
    <div style={{ padding: '4.7rem' }}>
      <section style={{
        display: 'flex', flexDirection: 'column', textAlign: 'initial',
      }}
      >
        <p style={{ fontSize: '1.1rem' }}>
          Para reativar sua conta, ter acesso a todos os dados de mercado,
          saber as variações das vendas nas
          <br />
          categorias e entender semana a semana o que está acontecendo no Mercado Livre
          {' '}
          {' '}
          <strong style={{ color: '#F28500' }}>escolha um de nossos planos:</strong>
        </p>
      </section>
      <Row>
        <Col
          xs="6"
          style={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#23364A',
            fontSize: '0.9rem',
            fontWeight: '600',
            margin: '160px 0 0 0',

          }}
        >
          <Row>
            <div style={{ margin: '10px 0 0 0', fontSize: '0.85rem' }}>Assinatura (Faturado mensalmente)</div>
          </Row>
          <Row style={{
            width: '60.3rem', height: '2px', backgroundColor: '#B3B3A1', marginTop: '1rem',
          }}
          />
          <Row>
            <div style={{ margin: '10px 0 0 0', fontSize: '0.85rem' }}>Quantidade de categorias monitoradas</div>
          </Row>
          <Row style={{
            width: '60.3rem', height: '2px', backgroundColor: '#B3B3A1', marginTop: '1rem',
          }}
          />
        </Col>
        <Col
          xs="6"
          style={{
            display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', color: '#949CA3', fontSize: '0.9rem', fontWeight: '650', paddingRight: '12rem',
          }}
        >

          {plans.map(({
            id, title, price, categoryNumber, color, badge,
          }) => (
            <div style={{ marginBottom: '1rem' }}>
              <Planbox
                key={id}
                title={title}
                price={price}
                categoryNumber={categoryNumber}
                color={color}
                badge={badge}
                onClick={() => handleClick(id)}
                active={id === selectedPlan}
              />
            </div>

          ))}

        </Col>
      </Row>
    </div>

  );
};

export default CategoryPlan;
