/* eslint-disable react/jsx-no-bind */
import React, {
  useState, useCallback, useEffect, useRef,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col, CardBody, Button,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { toast, ToastContainer } from 'react-toastify';
import Media from 'react-media';
import { cleanErrorAuth, signin } from '../../store/auth';
import styles from '../signup/index.module.scss';

const Signin = ({ setStepper }) => {
  const formRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const errorMessage = useSelector(({ auth }) => auth.msg);
  const hasAuth = useSelector(({ auth }) => auth.hasAuth);
  const userStatus = useSelector(({ auth }) => auth.user.status);

  const onChange = (setState) => (event) => setState(event.target.value);

  const onChangeRemember = useCallback((event) => {
    setRemember(event.target.checked);
  }, []);

  async function onSignin(e) {
    e.preventDefault();
    if (email && password) {
      try {
        dispatch(signin({ email, password, remember }));
        setDisabledButton(true);
      } catch (err) {
        toast.error('Falha no login');
        setDisabledButton(false);
      }
    }
  }
  useEffect(() => {
    if (!hasAuth) return;

    if (!userStatus) {
      const { from } = location.state || { from: { pathname: '/first-steps' } };
      history.replace(from);
    }
    if (userStatus === 'active' || 'past_due') {
      const { from } = location.state || { from: { pathname: '/sales' } };
      history.replace(from);
    }
    if (userStatus === 'canceled') {
      const { from } = location.state || { from: { pathname: '/reactivate-account' } };
      history.replace(from);
    }
  }, [location.state, hasAuth, history]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(cleanErrorAuth());
      setDisabledButton(false);
    }
  }, [errorMessage]);

  return (
    <Media query={{ minWidth: '500px' }}>
      {(matches) => (matches ? (
        <Col md={6} className="d-flex align-items-center px-5">
          <CardBody className="d-flex flex-column pt-5 pb-5" style={{ height: '95%' }}>

            <AvForm htmlFor={formRef} onSubmit={onSignin} id="signin-form">
              <AvField
                name="email"
                type="text"
                placeholder="Coloque aqui seu email"
                className={styles.white}
                label="Login"
                value={email}
                onChange={onChange(setEmail)}
                validate={{
                  email: { value: true, errorMessage: 'Email inválido.' },
                  required: { value: true, errorMessage: 'Preencha o campo.' },
                }}
              />
              <AvField
                name="password"
                type="password"
                placeholder="Coloque aqui sua senha"
                label="Senha"
                className={styles.white}
                value={password}
                onChange={onChange(setPassword)}
                required
                errorMessage="Preencha o campo."
              />
              {' Esqueceu a senha? '}
              <Button
                className="text-info"
                onClick={() => setStepper(2)}
                style={{ border: 'none', background: 'none', padding: '0' }}
              >
                <u>
                  Clique aqui
                </u>
              </Button>
              <div className="custom-control custom-checkbox mt-3">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customControlInline"
                  value={remember}
                  onChange={onChangeRemember}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customControlInline"
                  style={{ cursor: 'pointer' }}
                >
                  Lembrar meus dados
                </label>
              </div>
            </AvForm>

            <div className="mt-auto">
              <Button
                disabled={disabledButton}
                type="submit"
                form="signin-form"
                size="lg"
                block
                color="primary"
                className="w-md font-weight-bold waves-effect waves-light"
              >
                Login
              </Button>
              <ToastContainer />

            </div>
          </CardBody>
        </Col>
      ) : (
        <Col className="d-flex align-items-center px-5" style={{ height: '130%' }}>
          <CardBody className="d-flex flex-column" style={{ height: '130%', padding: '5rem 0 5rem 0' }}>

            <AvForm htmlFor={formRef} onSubmit={onSignin} id="signin-form">
              <AvField
                name="email"
                type="text"
                placeholder="Coloque aqui seu email"
                className={styles.white}
                label="Login"
                value={email}
                onChange={onChange(setEmail)}
                validate={{
                  email: { value: true, errorMessage: 'Email inválido.' },
                  required: { value: true, errorMessage: 'Preencha o campo.' },
                }}
              />
              <AvField
                name="password"
                type="password"
                placeholder="Coloque aqui sua senha"
                label="Senha"
                className={styles.white}
                value={password}
                onChange={onChange(setPassword)}
                required
                errorMessage="Preencha o campo."
              />
              {' Esqueceu a senha? '}
              <Button
                className="text-info"
                onClick={() => setStepper(2)}
                style={{ border: 'none', background: 'none', padding: '0' }}
              >
                <u>
                  Clique aqui
                </u>
              </Button>
              <div className="custom-control custom-checkbox mt-3">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customControlInline"
                  value={remember}
                  onChange={onChangeRemember}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customControlInline"
                  style={{ cursor: 'pointer' }}
                >
                  Lembrar meus dados
                </label>
              </div>
            </AvForm>

            <div style={{ marginTop: '2rem' }}>
              <Button
                disabled={disabledButton}
                type="submit"
                form="signin-form"
                size="lg"
                block
                color="primary"
                className="w-md font-weight-bold waves-effect waves-light"
              >
                Entrar
              </Button>
              <Button
                disabled={disabledButton}
                onClick={() => history.push('register')}
                size="lg"
                block
                color="primary"
                className="w-md font-weight-bold waves-effect waves-light"
              >
                Teste grátis
              </Button>
              <ToastContainer />

            </div>
          </CardBody>
        </Col>
      ))}

    </Media>
  );
};

Signin.propTypes = {
  setStepper: PropTypes.func.isRequired,
};

export default Signin;
