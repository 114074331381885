import reducer from './loaders.reducer';

export {
  LoadersTypes,
  getLoaders,
  getLoadersPage,
  postCreateLoader,
} from './loaders.action';

export default reducer;
