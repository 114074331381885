import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';

const Navbar = ({ isMenuOpened }) => {
  const location = useLocation();

  const userStatus = useSelector(({ auth }) => auth.user.status);
  const userID = useSelector(({ auth }) => auth.user.id);
  console.log(userID);
  const [verification, setVerification] = useState();

  useEffect(() => {
    if (userID === 179 || userID === 199 || userID === 210 || userID === 229) setVerification(true);
  }, []);

  const userInOnboarding = location.pathname.split('/')[1] === 'success'
  || location.pathname.split('/')[1] === 'activating-account'
  || location.pathname === '/first-steps'
  || location.pathname === '/block-mobile'
  || location.pathname === '/reactivate-account';

  const getActive = (pathname) => {
    const isSame = pathname === location.pathname;
    if (isSame) {
      return 'activeLogged';
    }

    const paths = location.pathname.split('/');
    const somePath = paths.some((path) => path === pathname);

    return somePath ? 'activeLogged' : '';
  };

  return (
    <div className="topnav">
      <nav
        className="navbar-light navbar-expand-sm topnav-menu "
        id="navigation"
      >
        <Collapse
          isOpen={isMenuOpened}
          className="navbar-collapse justify-content-center"
          id="topnav-menu-content"
        >
          {userInOnboarding || userStatus === 'canceled'
            ? ''
            : (
              <ul
                className="navbar-nav"
                style={{
                  padding: 0,
                  pointerEvents: 'auto',
                  display: 'auto',
                }}
              >
                {verification
                  ? (
                    <>
                      {/* <li className={`nav-itemLog ${getActive('/dashboard')}`}>
                        <Link
                          className={`nav-linkLog ${getActive('/dashboard')}`}
                          to="dashboard"
                        >
                          Resumo
                        </Link>
                      </li> */}
                      <li className={`nav-itemLog ${getActive('/sales')}`}>
                        <Link
                          className={`nav-linkLog ${getActive('/sales')}`}
                          to="sales"
                        >
                          Categoria
                        </Link>
                      </li>

                      <li className={`nav-itemLog ${getActive('/seller')}`}>
                        <Link
                          className={`nav-linkLog ${getActive('/seller')}`}
                          to="seller"
                        >
                          Concorrência
                        </Link>
                      </li>

                      <li className={`nav-itemLog ${getActive('/categories')}`}>
                        <Link
                          className={`nav-linkLog ${getActive('/categories')}`}
                          to="categories"
                        >
                          Configurações
                        </Link>
                      </li>
                    </>
                  )
                  : (
                    <>

                      <li className={`nav-itemLog ${getActive('/sales')}`}>
                        <Link
                          className={`nav-linkLog ${getActive('/sales')}`}
                          to="sales"
                        >
                          Categoria
                        </Link>
                      </li>
                      <li className={`nav-itemLog ${getActive('/categories')}`}>
                        <Link
                          className={`nav-linkLog ${getActive('/categories')}`}
                          to="categories"
                        >
                          Configurações
                        </Link>
                      </li>

                    </>
                  )}

              </ul>
            )}
        </Collapse>
      </nav>
      <ToastContainer />
    </div>
  );
};

Navbar.propTypes = {
  isMenuOpened: PropTypes.bool.isRequired,
};

export default Navbar;
