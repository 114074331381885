import { toastr } from 'react-redux-toastr';
import Api from '../../services/api';

export const SellerconfigTypes = {
  FETCH: '@sellerconfig/fetch',
  ERROR: '@sellerconfig/error',
  GET_RECEIVE: '@sellerconfig/get/receive',
  ADD_RECEIVE: '@sellerconfig/add/receive',

  PAGE_FETCH: '@sellerconfig/page/fetch',
  PAGE_ERROR: '@sellerconfig/page/error',
  PAGE_RECEIVE: '@sellerconfig/page/receive',

  TRACKING_RECEIVE: '@sellerconfig/tracking/receive',

  TREE_RECEIVE: '@sellerconfig/tree/receive',
  NODE_RECEIVE: '@sellerconfig/node/receive',

  FILTERS_FETCH: '@sellerconfig/filters/fetch',
  FILTERS_ERROR: '@sellerconfig/filters/error',
  FILTERS_RECEIVE: '@sellerconfig/filters/receive',

  NEWPLAN: '@sellerconfig/newplan/fetch',
  PLANUPTADE: '@sellerconfig/planUptade/fetch',

  CATEGORYONBOARD: '@sellerconfig/categoryOnBoarding/fetch',
};

export const getSellerConfigPage = ({
  page = 1,
  limit = 30,
  search = '',
  querys = [],
  order = null,
  orderby = null,
  userIdRedux,
} = {}) => (dispatch) => {
  dispatch({ type: SellerconfigTypes.PAGE_FETCH });

  const query = [
    `limit=${limit}`,
    `page=${page}`,
    order && `order=${order}`,
    orderby && `orderby=${orderby}`,
    querys && querys.map((q) => `${q.key}=${q.value}`).join('&'),
    search && `search=${search}`,
    userIdRedux && `userIdRedux=${userIdRedux}`,
  ]
    .filter((q) => q)
    .join('&');

  Api.get(`/seller/configSeller?${query}`)
    .then((resp) => {
      const payload = resp.data;
      dispatch({ type: SellerconfigTypes.PAGE_RECEIVE, payload });
    }).catch((err) => {
      toastr.error('Falha ao carregar vendedores.');
      const payload = err.response?.data;
      dispatch({ type: SellerconfigTypes.PAGE_ERROR, payload });
    });
};
