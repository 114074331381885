/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import {
  Col,
  CardBody,
  Button,
} from 'reactstrap';
import { toast } from 'react-toastify';
import styles from '../signup/index.module.scss';
import { recoverPassword } from '../../services/auth';

const RecoverPassword = ({ setStepper, email, setEmail }) => {
  const onChange = (setState) => (event) => setState(event.target.value);
  const [isFetching, setIsFetching] = useState(false);

  const handleClick = () => {
    setStepper(1);
  };

  const sendEmail = useCallback(async () => {
    setIsFetching(true);
    if (email) {
      const response = await recoverPassword(email);
      setStepper(3);
      toast.success(response.msg);
    }
  }, [email, setStepper]);

  return (
    <Col md={6} className="d-flex align-items-center px-5">
      <CardBody className="d-flex flex-column pt-5 pb-5" style={{ height: '95%' }}>
        <h2
          className="font-size-34 font-weight-bold pb-4"
          style={{ color: '#23364A' }}
        >
          Esqueceu sua senha?

        </h2>
        <p className="font-size-19 mb-4" style={{ color: '#23364A' }}>
          Confirme seu email para receber o código de redefinição de senha.
        </p>
        <AvForm id="recover-form" onSubmit={sendEmail}>
          <AvField
            name="email"
            label="Seu email cadastrado"
            placeholder="Coloque aqui seu email"
            className={styles.white}
            type="text"
            value={email}
            onChange={onChange(setEmail)}
            validate={{
              email: { value: true, errorMessage: 'Email inválido.' },
              required: { value: true, errorMessage: 'Preencha o campo.' },
            }}
          />
        </AvForm>

        <button
          type="button"
          style={{
            background: 'none',
            border: 'none',
            color: '#23364A',
            alignSelf: 'flex-start',
          }}
          onClick={handleClick}
        >
          &lt;
          <u> Voltar</u>

        </button>

        <div className="mt-auto">
          <Button
            type="submit"
            form="recover-form"
            size="lg"
            block
            color="primary"
            disabled={isFetching}
            className="w-md font-weight-bold waves-effect waves-light"
          >
            Enviar código
          </Button>
        </div>

      </CardBody>
    </Col>
  );
};

RecoverPassword.propTypes = {
  setStepper: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
};

export default RecoverPassword;
