import React, { useEffect, useState } from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import { Button } from 'reactstrap';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import styles from './styles.module.scss';
import {
  changeAllUserCategories,
  pullNewCategories,
  postSilverPlan,
  startCrawler,
} from '../../../../services/categories';
import { restoreAuth } from '../../../../store/auth';
import {
  getFaturamento,
  getStripeSessionById,
} from '../../../../services/users';
import Progress from '../../../../components/progress/Progress';
import { isFetchingCategoryOnboarding } from '../../../../store/categories/categories.action';

// eslint-disable-next-line react/prop-types
const WelcomeLast = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const userId = useSelector(({ auth }) => auth.user.id);
  const userEmail = useSelector(({ auth }) => auth.user.email);
  const token = useSelector(({ auth }) => auth.token);
  const arrayCategories = JSON.parse(localStorage.getItem('CategoriesForUser'));
  const [{ id: idCategory, keyword }] = arrayCategories;
  const [userResponse, setUserResponse] = useState();
  const [disabledButton, setDisabledButton] = useState(false);
  const [setProgressStatus, setSetProgressStatus] = useState(false);

  async function setPlan() {
    const responsePlan = await postSilverPlan(userId);
    setUserResponse(responsePlan);
  }

  const handleClick = async () => {
    setDisabledButton(true);
    setSetProgressStatus(true);
    const responsePullingCategories = await pullNewCategories({
      category_id: idCategory,
      keyword,
    }, true);
    await changeAllUserCategories(userId, arrayCategories, 1);
    dispatch(restoreAuth({ user: userResponse?.user, token }));
    localStorage.removeItem('CategoriesForUser');
    history.push('/sales');
    if (!responsePullingCategories?.itemExists) {
      dispatch(isFetchingCategoryOnboarding(true));
      await startCrawler(userId, true);
    }
  };

  async function getStripeSession() {
    await getStripeSessionById(id);
  }
  async function faturamento() {
    await getFaturamento(userEmail);
  }

  useEffect(() => {
    getStripeSession();
    faturamento();
  }, []);

  useEffect(() => {
    setPlan();
  }, []);

  return (
    <section>
      <div className={styles.success}>
        Pronto, a sua configuração foi concluída com sucesso!
      </div>
      <div className={styles.title}>Comece a acelerar suas vendas.</div>
      <p className={styles.p}>
        Já disponibilizamos dados relevantes e que podem te ajudar a tomar boas
        <br />
        decisões, como: as vendas consolidadas da sua categoria, os produtos
        mais
        <br />
        vendidos no Mercado Livre, insights e muito mais.
      </p>
      {setProgressStatus ? (
        <Progress />
      ) : (
        <Button
          onClick={handleClick}
          disabled={disabledButton}
          color="primary"
          size="lg"
          style={{
            height: '55px',
            width: '400px',
            margin: '0 auto',
            fontSize: '1rem',
            fontWeight: '700',
          }}
        >
          Acesse o Dashboard Mercado Acelerado
        </Button>
      )}
    </section>
  );
};

export default WelcomeLast;
