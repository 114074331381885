import React from 'react';
import {
  Button,
} from 'reactstrap';

const FirstStep = (props) => {
  // eslint-disable-next-line react/prop-types
  const { onClick } = props;
  return (
    <>
      <h2 style={{
        color: '#395c81', fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '0.5rem', marginTop: '1.5rem',
      }}
      >
        Nossas boas vindas ao
        <br />
        Dashboard do Mercado Acelerado!
      </h2>
      <div style={{
        margin: '0 auto', padding: '1rem', paddingBottom: '1rem',
      }}
      >
        <p style={{ fontSize: '1rem', color: '#23364A' }}>
          Aqui você vai encontrar dados de vendas das categorias no Mercado Livre,
          <br />
          descobrir o que seu concorrente está vendendo, além de saber quais as maiores
          <br />
          buscas dentro da plataforma. Tudo isso em um único lugar que vai te ajudar a
          <br />
          tomar as melhores decisões e fazer o seu negócio acelerar pra valer.
          <br />
        </p>
      </div>

      <Button
        color="primary"
        size="lg"
        onClick={onClick}
        style={{
          height: '45px',
          width: '250px',
          margin: '0 auto',
          fontSize: '1rem',
          fontWeight: '650',

        }}
      >
        Avançar
      </Button>
    </>
  );
};

export default FirstStep;
