import React, { useEffect } from 'react';
// import { useCallback, useState } from 'react';
// import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
// import { Height } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import Topbar from './Topbar';
import Footer from './Footer';
import RightSideBar from '../right-sidebar';

const Layout = ({
  children, title, bgColor, isAccountCreated,
}) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${title} | Mercado Acelerado`;
  }, [title]);

  return (
    <>
      <div id="layout-wrapper">
        <header id="page-topbar">
          <Topbar
            bgColor={bgColor}
            isAccountCreated={isAccountCreated}
          />
        </header>
        <div className="main-content">
          <div className="d-flex align-items-center justify-content-center" style={{ minHeight: location.pathname === '/categories' ? '' : 'calc(100vh - 70px - 80px - 32px)' }}>{children}</div>

        </div>
        <Footer />
      </div>
      <RightSideBar />
    </>
  );
};

Layout.defaultProps = {
  isAccountCreated: false,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  isAccountCreated: PropTypes.bool,
};

export default Layout;
