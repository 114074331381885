import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  Label,
  Form,
  Col,
  Row,
} from 'reactstrap';
import Media from 'react-media';
import './selectSearch.css';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// import Progress from '../progress';

const SelectSearch = ({
  filtered,
  filters,
  onChangeFilter,
  hideDefaultOption,
  type,
  vertical,
  setInputKeyword,
  setIdChildren,
  setVerificationButton,
  setFullName,
  width,
  restNumber,
  pageSelectCategories,
  onChangeFilterCategories,
  setStateForBrandFilter,
  setStateForBrandKeyFilter,
}) => {
  // const inputSize = type === 'sales' ? 'col-md-6' : 'col-md-4';
  const [validateChildren, setValidateChildren] = useState(1);
  const [validateInput, setValidateInput] = useState(false);
  const { filtersLoading: isCategoryFetching } = useSelector(({ categories }) => categories);

  let inputSize = '';

  function changeFilter(e, filter) {
    onChangeFilter(e, filter);
    setStateForBrandFilter(e.target);
    setStateForBrandKeyFilter(filter);
  }

  function onChangeLastSelect(e, filter) {
    const validate = filter.values.filter((item) => item.id === e.target.value);
    onChangeFilterCategories(e, filter.key);
    setIdChildren(validate[0]?.id);
    toast.warning('Carregando dados!', {
      autoClose: 1000,
      hideProgressBar: true,
    });
    if (validate[0]?.level === 'L4') {
      setFullName(validate[0]?.name);
      setValidateChildren(0);
      setIdChildren(validate[0]?.id);
      return;
    }
    setFullName(validate[0]?.name);
    setValidateChildren(validate[0]?.children);
    setTimeout(() => {
      toast.success('Dados carregados!', {
        hideProgressBar: true,

      });
    }, 1000);
  }

  if (type === 'sales' || 'seller') {
    inputSize = 'col-md-4';
  }
  if (type === 'categories') {
    inputSize = 'col-md-3';
  }
  if (type === 'trends') {
    inputSize = 'col-md-4';
  }

  function filterLastName(value) {
    return value.split('> ').pop();
  }

  function inputSelect(e) {
    if (e.target.value === 'chave') {
      setValidateInput(true);
      return;
    }
    setValidateInput(false);
  }
  function setInputStyle(el) {
    if (el) {
      el.style.setProperty('background', '#395c81', 'important');
      el.style.setProperty('font-weight', 'bold', 'important');
      el.style.setProperty('color', '#ecf2f6', 'important');
      el.style.setProperty('border', 'none', 'important');
      el.style.setProperty('height', '45px', 'important');
      el.style.setProperty('width', '70%', 'important');
      // el.style.setProperty('max-width', '287.475px', 'important');
    }
  }

  const labelMargin = type === 'sales' || 'seller' ? 'mr-3' : 'mr-1';
  const mediaForm = (
    <Media query={{ minWidth: '780px' }}>
      {(matches) => (matches ? (
        <Form className="form-inline col-md-9">
          {filters.map((filter) => filter.values.length > 0 && (
          <FormGroup className={`form-inline ${inputSize}`} key={filter.key.key} sm="10" style={{ 'flex-wrap': 'nowrap', padding: '0 0.5rem' }}>
            <Label
              for="search-input"
              className={`col-form-label ${labelMargin}`}
              style={{
                color: '#a4e5f9', opacity: '0.9', fontWeight: '300', fontSize: 'min(0.8vw, 0.8125rem)',
              }}
            >
              {filter.name}
            </Label>
            <div className="filters">
              <select
                style={{
                  fontWeight: 'bold', background: '#395c81', color: '#ecf2f6', border: 'none', height: 'calc(1.5em + 0.75rem + 2px)', fontSize: 'min(0.8vw, 0.8125rem)', width: '100%', paddingRight: '1.5rem',
                }}
                className="select0 form-control col-md-11 form-inline"
                value={filter.key === 'category_id'
                  ? JSON.stringify({
                    id: filtered[filter.key],
                    keyword: filtered.keyword,
                  })
                  : JSON.stringify({ id: filtered[filter.key] })}
                onChange={(e) => changeFilter(e, filter)}
              >
                {!hideDefaultOption && filter.key !== 'week_created' && (
                <option value={
                  filter.key === 'category_id'
                    ? JSON.stringify({ id: '', keyword: '' })
                    : JSON.stringify({ id: '' })
                }
                >
                  Todas
                </option>
                )}
                {filter?.values.map((f) => (
                  <option key={f.id} value={JSON.stringify({ id: f.id, keyword: f.keyword })}>
                    {f.name}
                  </option>
                ))}
              </select>
            </div>
          </FormGroup>
          ))}
        </Form>
      ) : (
        <Col xl="12">
          {filters.map((filter) => filter.values.length && (
          <FormGroup row key={filter.key.key}>
            <Label for="search-input" className="col-sm-2 col-form-label">
              {filter.name}
            </Label>
            <Col sm="10">
              <Row>
                <select
                  className="form-control"
                  value={filter.key === 'category_id'
                    ? JSON.stringify({
                      id: filtered[filter.key],
                      keyword: filtered.keyword,
                    })
                    : JSON.stringify({ id: filtered[filter.key] })}
                  onChange={(e) => changeFilter(e, filter)}
                >
                  {!hideDefaultOption && (
                    <option value={
                filter.key === 'category_id'
                  ? JSON.stringify({ id: '', keyword: '' })
                  : JSON.stringify({ id: '' })
                   }>
                      {`${filter.key === 'week_created' ? 'Todos' : 'Todas'}`}
                    </option>
                  )}
                  {filter?.values.map((f) => (
                    <option key={f.id} value={JSON.stringify({ id: f.id, keyword: f.keyword })}>
                      {f.name}
                    </option>
                  ))}
                </select>
              </Row>
            </Col>
          </FormGroup>
          ))}
        </Col>
      ))}

    </Media>
  );

  useEffect(() => {
    const changeSomeUpperFilterCategory = (validateChildren > 0 || filtered.category_L0 === '');

    if (pageSelectCategories && changeSomeUpperFilterCategory) {
      setValidateChildren(1);
      setVerificationButton(true);
      setValidateInput(false);
      setInputKeyword(false);
    }

    if (validateChildren === 0 && !isCategoryFetching) setVerificationButton(false);
  }, [validateChildren, filtered, isCategoryFetching]);

  useEffect(() => {
    if (!pageSelectCategories) {
      return;
    }

    const changeKeywordForAllResults = validateChildren === 0 && validateInput;

    if (changeKeywordForAllResults) {
      setValidateInput(true);
      return;
    }
    setValidateInput(false);
    setInputKeyword(false);
  }, [validateInput]);

  return (
    <>
      {vertical ? (
        <Col>
          {filters.map((filter) => {
            const teste = (
              <>
                <FormGroup
                  row
                  key={filter.key}
                  style={{
                    justifyContent: 'center', width: `${width}`, marginLeft: 'auto', marginRight: 'auto',
                  }}
                >

                  <Label
                    for="search-input"
                    className={`col-form-label ${labelMargin}`}
                    style={{
                      color: '#a4e5f9', opacity: '0.9', fontWeight: 'bold', fontSize: '1rem',
                    }}
                  >
                    {filter.name}
                  </Label>
                  <div className="seta">
                    <select
                      style={{
                        fontWeight: 'bold', background: '#395c81', color: '#ecf2f6', border: 'none', height: '45px',
                      }}
                      disabled={restNumber === 0}
                      className="select0 form-control form-inline"
                      value={filtered[filter.key]}
                      onChange={(e) => onChangeLastSelect(e, filter)}
                    >
                      {!hideDefaultOption && <option value="">Selecione a categoria</option>}
                      {filter?.values.map((f) => (
                        <option key={f.id} value={f.id}>
                          {filterLastName(f.name)}
                        </option>
                      ))}
                    </select>
                  </div>

                </FormGroup>
              </>
            );
            console.log('filter values', filter.values[0]);
            return (
              <>
                {filter.values.length > 0 && teste}

              </>
            );
          })}
          {validateChildren === 0
            && (
              <FormGroup
                row
                style={{
                  justifyContent: 'center', width: `${width}`, marginLeft: 'auto', marginRight: 'auto',

                }}
              >
                <Label
                  for="search-input"
                  className={`col-form-label ${labelMargin}`}
                  style={{
                    color: '#a4e5f9', opacity: '0.9', fontWeight: 'bold', fontSize: '1rem',
                  }}
                />
                <div className="seta">
                  <select
                    style={{
                      fontWeight: 'bold', background: '#395c81', color: '#ecf2f6', border: 'none', height: '45px',
                    }}
                    className="select0 form-control form-inline"
                    name=""
                    id="filterInput"
                    onChange={(e) => inputSelect(e)}
                  >
                    <option selected value="resultados">Todos Resultados</option>
                    <option value="chave">Palavra-chave</option>
                  </select>
                </div>
              </FormGroup>
            )}
          {validateInput && (
          <FormGroup
            row
            style={{
              justifyContent: 'center', width: `${width}`, marginLeft: 'auto', marginRight: 'auto',

            }}
          >
            <Label
              for="search-input"
              className={`col-form-label ${labelMargin}`}
              style={{
                color: '#a4e5f9', opacity: '0.9', fontWeight: 'bold', fontSize: '1rem',
              }}
            />
            <input
              placeholder="Insira sua palavra chave"
              className="form-control col-md-8 form-inline"
              ref={(el) => setInputStyle(el)}
              onChange={(e) => setInputKeyword(e.target.value)}
              type="text"
            />

          </FormGroup>
          )}
        </Col>
      ) : mediaForm}

    </>
  );
};

SelectSearch.defaultProps = {
  hideDefaultOption: false,
  vertical: false,
  setInputKeyword: false,
  setIdChildren: '',
  setVerificationButton: true,
  setFullName: '',
  width: '70%',
  restNumber: 1,
  pageSelectCategories: false,
  onChangeFilter: false,
  onChangeFilterCategories: false,
  setStateForBrandFilter: false,
  setStateForBrandKeyFilter: false,
};

SelectSearch.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filtered: PropTypes.object.isRequired,
  onChangeFilter: PropTypes.func,
  hideDefaultOption: PropTypes.bool,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  type: PropTypes.string.isRequired,
  vertical: PropTypes.bool,
  setInputKeyword: PropTypes.bool,
  setIdChildren: PropTypes.string,
  setVerificationButton: PropTypes.bool,
  setFullName: PropTypes.string,
  width: PropTypes.string,
  restNumber: PropTypes.number,
  pageSelectCategories: PropTypes.bool,
  onChangeFilterCategories: PropTypes.func,
  setStateForBrandFilter: PropTypes.func,
  setStateForBrandKeyFilter: PropTypes.func,
};

export default SelectSearch;
