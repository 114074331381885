import React from 'react';

import { Container, Row, Col } from 'reactstrap';

const Footer = () => {
  const newUser = sessionStorage.getItem('@newlyCreated');
  const userSessionStorage = JSON.parse(sessionStorage.getItem('@userAuth'));

  return (
    <footer className="footer d-flex">
      <Container
        fluid
        className="d-flex align-items-end justify-content-center"
        style={{ borderTop: !userSessionStorage?.user.id || newUser ? 'none' : '1px solid rgb(222, 226, 230)' }}
      >
        <Row>
          <Col sm="12">
            <p style={{ color: !userSessionStorage?.user.id || newUser ? 'white' : '#707070', margin: 0 }}>
              © 2022 Mercado Acelerado
              <br />
              v1
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
