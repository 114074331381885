import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Col,
  Row,
  Input,
  FormGroup,
  Label,
} from 'reactstrap';
import './textSearch.css';
import Media from 'react-media';

const cleanBtnStyle = {
  background: 'none', border: 'none', outline: 'none', fontSize: 'min(0.8vw, 0.8125rem)', width: '35%', color: '#ecf2f6',
};

const TextSearch = ({
  searchInput,
  setSearch,
  onKeyDown,
  onCancelSearch,
  label,
  placeholder,
}) => {
  const location = useLocation();

  return (
    <Media query={{ minWidth: '780px' }}>
      {(matches) => (matches ? (
        <Col xl="4">
          <Row style={{ width: location.pathname === '/sales' || location.pathname === '/seller' ? '80%' : '24em' }}>
            <Col sm="12">
              <Row className="search-input">
                <Input
                  style={{
                    background: '#395c81',
                    color: '#ecf2f6',
                    border: 'none',
                    fontSize: 'min(0.8vw, 0.8125rem)',
                  }}
                  sm="6"
                  className="form-control blue"
                  type="text"
                  id="search-input"
                  placeholder={placeholder || label}
                  value={searchInput}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={onKeyDown}
                />

                <button
                  type="button"
                  style={cleanBtnStyle}
                  onClick={onCancelSearch}
                >
                  <div style={{
                    fontSize: 'min(0.65vw, 0.7125rem)',
                    fontWeight: '750',
                  }}
                  >
                    X Limpar
                  </div>
                </button>
              </Row>
            </Col>
          </Row>
        </Col>
      ) : (
        <Col xl="12">
          <FormGroup row>
            <Label for="search-input" className="col-sm-2 col-form-label">
              {label}
            </Label>
            <Col sm="10">
              <Row className="search-input">
                <Input
                  className="form-control"
                  type="text"
                  id="search-input"
                  placeholder={placeholder || label}
                  value={searchInput}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={onKeyDown}
                />
                <Button
                  color="danger"
                  className="search-btn"
                  onClick={onCancelSearch}
                >
                  <i className="ion ion-md-close" />
                </Button>
              </Row>
            </Col>
          </FormGroup>
        </Col>
      ))}
    </Media>
  );
};

TextSearch.defaultProps = { placeholder: '' };

TextSearch.propTypes = {
  searchInput: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onCancelSearch: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

export default TextSearch;
