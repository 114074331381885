/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Col, Row, Card, CardBody, Button, FormGroup,
} from 'reactstrap';
import { Doughnut, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale,
  LinearScale,
  BarElement,
  // Title,
} from 'chart.js';
import PropTypes from 'prop-types';
import getDashboard from '../../services/dashboard';
import { ReactComponent as Lista } from '../../assets/images/ListDash.svg';
import { ReactComponent as Positivo } from '../../assets/images/PositivoDash.svg';
import { ReactComponent as Coracao } from '../../assets/images/HealthDash.svg';
import { ReactComponent as Raio } from '../../assets/images/RaioDash.svg';
import { ReactComponent as Sifrao } from '../../assets/images/Sifrao.svg';
import { ReactComponent as Carrinho } from '../../assets/images/Carrinho.svg';
import { ReactComponent as Raio1 } from '../../assets/images/Raio.svg';
import { ReactComponent as Indice } from '../../assets/images/Indice.svg';
import ModalComponent from '../../components/modal/ModalComponent';
// import useTableData from '../../hooks/useTableData';
// import { getSalesPage } from '../../store/sales';
// import Table from '../../components/table';

const LinkData = ({ item }) => (
  <td>
    <a
      href={`${item.permalink}`}
      style={{ color: '#384E5E', fontWeight: '700' }}
      target="_blank"
      rel="noopener noreferrer"
    >
      Ver
    </a>
  </td>
);

LinkData.propTypes = {
  item: PropTypes.shape({
    permalink: PropTypes.string.isRequired,
  }).isRequired,
};

const ThumbData = ({ item }) => (
  <td>
    <img
      src={item.thumbnail}
      alt={`${item.id}-thumb`}
      className="sales-image"
    />
  </td>
);

ThumbData.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
  }).isRequired,
};

const GMV = ({ item, column }) => {
  const formated = useMemo(() => {
    const raw = item[column.id];
    const locale = Number(raw).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return locale;
  }, [item, column.id]);

  return <td>{item.buybox ? 'N/A' : formated}</td>;
};

GMV.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    GMV: PropTypes.string.isRequired,
    buybox: PropTypes.bool,
  }).isRequired,
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

const Sells = ({ item }) => {
  if (item.real_sold_quantity) {
    const formatedSell = item.real_sold_quantity.toLocaleString('pt-BR');
    return (
      <td>
        {formatedSell}
      </td>
    );
  }
  return <td> - </td>;
};

Sells.defaultProps = {
  item: { real_sold_quantity: 0 },
};

Sells.propTypes = {
  item: PropTypes.shape({
    real_sold_quantity: PropTypes.number.isRequired,
  }),
};

// const columns = [
//   { id: 'itemRelevance' },
//   { id: 'thumbnail', Component: ThumbData },
//   { id: 'gmv', title: 'FATURAMENTO', Component: GMV },
//   { id: 'real_sold_quantity', title: 'VENDAS', Component: Sells },
// ];

const Dashboard = () => {
  const [modalChangePlan, setModalChangePlan] = useState(false);
  const [dashboardData, setDashboardData] = useState({});
  const [dashboardDataItemsWithMoreSells, setDashboardDataItemsWithMoreSells] = useState([]);
  const [dashboardDataTrendByCategory, setDashboardDataTrendByCategory] = useState([]);

  const {
    adsModule,
  } = dashboardData;

  const formatedAds = Number(adsModule).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  // const {
  //   rows,
  //   isFetching,
  //   paging,
  //   ordering,
  //   onChangeOrder,
  //   onPageChange,
  // } = useTableData({ reducer: 'sales', action: getSalesPage });

  ChartJS.register(ArcElement, Legend, CategoryScale,
    LinearScale,
    BarElement,
    // Title,
    Tooltip);

  const options3 = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
      },
      // title: {
      //   display: true,
      //   text: 'Chart.js Horizontal Bar Chart',
      // },
    },
  };

  const jsonTeste = {
    opportunityIndex: {
      competitiveness: 'Baixa',
      activeQuantity: 'Media',
    },
    quantityAds: {
      totalAds: 25300,
      adsSells: 28999000,
      percentSellsAds: 35,
    },
    healthCategory: {
      totalHealth: 55,
      healthFreeShipping: 32,
      typeAdsCategoryPremium: 43,
      typeAdsCategoryClassic: 57,
    },
    billing: {
      totalBilling: 20890879,
      totalItemsSells: 99999,
      averageTicket: 25,
    },
    numberOfSellers: {
      totalSellers: 98584,
      sellersWithSale: 8785,
      percentSellersWithSale: 15,
    },
    ratioSellersCategory: [
      {
        1: {
          name: 'Gold',
          value: 30,
        },
        2: {
          name: 'Platinum',
          value: 40,
        },
        3: {
          name: 'Lojas Oficiais',
          value: 30,
        },
      },
    ],
    itemsWithMoreSale: [
      {
        1: {
          position: 1,
          thumbnail: 'http://http2.mlstatic.com/D_849503-MLB41363605183_042020-I.jpg',
          billing: 5500,
          sells: 89,
        },
        2: {
          position: 2,
          thumbnail: 'http://http2.mlstatic.com/D_849503-MLB41363605183_042020-I.jpg',
          billing: 3000,
          sells: 85,
        },
        3: {
          position: 3,
          thumbnail: 'http://http2.mlstatic.com/D_849503-MLB41363605183_042020-I.jpg',
          billing: 3070,
          sells: 50,
        },
        4: {
          position: 4,
          thumbnail: 'http://http2.mlstatic.com/D_849503-MLB41363605183_042020-I.jpg',
          billing: 2070,
          sells: 40,
        },
        5: {
          position: 5,
          thumbnail: 'http://http2.mlstatic.com/D_849503-MLB41363605183_042020-I.jpg',
          billing: 2000,
          sells: 18,
        },
        6: {
          position: 6,
          thumbnail: 'http://http2.mlstatic.com/D_849503-MLB41363605183_042020-I.jpg',
          billing: 1900,
          sells: 15,
        },
        7: {
          position: 7,
          thumbnail: 'http://http2.mlstatic.com/D_849503-MLB41363605183_042020-I.jpg',
          billing: 1700,
          sells: 12,
        },
        8: {
          position: 8,
          thumbnail: 'http://http2.mlstatic.com/D_849503-MLB41363605183_042020-I.jpg',
          billing: 1400,
          sells: 11,
        },
        9: {
          position: 9,
          thumbnail: 'http://http2.mlstatic.com/D_849503-MLB41363605183_042020-I.jpg',
          billing: 1300,
          sells: 10,
        },
        10: {
          position: 10,
          thumbnail: 'http://http2.mlstatic.com/D_849503-MLB41363605183_042020-I.jpg',
          billing: 1000,
          sells: 8,
        },
      },
    ],
    mostSearchedTerms: [
      {
        1: 'Michellin',
        2: 'Firestone',
      },
    ],
    typeShipping: [
      {
        0: {
          name: 'Mercado Envios Correios',
          quantity: 16,
        },
        1: {
          name: 'Agência Mercado Livre',
          quantity: 24,
        },
        2: {
          name: 'Mercado Envios Coletas',
          quantity: 7,
        },
        3: {
          name: 'Mercado Envios Flex',
          quantity: 3,
        },
        4: {
          name: 'Mercado Envios Full',
          quantity: 25,
        },
        5: {
          name: 'Transportadoras Próprias',
          quantity: 25,
        },
      },
    ],
    typeShipping2: [
      {
        1: {
          name: 'Correio',
          quantity: 50,
        },
        2: {
          name: 'JSX',
          quantity: 50,
        },
      },
    ],
  };

  const labels = ['Mercado Envios Correios', 'Agência Mercado Livre', 'Mercado Envios Coletas', 'Mercado Envios Flex', 'Mercado Envios Full', 'Transportadoras Próprias'];

  const data3 = {
    labels,
    datasets: [
      {
        label: 'Porcentagem',
        data: labels.map(() => Math.floor(Math.random() * 100)),
        borderColor: 'rgb(255, 210, 129)',
        backgroundColor: 'rgba(255, 210, 129, 0.5)',
      },
    ],
  };

  const data5 = {
    labels: ['Verde', 'Demais Reputações', 'Líder', 'Gold', 'Platinum', 'Lojas Oficiais'],
    datasets: [
      {
        label: 'Porcentagem',
        data: labels.map(() => Math.floor(Math.random() * 100)),
        borderColor: 'rgb(255, 210, 129)',
        backgroundColor: 'rgba(255, 210, 129, 0.5)',
      },
    ],
  };

  const data = {
    labels: ['Anúncios com venda', 'Sem venda'],
    datasets: [
      {
        label: '# of Votes',
        data: [jsonTeste.quantityAds.percentSellsAds, 100 - jsonTeste.quantityAds.percentSellsAds],
        backgroundColor: [
          'rgba(253, 188, 158)',
          'rgba(253, 188, 158, 0.2)',
        ],
        borderColor: [
          'rgba(253, 188, 158)',
          'rgba(253, 188, 158, 0.2)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const data2 = {
    labels: ['Premium (parcelamento sem juros)', 'Clássico (parcelamento com juros)'],
    datasets: [
      {
        label: '# of Votes',
        data: [jsonTeste.healthCategory.typeAdsCategoryPremium, jsonTeste.healthCategory.typeAdsCategoryClassic],
        backgroundColor: [
          'rgba(255, 210, 129)',
          'rgba(255, 210, 129, 0.2)',
        ],
        borderColor: [
          'rgba(255, 210, 129)',
          'rgba(255, 210, 129, 0.2)',
        ],
        borderWidth: 1,
      },
    ],
  };
  const data4 = {
    labels: ['Anúncios com frete Grátis', 'Anúncios sem frete Grátis'],
    datasets: [
      {
        label: '# of Votes',
        data: [jsonTeste.healthCategory.healthFreeShipping, 100 - jsonTeste.healthCategory.healthFreeShipping],
        backgroundColor: [
          'rgba(255, 210, 129)',
          'rgba(255, 210, 129, 0.2)',
        ],
        borderColor: [
          'rgba(255, 210, 129)',
          'rgba(255, 210, 129, 0.2)',
        ],
        borderWidth: 1,
      },
    ],
  };

  async function loadData(categoryId) {
    const responseData = await getDashboard(categoryId);
    setDashboardDataItemsWithMoreSells(responseData.productsAndTrends.itemsWithMoreSells);
    setDashboardDataTrendByCategory(responseData.productsAndTrends.trendByCategory);
    setDashboardData(responseData);
  }

  const listaMaisVendidos = dashboardDataItemsWithMoreSells?.map(
    (item, i) => (
      <>
        <tr>
          <td>{i + 1}</td>
          <td><img width={32} src={item.thumbnail} alt="" /></td>
          <td>
            {Number(item.gmv).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </td>
          <td>{item.real_sold_quantity.toLocaleString('pt-BR')}</td>
        </tr>
      </>
    ),

  );

  const listaMaisBuscados = dashboardDataTrendByCategory?.map(
    (termo, i) => (
      <>
        <tr>
          <td>{i + 1}</td>
          <td>{termo.keyword}</td>
        </tr>
      </>
    ),
  );

  useEffect(() => {
    loadData('MLB2233');
  }, []);

  return (
    <>
      <Row style={{ width: '100%', fontFamily: 'Inter' }}>
        <Col sm="12">
          <div style={{ display: 'flex' }}>
            <Card xs="7" className="mr-1" style={{ flex: 6 }}>
              <CardBody
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  background: '#567faa',
                  padding: '0.7rem 0',
                }}
              >
                <h1>teste</h1>
                <Button>teste</Button>
              </CardBody>
            </Card>
            <Card xs="5" style={{ flex: 1.2 }}>
              <CardBody
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#79A0C9',
                  padding: '0.7rem 0',
                }}
              >
                <Col>
                  <FormGroup
                    className="form-inline"
                    style={{
                      margin: '0px', 'flex-wrap': 'nowrap', display: 'flex', justifyContent: 'center', alignItems: 'center',
                    }}
                  >
                    <Button style={{
                      fontSize: 'min(0.65vw, 0.7125rem)', border: '1px solid #567faa', background: '#395C81', margin: '0', borderRadius: '0.3rem', height: '2.3rem',
                    }}
                    >
                      Exportar dados do resumo (.pdf)
                    </Button>
                  </FormGroup>
                </Col>
              </CardBody>
            </Card>
          </div>
          <p>
            Acessórios para Veículos &gt; Acess. de Carros e Caminhonetes &gt; Pneus &gt;
            {' '}
            <strong>Goodyear</strong>
          </p>
          <Card style={{
            height: '85rem', display: 'grid', gap: '0.5rem', gridTemplateColumns: '1fr 1fr', backgroundColor: '#eef0f3',
          }}
          >
            <div
              className="col1"
              style={{
                display: 'grid', gridTemplateRows: '1.5fr 2fr 1.5fr', height: '85rem', backgroundColor: '#eef0f3',
              }}
            >
              <div
                className="indice"
                style={{
                  backgroundColor: 'white', width: '100%', height: '97%', display: 'grid', gridTemplateRows: '1fr 2fr',
                }}
              >
                <div style={{
                  backgroundColor: '#FFE8BE', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: '#23364A', fontWeight: '650',
                }}
                >
                  <h4 style={{ fontSize: 'min(1.6vw, 2.4125rem)' }}>
                    {' '}
                    <Raio />
                    {' '}
                    ÍNDICE MERCADO ACELERADO DE OPORTUNIDADES
                    {' '}
                  </h4>
                  <h5 style={{ color: 'orange', fontSize: 'min(1.2vw, 1.8125rem)' }}>
                    (ENTENDER COMPETITIVIDADE E FACILIDADE PARA VENDAS NA CATEGORIA)
                  </h5>
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: '2fr 3fr' }}>
                  <div style={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
                  }}
                  >
                    <h5 style={{
                      fontSize: 'min(0.9vw, 1.5125rem)', textAlign: 'center', color: '#23364A', fontWeight: '650',
                    }}
                    >
                      ÍNDICE DE
                      {' '}
                      <br />
                      OPORTUNIDADE

                    </h5>
                    <Indice style={{ width: '14rem' }} />
                    <p style={{
                      fontSize: 'min(0.8vw, 1rem)', margin: '0', color: '#8F9BB4', marginTop: '1rem',
                    }}
                    >
                      Competitividade:
                      {' '}
                      <strong style={{ color: '#FC9D73' }}>BAIXA</strong>
                    </p>
                    <p style={{ fontSize: 'min(0.8vw, 1rem)', margin: '0', color: '#8F9BB4' }}>
                      Qualidade dos anúncios:
                      {' '}
                      <strong style={{ color: '#FC9D73' }}>BAIXA</strong>
                    </p>
                  </div>
                  <div style={{
                    display: 'grid', gridTemplateRows: '2fr 3fr',
                  }}
                  >
                    <div style={{ textAlign: 'center', fontWeight: '650', color: '#23364A' }}>
                      <p style={{ fontSize: 'min(1vw, 1.6125rem)', marginBottom: '0', marginTop: '1rem' }}>PARA TER SUCESSO SERÁ NECESSÁRIO</p>
                      <p style={{ fontSize: 'min(0.8vw, 1rem)', margin: '0', textAlign: 'center' }}>
                        Clique nas opções abaixo para receber as
                        {' '}
                        <br />
                        orientações necessárias para se destacar na categoria

                      </p>
                    </div>
                    <div style={{
                      display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', marginTop: '1rem',
                    }}
                    >
                      <Button
                        style={{
                          height: '30%', width: '35%', fontSize: 'min(0.7vw, 1.4125rem)', fontWeight: '650', margin: '0 0.5rem',
                        }}
                        onClick={() => setModalChangePlan(true)}
                      >
                        <Raio1 style={{ height: '70%' }} />
                        {' '}
                        Anúncios
                      </Button>
                      <Button style={{
                        height: '30%', width: '35%', fontSize: 'min(0.7vw, 1.4125rem)', fontWeight: '650', margin: '0 0.5rem',
                      }}
                      >
                        <Raio1 style={{ height: '70%' }} />
                        {' '}
                        Reputação
                      </Button>
                      <Button style={{
                        height: '30%', width: '35%', fontSize: 'min(0.7vw, 1.4125rem)', fontWeight: '650', margin: '0 0.5rem',
                      }}
                      >
                        <Raio1 style={{ height: '70%' }} />
                        {' '}
                        Operação
                      </Button>
                      <Button style={{
                        height: '30%', width: '35%', fontSize: 'min(0.7vw, 1.4125rem)', fontWeight: '650', margin: '0 0.5rem',
                      }}
                      >
                        <Raio1 style={{ height: '70%' }} />
                        {' '}
                        Performance
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="gridAnuncios"
                style={{
                  backgroundColor: '#eef0f3', display: 'grid', gap: '0.5rem', gridTemplateColumns: '1fr 1fr', width: '100%', alignSelf: 'center',
                }}
              >
                <div
                  className="numeroAnuncios"
                  style={{
                    display: 'grid', height: '40rem', gridTemplateRows: '1fr 2fr 2fr',
                  }}
                >
                  <div style={{
                    backgroundColor: '#FFEEE6', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: '1rem',
                  }}
                  >
                    <section style={{
                      fontSize: 'min(1.2vw, 1.8125rem)', color: '#23364A', margin: '0.4rem 0 0.5rem 0',
                    }}
                    >
                      <Lista height="75%" />
                      {' '}
                      NÚMERO DE ANÚNCIOS
                    </section>
                    {' '}
                    <br />
                    <section style={{
                      fontSize: 'min(1.6vw, 2.2125rem)', color: '#23364A', fontWeight: '600', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '0.5rem',
                    }}
                    >
                      {`${formatedAds}`}
                      <Positivo style={{ marginLeft: '0.5rem' }} />
                    </section>
                    {' '}
                    <br />
                    <p style={{ fontSize: 'min(0.7vw, 0.9125rem)', color: '#FC9D73', fontWeight: '600' }}>
                      Total de anúncios cadastrados na Categoria
                    </p>
                  </div>
                  <div style={{
                    gap: '0.5rem', borderBottom: '1px solid #707070', backgroundColor: 'white', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', alignSelf: 'flex-start',
                  }}
                  >
                    <section style={{
                      fontSize: 'min(1vw, 1.6125rem)', color: '#23364A', margin: '0.6rem 0 1.5rem 0', fontWeight: '600',
                    }}
                    >
                      QUANTIDADE DE ANÚNCIOS
                      {' '}
                      <br />
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        COM VENDA
                      </div>
                    </section>
                    <section style={{
                      fontSize: 'min(1.6vw, 2.2125rem)', color: '#23364A', fontWeight: '600', marginBottom: '1.6rem',
                    }}
                    >
                      {`${jsonTeste.quantityAds.adsSells}`}

                    </section>
                    <p style={{
                      fontSize: 'min(0.7vw, 0.9125rem)', color: '#8F9BB4', fontWeight: '600', marginBottom: '0', textAlign: 'center',
                    }}
                    >
                      É a quantidade de anúncios que
                      {' '}
                      <br />
                      apresentam vendas dentro da categoria

                    </p>
                  </div>
                  <div style={{
                    backgroundColor: 'white', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                  }}
                  >
                    <section>
                      <Doughnut
                        data={data}
                        options={{
                          responsive: true, maintainAspectRatio: false,
                        }}
                      />
                    </section>
                    <p style={{
                      fontSize: 'min(0.7vw, 0.9125rem)', color: '#8F9BB4', fontWeight: '600', marginTop: '1.5rem', textAlign: 'center',
                    }}
                    >
                      É a quantidade de anúncios que apresentam vendas
                      {' '}
                      <br />
                      dentro da categoria

                    </p>
                  </div>
                </div>
                <div
                  className="health"
                  style={{
                    display: 'grid', backgroundColor: '#eef0f3', height: '40rem', gridTemplateRows: '1fr 2fr 2fr',
                  }}
                >
                  <div style={{
                    backgroundColor: '#FFEEE6', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: '1rem',
                  }}
                  >
                    <section style={{
                      fontSize: 'min(1.2vw, 1.8125rem)', color: '#23364A', margin: '0.5rem 0 0.5rem 0',
                    }}
                    >
                      <Coracao height="75%" />
                      {' '}
                      HEALTH DA CATEGORIA
                    </section>
                    {' '}
                    <br />
                    <section style={{
                      fontSize: 'min(1.6vw, 2.2125rem)', color: '#23364A', fontWeight: '600', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '0.5rem',
                    }}
                    >
                      {`${jsonTeste.healthCategory.totalHealth}%`}

                      <Positivo style={{ marginLeft: '0.5rem' }} />
                    </section>
                    {' '}
                    <br />
                    <p style={{ fontSize: 'min(0.7vw, 0.9125rem)', color: '#FC9D73', fontWeight: '600' }}>
                      É a saúde média dos anúncios da Categoria

                    </p>
                  </div>
                  <div style={{
                    gap: '0.5rem', borderBottom: '1px solid #707070', backgroundColor: 'white', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', alignSelf: 'flex-start',
                  }}
                  >
                    {/* <section style={{
                    fontSize: '1.1rem', color: '#23364A', fontWeight: '600',
                  }}
                  >
                    ANÚNCIOS COM FRETE GRÁTIS
                  </section> */}
                    <section style={{ height: '70%' }}>
                      <Doughnut
                        data={data4}
                        options={{
                          responsive: true, maintainAspectRatio: false,
                        }}
                      />
                    </section>
                    <p style={{
                      fontSize: 'min(0.7vw, 0.9125rem)', color: '#8F9BB4', fontWeight: '600', marginTop: '1.5rem',
                    }}
                    >
                      Dos anúncios da categoria oferecem frete grátis
                    </p>
                  </div>
                  <div style={{
                    gap: '0.5rem', backgroundColor: 'white', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', alignSelf: 'flex-start',
                  }}
                  >
                    <section style={{
                      fontSize: 'min(1vw, 1.6125rem)', color: '#23364A', marginTop: '1rem', fontWeight: '600',
                    }}
                    >
                      TIPOS DE ANÚNCIOS DA CATEGORIA
                    </section>

                    <div style={{ display: 'flex', height: '100%' }}>
                      <section style={{ width: '100%', paddingBottom: '0.5rem' }}>
                        <Doughnut
                          data={data2}
                          options={{
                            responsive: true,
                            maintainAspectRatio: false,
                          }}
                        />
                      </section>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modalidadeFrete"
                style={{
                  color: '#23364A', fontWeight: '700', width: '100%', backgroundColor: 'white', height: '28rem', display: 'grid', alignSelf: 'flex-end', gridTemplateRows: '1fr 4fr', marginTop: '0.5rem',
                }}
              >
                <div style={{ margin: '1rem auto' }}>
                  <h4 style={{ fontSize: 'min(1.2vw, 1.8125rem)' }}>MODALIDADE DE ENVIOS DOS ANÚNCIOS DA CATEGORIA</h4>
                </div>
                <div>
                  <Bar options={options3} data={data3} />
                  {' '}
                </div>
              </div>
            </div>
            <div
              className="col2"
              style={{
                display: 'grid', gridTemplateRows: '2fr 1fr 2fr', textAlign: 'center',
              }}
            >
              <div style={{
                display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '0.5rem', backgroundColor: '#eef0f3',
              }}
              >
                <div style={{
                  backgroundColor: 'white', display: 'grid', gridTemplateRows: '1fr 2fr 2fr',
                }}
                >
                  <div style={{ backgroundColor: '#E7FEED' }}>
                    <section style={{
                      fontSize: 'min(1.2vw, 1.8125rem)', color: '#23364A', margin: '1rem 0 1.1rem 0', display: 'flex', justifyContent: 'center', alignItems: 'center',
                    }}
                    >
                      <div style={{ marginBottom: '0.3rem' }}>
                        <Sifrao />
                      </div>
                      FATURAMENTO
                    </section>
                    <section style={{
                      fontSize: 'min(1.6vw, 2.2125rem)', color: '#23364A', fontWeight: '600', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '0.5rem',
                    }}
                    >
                      <div style={{ marginLeft: '1rem' }}>
                        {`R$${jsonTeste.billing.totalBilling}`}
                      </div>
                      <Positivo style={{ marginLeft: '0.5rem' }} />
                    </section>
                  </div>
                  <div style={{ borderBottom: '1px solid #707070' }}>
                    <section style={{
                      fontSize: 'min(1vw, 1.6125rem)', color: '#23364A', marginTop: '1.5rem', fontWeight: '600',
                    }}
                    >
                      ITENS VENDIDOS
                      {' '}
                      <br />
                      NA CATEGORIA
                    </section>
                    <section style={{
                      fontSize: 'min(1.6vw, 2.2125rem)', color: '#23364A', fontWeight: '600', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1.3rem 0 1.3rem 0',
                    }}
                    >
                      <div style={{ marginLeft: '1rem' }}>
                        {`${jsonTeste.billing.totalItemsSells}`}
                      </div>
                      <Positivo style={{ marginLeft: '0.5rem' }} />

                    </section>
                    <p style={{
                      fontSize: 'min(0.7vw, 0.9125rem)', color: '#8F9BB4', fontWeight: '600', marginBottom: '0', textAlign: 'center',
                    }}
                    >
                      É a quantidade de itens vendidos
                      {' '}
                      <br />
                      nesta categoria

                    </p>
                  </div>
                  <div>
                    <section style={{
                      fontSize: 'min(1vw, 1.6125rem)', color: '#23364A', marginTop: '1.5rem', fontWeight: '600',
                    }}
                    >
                      TICKET MÉDIO

                    </section>
                    <section style={{
                      fontSize: 'min(1.6vw, 2.2125rem)', color: '#23364A', fontWeight: '600', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '2.5rem 0 1.3rem 0',
                    }}
                    >
                      <div style={{ marginLeft: '1rem' }}>
                        {`R$${jsonTeste.billing.averageTicket}`}
                      </div>
                      <Positivo style={{ marginLeft: '0.5rem' }} />

                    </section>
                    <p style={{
                      fontSize: 'min(0.7vw, 0.9125rem)', color: '#8F9BB4', fontWeight: '600', marginBottom: '0', textAlign: 'center',
                    }}
                    >
                      É a quantidade de vendedores que
                      {' '}
                      <br />
                      possuem vendas dentro da
                      <br />
                      {' '}
                      categoria

                    </p>
                  </div>
                </div>
                <div style={{
                  backgroundColor: 'white', display: 'grid', gridTemplateRows: '1fr 2fr 2fr',
                }}
                >
                  <div style={{ backgroundColor: '#ADD2FE' }}>
                    <section style={{
                      fontSize: 'min(1.2vw, 1.8125rem)', color: '#23364A', margin: '1rem 0 0.5rem 0',
                    }}
                    >
                      <Carrinho />
                      NÚMERO DE VENDEDORES
                    </section>
                    <section style={{
                      fontSize: 'min(1.6vw, 2.2125rem)', color: '#23364A', fontWeight: '600', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '0.5rem',
                    }}
                    >
                      <div style={{ marginLeft: '1rem', marginTop: '0.5rem' }}>
                        {`R$${jsonTeste.numberOfSellers.totalSellers}`}
                      </div>
                      <Positivo style={{ marginLeft: '0.5rem' }} />

                    </section>
                  </div>
                  <div style={{ borderBottom: '1px solid #707070' }}>
                    <section style={{
                      fontSize: 'min(1vw, 1.6125rem)', color: '#23364A', marginTop: '1.5rem', fontWeight: '600',
                    }}
                    >

                      QUANTIDADE DE
                      {' '}
                      <br />
                      VENDEDORES COM VENDA
                    </section>
                    <section style={{
                      fontSize: 'min(1.6vw, 2.2125rem)', color: '#23364A', fontWeight: '600', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1.3rem 0 1.3rem 0',
                    }}
                    >
                      <div style={{ marginLeft: '1rem' }}>
                        {`${jsonTeste.numberOfSellers.sellersWithSale}`}
                      </div>
                      <Positivo style={{ marginLeft: '0.5rem' }} />

                    </section>
                    <p style={{
                      fontSize: 'min(0.7vw, 0.9125rem)', color: '#8F9BB4', fontWeight: '600', marginTop: '1.5rem', textAlign: 'center',
                    }}
                    >
                      É a quantidade de vendedores que
                      {' '}
                      <br />
                      possuem vendas dentro da categoria

                    </p>
                  </div>
                  <div>
                    <section style={{
                      fontSize: 'min(1vw, 1.6125rem)', color: '#23364A', marginTop: '1.5rem', fontWeight: '600',
                    }}
                    >
                      PORCENTAGEM DE
                      {' '}
                      <br />
                      VENDEDORES COM VENDA

                    </section>
                    <section style={{
                      fontSize: 'min(1.6vw, 2.2125rem)', color: '#23364A', fontWeight: '600', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1rem 0 1.3rem 0',
                    }}
                    >
                      <div style={{ marginLeft: '1rem' }}>
                        {`${jsonTeste.numberOfSellers.percentSellersWithSale}%`}
                      </div>
                      <Positivo style={{ marginLeft: '0.5rem' }} />

                    </section>
                    <p style={{
                      fontSize: 'min(0.7vw, 0.9125rem)', color: '#8F9BB4', fontWeight: '600', marginTop: '1.5rem', textAlign: 'center',
                    }}
                    >
                      É a porcentagem de vendedores que
                      {' '}
                      <br />
                      possuem vendas dentro da categoria

                    </p>
                  </div>
                </div>
              </div>
              <div style={{ backgroundColor: 'white', display: 'grid', marginTop: '0.5rem' }}>
                <section>
                  {' '}
                  <h5 style={{ marginTop: '0.5rem' }}>NÍVEIS DE REPUTAÇÃO DOS VENDEDORES DA CATEGORIA</h5>
                </section>
                <div>
                  <Bar options={options3} data={data5} />
                </div>
              </div>
              <div style={{
                display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '0.5rem', marginTop: '0.5rem',
              }}
              >
                <section style={{ backgroundColor: 'white' }}>
                  <p style={{ fontSize: 'min(0.8vw, 1rem)', margin: '1rem auto', fontWeight: '650' }}>
                    10 PRODUTOS QUE MAIS VENDERAM NO
                    {' '}
                    <br />
                    PERÍODO SELECIONADO NA CATEGORIA

                  </p>
                  <div style={{ overflowY: 'auto', height: '28.5rem', width: '100%' }}>
                    <table className="table table-hover">
                      <thead>
                        <tr style={{ color: '#949CA3' }}>
                          <th scope="col" />
                          <th scope="col" />
                          <th scope="col">FATURAMENTO</th>
                          <th scope="col">VENDAS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listaMaisVendidos}
                      </tbody>
                    </table>
                  </div>
                </section>
                <section style={{ backgroundColor: 'white' }}>
                  <p style={{ fontSize: 'min(0.8vw, 1rem)', margin: '1rem auto', fontWeight: '650' }}>
                    50 TERMOS MAIS BUSCADOS DA
                    {' '}
                    <br />
                    CATEGORIA NAS ÚLTIMAS 24 HORAS

                  </p>
                  <div style={{ overflowY: 'auto', height: '28.5rem', width: '100%' }}>
                    <table className="table table-hover">
                      <thead>
                        <tr style={{ color: '#949CA3' }}>
                          <th scope="col" />
                          <th scope="col">TERMO/PALAVRA-CHAVE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listaMaisBuscados}

                      </tbody>
                    </table>
                  </div>

                </section>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      {
      modalChangePlan && (
        <ModalComponent
          notShowHeader
          ModalActive={() => setModalChangePlan(false)}
          width="50%"
          height="70%"
          padding="0.8rem"
          heightContainer="100%"
          flexDirectionContainer="column"
        >
          <div style={{
            width: '95%',
            height: '100%',
            backgroundColor: 'white',
            borderRadius: '10px',
            overflowY: 'auto',
          }}
          >
            <p style={{
              textAlign: 'justify', margin: '1rem 2rem', fontFamily: 'Roboto', color: '#23364A',
            }}
            >
              <h2 style={{ textAlign: 'center', color: '#567FAA' }}>
                {' '}
                <Raio1 />
                {' '}
                RECOMENDAÇÃO PARA ANÚNCIOS
              </h2>
              <br />
              <h5 style={{ color: '#F28500' }}>O QUE?</h5>

              Oferecer anúncios com frete grátis.
              <br />
              <br />
              <h5 style={{ color: '#F28500' }}>COMO</h5>

              Criar anúncios com frete grátis incluído.
              <br />
              <br />
              <h5 style={{ color: '#F28500' }}>POR QUE?</h5>

              Anúncios com frete grátis faturam R$XX.XXX que representa X% do faturamento.
              <br />
              <br />
              <h5 style={{ color: '#F28500' }}>QUER SABER COMO?</h5>

              Confira o video que preparamos para você.
              {' '}
              <a href="http://">Clique aqui!</a>
            </p>
          </div>
          <div style={{
            alignSelf: 'center',
            justifySelf: 'flexEnd',
            marginTop: '1rem',
          }}
          >
            <Button
              style={{ width: '10rem' }}
              color="primary"
              size="lg"
              type="submit"
              onClick={() => setModalChangePlan(false)}
            >
              Entendi
            </Button>
          </div>
        </ModalComponent>
      )
}
    </>
  );
};

export default Dashboard;
