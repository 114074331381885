import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import PropTypes from 'prop-types';
import {
  Button,
} from 'reactstrap';

import {
  getCategoriesPage,
  getCategoriesFilter,

} from '../../../../store/categories';

import SelectSearch from '../../../../components/selectSearch';
import useTableData from '../../../../hooks/useTableData';
import ModalMonitorar from '../modalMonitor/ModalMonitorar';

// eslint-disable-next-line react/prop-types
const CatMonitor = ({
  // eslint-disable-next-line react/prop-types
  onClick: onClickProp, setFullName, idChildren, setIdChildren, inputKeyword, setInputKeyword,
}) => {
  const [modalActive, setModalActive] = useState(false);
  const dispatch = useDispatch();
  const [verificationButton, setVerificationButton] = useState(true);
  const filters = useSelector((state) => state.categories.filters);
  const {
    filtered,
    onChangeFilterCategories,
  } = useTableData({ reducer: 'categories', action: getCategoriesPage });

  useEffect(() => {
    dispatch(getCategoriesFilter());
  }, []);

  return (

    <section>

      {modalActive
        ? (

          <ModalMonitorar ModalActive={() => setModalActive(false)} title="">
            <div>
              <p className="fontCardModal">
                <strong style={{ color: '#567FAA' }}>Importante: </strong>
                {' '}
                L1 considerada a categoria Macro, para informações
                <br />
                mais assertivas sobre as vendas de mercado, solicitamos que
                <br />
                escolha a partir de L3. Caso a categoria não possua níveis de
                <br />
                sub-categorias L3 e L4, selecionar L2.
              </p>
              <div className="containerColumnsModal">

                <div className="columnsModal">
                  <div className="LModal">L1</div>
                  <div className="categoryModal">
                    Saúde
                  </div>
                </div>
                <div className="crevronModal">&gt;</div>
                <div className="columnsModal">
                  <div className="LModal">L2</div>
                  <div className="categoryModal">
                    Equipamento Médico
                  </div>
                </div>
                <div className="crevronModal">&gt;</div>
                <div className="columnsModal">
                  <div className="LModal">L3</div>
                  <div className="categoryModal">
                    Médicos
                  </div>
                </div>
                <div className="crevronModal">&gt;</div>
                <div className="columnsModal">
                  <div className="LModal"> L4</div>
                  <div className="categoryModal">
                    Máscaras Cirurgicas
                  </div>

                </div>
              </div>
            </div>
          </ModalMonitorar>

        ) : null}
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >

        <div style={{
          fontSize: '1.2rem', marginBottom: '1rem', fontWeight: '650', marginTop: '1rem', color: '#23364A', marginRight: '1.4rem',
        }}
        >
          Qual categoria deseja monitorar?
        </div>
        <Button
          style={{
            borderRadius: '50%',
            backgroundColor: '#395C81',
            width: '10px',
            height: '25px',
            fontWeight: '500',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

          }}
          onClick={() => setModalActive(true)}
        >
          ?
        </Button>
      </div>
      <SelectSearch
        vertical
        filtered={filtered}
        filters={filters}
        onChangeFilterCategories={onChangeFilterCategories}
        type="categories"
        setInputKeyword={setInputKeyword}
        setIdChildren={setIdChildren}
        inputKeyword={inputKeyword}
        idChildren={idChildren}
        setFullName={setFullName}
        setVerificationButton={setVerificationButton}
      />

      <div />
      <div style={{ margin: '0 auto' }}>
        <Button
          onClick={() => onClickProp()}
          disabled={verificationButton}
          color="primary"
          size="lg"
          style={{
            height: '45px',
            width: '250px',
            margin: '0 auto',
            fontSize: '1rem',
            marginTop: '1rem',
            fontWeight: '650',

          }}
        >
          Avançar &gt;
        </Button>

      </div>
    </section>
  );
};

export default CatMonitor;
