import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Card, Col, Container, FormGroup, Input, Label, Row,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { changePasswordLogged, changeUserData } from '../../services/users';
import styles from './userdata.module.css';
import ModalComponent from '../../components/modal/ModalComponent';
import { restoreAuth } from '../../store/auth/auth.action';
import inputStyle from '../signup/index.module.scss';
import { ReactComponent as Voltar } from '../../assets/images/Arrow-back.svg';

const UserData = () => {
  const history = useHistory();
  const userSettings = useSelector(({ auth }) => auth.user);
  const userPlan = useSelector(({ auth }) => auth.user.plan);
  const fullName = userSettings.fullname.split(' ');
  const { token } = JSON.parse(sessionStorage.getItem('@userAuth'));
  const [modalActive, setModalActive] = useState(false);
  const [integratedML, setIntegratedML] = useState(false);
  const [modalActiveChangeUserData, setModalActiveChangeUserData] = useState(false);
  const [fieldNotModal, setFieldNotModal] = useState({
    name: fullName.shift(),
    sobrenome: fullName.join(' '),
    cellPhone: userSettings.cell_phone,
    company: userSettings.company,
    email: userSettings.email,
    sellerML: userSettings.sellerML,
  });
  const [field, setField] = useState({});
  const dispatch = useDispatch();

  const maskPhone = (value) => value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{4})(\d+?)$/, '$1');

  // FORM INICIO
  const handleFormNotModalChange = (e) => {
    setFieldNotModal({
      ...fieldNotModal,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === 'cellPhone') {
      setFieldNotModal({ ...fieldNotModal, cellPhone: maskPhone(e.target.value) });
    }
  };

  const changeDataSubmit = async () => {
    try {
      await changeUserData({
        userId: userSettings.id,
        ...fieldNotModal,
      });

      userSettings.fullname = `${fieldNotModal.name} ${fieldNotModal.sobrenome}`;
      userSettings.company = `${fieldNotModal.company}`;
      userSettings.cell_phone = `${fieldNotModal.cellPhone}`;
      userSettings.email = `${fieldNotModal.email}`;
      sessionStorage.setItem('@userAuth', JSON.stringify({ user: userSettings, token }));
      setFieldNotModal({ ...fieldNotModal, senhaAtual: '' });
      const { user } = JSON.parse(sessionStorage.getItem('@userAuth'));
      dispatch(restoreAuth({ user, token }));
      setModalActiveChangeUserData(true);
    } catch (err) {
      toast.error(err);
    }
  };

  // FORM FIM

  // MODAL INICIO
  const handleFormChange = (e) => {
    setField({
      ...field,
      [e.target.name]: e.target.value,
    });
  };
  const teste = () => {
    if (userPlan !== 0) history.push('/sales');
    if (userPlan === 0) history.push('/reactivate-account');
  };
  const integration = () => {
    window.location = 'https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=1501723720239053&redirect_uri=https://app.mercadoacelerado.com.br/access-token';
  };

  const handleValidSubmit = async () => {
    const {
      newPassword, password, confirmationPassword,
    } = field;
    const { id } = await JSON.parse(sessionStorage.getItem('@userAuth')).user;
    if (newPassword === confirmationPassword) {
      try {
        const response = await changePasswordLogged({ userId: id, newPassword, password });
        setModalActive(false);
        toast.success(response.msg);
      } catch (error) {
        toast.error(error.response.data.msg);
      }
    }
  };

  useEffect(() => {
    if (userSettings.sellerML) setIntegratedML(true);
  }, []);
  return (
    <>
      <Container className={styles.mainContainer}>
        <Card className={`d-flex p-3 ${styles.mainCard}`}>
          <Button
            className="w-0 no-bg waves-effect waves-light"
            onClick={teste}
            style={{
              width: '50px', marginBotton: '0.5rem',
            }}
          >
            <Voltar width="25px" height="25px" />
          </Button>
          <Row className={`m-4 ${styles.rowMeusDados}`}>
            Meus dados
          </Row>
          <AvForm className="">
            <Row className={`m-3 ${styles.rowInputs}`}>

              <Col md={7} className={styles.columnLeft}>
                <Row>
                  <Col md={6}>
                    <AvField
                      name="name"
                      label="Nome"
                      for="name"
                      className={inputStyle.white}
                      id="name"
                      type="text"
                      value={fieldNotModal.name}
                      onChange={handleFormNotModalChange}
                      required
                      errorMessage="Insira um nome."
                    />
                  </Col>
                  <Col md={6}>
                    <AvField
                      name="sobrenome"
                      label="Sobrenome"
                      for="sobrenome"
                      id="sobrenome"
                      type="text"
                      className={`mb-2 ${inputStyle.white}`}
                      value={fieldNotModal.sobrenome}
                      onChange={handleFormNotModalChange}
                      required
                      errorMessage="Insira um sobrenome."
                    />
                  </Col>
                </Row>
                <AvField
                  name="cellPhone"
                  label="Celular com DDD"
                  for="cellPhone"
                  id="cellPhone"
                  type="text"
                  className={`mb-4 ${inputStyle.white}`}
                  maxLength="15"
                  value={fieldNotModal.cellPhone}
                  onChange={handleFormNotModalChange}
                  validate={{
                    required: { value: true, errorMessage: 'Insira seu celular.' },
                    minLength: { value: 11, errorMessage: 'Insira um número de celular válido.' },
                  }}
                />
                <AvField
                  name="company"
                  label="Nome da empresa"
                  for="company"
                  id="company"
                  type="text"
                  className={`mb-4 ${inputStyle.white}`}
                  value={fieldNotModal.company}
                  onChange={handleFormNotModalChange}
                  required
                  errorMessage="Insira o nome de sua empresa."
                />

                <FormGroup check inline className="align-items-center mb-4">
                  <Col className="p-0" md={8}>
                    <Label for="sellerML" style={{ fontSize: '0.9rem' }}>
                      Já vende no Mercado Livre?
                      <p style={{ fontSize: '0.75rem', color: '#707070' }}>
                        Ao clicar em &quot;Sim&quot;, solicitaremos
                        {' '}
                        <br />
                        a autorização da sua conta no
                        {' '}
                        <br />
                        Mercado Livre para melhores
                        {' '}
                        <br />
                        orientações e análises.
                      </p>
                    </Label>
                  </Col>
                  <Col className="d-flex justify-content-center align-items-center" md={10}>
                    {integratedML
                      ? (
                        <>
                          <div style={{ paddingBottom: '6.5rem' }}>Conta já integrada!</div>
                        </>
                      )
                      : (
                        <div style={{ paddingBottom: '6.5rem' }}>
                          <Input onClick={integration} type="radio" name="sellerML" id="sellerMLYes" value={1} onChange={() => setFieldNotModal({ ...fieldNotModal, sellerML: 1 })} checked={fieldNotModal.sellerML === 1} />
                          <Label htmlFor="sellerMLYes" style={{ color: '#23364A', marginBottom: 0, cursor: 'Pointer' }}>
                            Sim
                          </Label>
                          <FormGroup check inline />
                          <Input className="teste14" type="radio" name="sellerML" id="sellerMLNo" value={0} onChange={() => setFieldNotModal({ ...fieldNotModal, sellerML: 0 })} checked={fieldNotModal.sellerML === 0} />
                          <Label for="sellerMLNo" style={{ color: '#23364A', marginBottom: 0, cursor: 'Pointer' }}>
                            Não
                          </Label>
                        </div>
                      )}

                  </Col>
                </FormGroup>

              </Col>
              <Col md={5}>
                <AvField
                  name="email"
                  label="Email"
                  for="email"
                  id="email"
                  type="email"
                  className={`mb-4 ${inputStyle.white}`}
                  value={fieldNotModal.email}
                  validate={{
                    required: { value: true, errorMessage: 'insira um endereço de email.' },
                    maxLength: { value: 256 },
                  }}
                  errorMessage="Insira um email válido."
                  onChange={handleFormNotModalChange}
                />
                {' '}
                <AvField
                  name="senhaAtual"
                  label="Senha atual"
                  for="senhaAtual"
                  id="senhaAtual"
                  type="password"
                  className={`mb-4 ${inputStyle.white}`}
                  value={fieldNotModal.senhaAtual}
                  onChange={handleFormNotModalChange}
                />
                <div style={{ marginLeft: '170px' }}>
                  <button style={{ border: 'none', background: 'none' }} type="button" onClick={() => setModalActive(true)}><u>Redefina sua senha</u></button>
                </div>

                <Col className="p-0 mt-4">
                  <Label style={{ fontSize: '0.9rem' }}>
                    Gerenciar informações de pagamento
                    <p style={{ fontSize: '0.75rem', color: '#707070' }}>
                      Forma, faturamento e histórico
                      {' '}
                      <br />
                      de pagamentos
                    </p>
                  </Label>
                  <a href="https://app.mercadoacelerado.com.br/portal-stripe">
                    {' '}
                    <u style={{ color: '#23364A' }}>Ver mais</u>
                    {' '}
                  </a>
                </Col>

              </Col>
            </Row>
            <Row className="mb-3">
              <Button
                color="primary"
                size="lg"
                type="submit"
                form="signupForm"
                md={6}
                className="mx-auto mt-4"
                style={{
                  width: '350px', height: '50px', borderRadius: '3px',
                }}
                onClick={changeDataSubmit}
                disabled={!fieldNotModal.senhaAtual}
              >
                Atualizar
              </Button>
            </Row>
          </AvForm>
        </Card>
        <p style={{ textAlign: 'center' }}>
          Precisa de ajuda? Entre em contato:
          {' '}
          <a href="mailto:suporte@mercadoacelerado.com.br" target="_blank" rel="noreferrer">suporte@mercadoacelerado.com.br</a>
        </p>
      </Container>

      {modalActive && (
      <ModalComponent ModalActive={() => setModalActive(false)} title="Redefinir senha">
        <div className={styles.ChangePasswordDivContainer}>
          <AvForm onSubmit={handleValidSubmit}>
            <div style={{ height: '115px' }}>
              <AvField
                name="password"
                type="password"
                className={`${inputStyle.white} ${styles.ChangePasswordField}`}
                onChange={handleFormChange}
                label="Senha atual"
                required
                errorMessage="Preencha sua senha"
              />
            </div>
            <div style={{ height: '115px' }}>
              <AvField
                name="newPassword"
                label="Senha"
                for="newPassword"
                id="newPassword"
                type="password"
                className={`${inputStyle.white} ${styles.ChangePasswordField}`}
                onChange={handleFormChange}
                validate={{
                  required: { value: true, errorMessage: 'Preencha aqui sua senha.' },
                  minLength: { value: 6, errorMessage: 'Insira uma senha com no mínimo 6 caracteres.' },
                  maxLength: { value: 256 },
                }}
              />
            </div>
            <div style={{ height: '115px' }}>
              <AvField
                name="confirmationPassword"
                label="Confirmação da senha"
                for="confirmationPassword"
                id="confirmationPassword"
                type="password"
                onChange={handleFormChange}
                className={`${inputStyle.white}`}
                required
                errorMessage="Senha inválida"
                validate={{ match: { value: 'newPassword', errorMessage: 'As senhas devem ser idênticas!' } }}
              />
            </div>

            <Button className={styles.ChangePasswordBtn} color="primary" size="lg" type="submit" block>
              {' '}
              Atualizar
            </Button>
          </AvForm>

        </div>
      </ModalComponent>
      )}

      {modalActiveChangeUserData && (
      <ModalComponent
        notShowHeader
        ModalActive={() => setModalActiveChangeUserData(true)}
        width="50%"
        height="40%"
      >
        <div className={styles.divChangeUserData}>
          <h2 style={{ color: '#23364A', fontWeight: 'bold' }}>Seus dados foram alterados com sucesso!</h2>
          <Button
            className={styles.btnChangeUserData}
            color="primary"
            size="lg"
            type="submit"
            onClick={() => setModalActiveChangeUserData(false)}
            block
          >
            Entendi
          </Button>
        </div>
      </ModalComponent>
      )}
    </>
  );
};

export default UserData;
