import React from 'react';
import ReactDOM from 'react-dom';
import ReduxToastr from 'react-redux-toastr';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import Routes from './routes';
import store from './store';
import Themes from './components/themes';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './assets/scss/fonts.scss';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Themes />
      <Routes />

      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="bottom-right"
        getState={(state) => state.toastr}
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),

);

serviceWorker.unregister();
