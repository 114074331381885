import axios from 'axios';
import config from '../config';

const Api = axios.create({
  baseURL: config.baseURL,
  headers: { 'Content-Type': 'application/json' },
  responseType: 'json',
});

const DEFAULT_ERROR = { error: true, errors: [{ message: 'Falha de rede.' }] };

Api.interceptors.response.use(
  (response) => response,
  (error) => {
    // eslint-disable-next-line no-param-reassign
    if (!error.response) error.response = { data: DEFAULT_ERROR };

    return Promise.reject(error);
  },
);

const setApiToken = (token) => {
  const Authorization = token ? `Bearer ${token}` : null;
  Api.defaults.headers.common.Authorization = Authorization;

  Api.interceptors.request.use((axiosConfig) => {
    // eslint-disable-next-line no-param-reassign
    axiosConfig.headers.Authorization = `Bearer ${token}`;
    return axiosConfig;
  });
};

export default Api;

export { setApiToken };
