/* eslint-disable camelcase */
const LayoutTypes = {
  ACTIVATE_NON_AUTH_LAYOUT: '@layout/ACTIVATE_NON_AUTH_LAYOUT',
  TOGGLE: '@layout/TOGGLE',
  TOGGLE_RIGHT_SIDEBAR: '@layout/TOGGLE_RIGHT_SIDEBAR',
  HIDE_RIGHT_SIDEBAR: '@layout/HIDE_RIGHT_SIDEBAR',
  CHANGE_LAYOUT: '@layout/CHANGE_LAYOUT',
  CHANGE_SIDEBAR_THEME: '@layout/CHANGE_SIDEBAR_THEME',
  CHANGE_LAYOUT_WIDTH: '@layout/CHANGE_LAYOUT_WIDTH',
  CHANGE_SIDEBAR_TYPE: '@layout/CHANGE_SIDEBAR_TYPE',
  CHANGE_PRELOADER: '@layout/CHANGE_PRELOADER',
  CHANGE_TOPBAR_THEME: '@layout/CHANGE_TOPBAR_THEME',
  CHANGE_THEME: '@layout/CHANGE_THEME',
};

const changeLayout = (layout) => ({
  type: LayoutTypes.CHANGE_LAYOUT,
  payload: layout,
});

const changePreloader = (layout) => ({
  type: LayoutTypes.CHANGE_PRELOADER,
  payload: layout,
});

const changeSidebarTheme = (theme) => ({
  type: LayoutTypes.CHANGE_SIDEBAR_THEME,
  payload: theme,
});

const changeLayoutWidth = (width, layoutType) => ({
  type: LayoutTypes.CHANGE_LAYOUT_WIDTH,
  payload: { width, layoutType },
});

const changeSidebarType = (sidebarType, isMobile) => ({
  type: LayoutTypes.CHANGE_SIDEBAR_TYPE,
  payload: { sidebarType, isMobile },
});

const changeTopbarTheme = (topbarTheme) => ({
  type: LayoutTypes.CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
});

const activateNonAuthLayout = () => ({
  type: LayoutTypes.ACTIVATE_NON_AUTH_LAYOUT,
  payload: {
    topbar: false,
    sidebar: false,
    footer: false,
    layoutType: 'NonAuth',
  },
});

const toggleSidebar = (is_toggle) => ({
  type: LayoutTypes.TOGGLE,
  payload: { is_toggle },
});

const toggleRightSidebar = (is_toggle) => ({
  type: LayoutTypes.TOGGLE_RIGHT_SIDEBAR,
  payload: { is_toggle },
});

const hideRightSidebar = () => ({
  type: LayoutTypes.HIDE_RIGHT_SIDEBAR,
  payload: null,
});

const changeTheme = (theme) => ({
  type: LayoutTypes.CHANGE_THEME,
  payload: theme,
});

export {
  LayoutTypes,
  changeLayout,
  changePreloader,
  changeSidebarTheme,
  changeLayoutWidth,
  changeSidebarType,
  changeTopbarTheme,
  activateNonAuthLayout,
  toggleSidebar,
  toggleRightSidebar,
  hideRightSidebar,
  changeTheme,
};
