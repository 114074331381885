/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useCallback } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import {
  Col,
  CardBody,
  Button,
} from 'reactstrap';
import styles from '../signup/index.module.scss';
import { confirmCode } from '../../services/auth';

const RecoveryCode = ({
  setStepper, setUser, setConfirmationCode, confirmationCode, email,
}) => {
  const onChange = (setState) => (event) => setState(event.target.value);

  const sendCode = useCallback(async () => {
    if (confirmationCode) {
      try {
        const response = await confirmCode(confirmationCode, email);
        if (response) {
          setUser(response.user);
          setStepper(4);
          toast.success(response.data.msg);
        }
      } catch ({ response }) {
        toast.error(response?.data.msg);
      }
    }
  }, [setStepper, setUser, confirmationCode, email]);

  const handleClick = () => {
    setStepper(2);
  };

  return (
    <Col md={6} className="d-flex align-items-center px-5">
      <CardBody className="d-flex flex-column pt-5 pb-5" style={{ height: '95%' }}>
        <h2
          className="font-size-34 font-weight-bold pb-4"
          style={{ color: '#23364A' }}
        >
          Código enviado!

        </h2>
        <p className="font-size-19 mb-4" style={{ color: '#23364A' }}>
          Verifique seu email para mais informações.
        </p>
        <AvForm id="confirmCode-form" onSubmit={sendCode}>
          <AvField
            name="code"
            label="Preencha o código recebido"
            placeholder="Coloque aqui o código recebido"
            className={styles.white}
            type="text"
            value={confirmationCode}
            onChange={onChange(setConfirmationCode)}
            validate={{
              required: { value: true, errorMessage: 'Preencha o campo.' },
            }}
          />
        </AvForm>
        <button
          type="button"
          style={{
            background: 'none',
            border: 'none',
            color: '#23364A',
            alignSelf: 'flex-start',
          }}
          onClick={handleClick}
        >
          &lt;
          <u> Voltar</u>

        </button>

        <div className="mt-auto">
          <Button
            type="submit"
            form="confirmCode-form"
            size="lg"
            block
            color="primary"
            className="w-md font-weight-bold waves-effect waves-light"

          >
            Confirmar
          </Button>
          <ToastContainer />
        </div>
      </CardBody>
    </Col>
  );
};

RecoveryCode.propTypes = {
  setStepper: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  setConfirmationCode: PropTypes.func.isRequired,
  confirmationCode: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default RecoveryCode;
