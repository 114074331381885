import Api from '../../services/api';

const AuthActions = {
  SIGNIN: '@auth/signin',
  RECEIVE_AUTH: '@auth/receive',
  ERROR_AUTH: '@auth/error',
  SIGNOUT: '@auth/signout',
  ERROR_CLEAN: '@auth/error/clean',
  ID_CATEGORIES: '@auth/categoriesOfUser',
};

const NO_USER = { user: {}, token: null };

const restoreAuth = ({ user, token } = NO_USER) => (dispatch) => {
  dispatch({ type: AuthActions.RECEIVE_AUTH, payload: { user, token } });
};

const categoriesOfUser = (userId) => (dispatch) => {
  Api.get(`/users/idOfCategoriesUser/${userId}`)
    .then((res) => dispatch({
      type: AuthActions.ID_CATEGORIES,
      payload: [...res.data],
    }));
};
const clearCategories = () => (dispatch) => {
  dispatch({
    type: AuthActions.ID_CATEGORIES,
    payload: [],
  });
};

const signin = ({ email, password, remember }) => async (dispatch) => {
  dispatch({ type: AuthActions.SIGNIN });
  try {
    const responseUser = await Api.post('/auth/signin', { email, password });
    dispatch({
      type: AuthActions.RECEIVE_AUTH,
      payload: { ...responseUser.data, remember },
    });
    return responseUser.data;
  } catch (err) {
    const payload = err.response.data;
    return dispatch({ type: AuthActions.ERROR_AUTH, payload });
  }
  // Api.post('/auth/signin/admin', { email, password })
  // .then((res) => dispatch({
  //   type: AuthActions.RECEIVE_AUTH,
  //   payload: { ...res.data, remember },
  // }))
  // .catch((err) => {
  //   const payload = err.response.data;

  //   return dispatch({ type: AuthActions.ERROR_AUTH, payload });
  // });
};

const forgotPassword = ({ email }) => (dispatch) => {
  dispatch({ type: AuthActions.SIGNIN });

  Api.post('/auth/forgot-password', { email })
    .then(() => dispatch({ type: AuthActions.RECEIVE_AUTH, payload: NO_USER }))
    .catch((err) => {
      const payload = err.response.data;

      return dispatch({ type: AuthActions.ERROR_AUTH, payload });
    });
};

const resetPassword = ({ email, newPassword, recoveryCode }) => (dispatch) => {
  dispatch({ type: AuthActions.SIGNIN });

  Api.post('/auth/reset-password', { email, newPassword, recoveryCode })
    .then((resp) => dispatch({
      type: AuthActions.RECEIVE_AUTH,
      payload: resp.data,
    }))
    .catch((err) => {
      const payload = err.response.data;

      return dispatch({ type: AuthActions.ERROR_AUTH, payload });
    });
};

const signout = () => (dispatch) => dispatch({ type: AuthActions.SIGNOUT });

const cleanErrorAuth = () => (dispatch) => {
  dispatch({ type: AuthActions.ERROR_CLEAN });
};

export {
  AuthActions,
  signin,
  restoreAuth,
  signout,
  forgotPassword,
  resetPassword,
  cleanErrorAuth,
  categoriesOfUser,
  clearCategories,
};
