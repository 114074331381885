import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getPayment } from '../../services/mercadoPago';
import Loading from '../loading/Loading';
import { approvedPlanOfUser } from '../../services/users';

const MpToken = () => {
  const history = useHistory();
  const idUser = useSelector(({ auth }) => auth.user.id);

  const paymentStatus = {
    approved: (planId) => {
      approvedPlanOfUser(idUser, planId);
      console.log('Aprovado', planId);
    },
    rejected: (planId) => {
      console.log('Rejeitado', planId);
    },
    refunded: (planId) => {
      console.log('Extornado', planId);
    },
  };

  async function verifyPayment(id) {
    const response = await getPayment(id);
    paymentStatus[response?.status](response?.metadata.template_id);
  }

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('@userAuth'));
    if (!user) history.push('/signIn');

    const urlParams = new URLSearchParams(window.location.search);
    const topic = urlParams.get('topic');
    const idMP = urlParams.get('id');
    // const error = urlParams.get('error');
    if (!idMP || !topic) {
      history.push('/home');

      console.log('Não existe id de pagamento');
    } else {
      verifyPayment(idMP);
      console.log('Existe id de pagamento');
    }
    // if (user) {

    // }
  }, []);

  return (
    <Loading />
  );
};

export default MpToken;
