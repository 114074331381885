import React, { useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';

import { useSelector } from 'react-redux';
import FirstStep from './components/FirstStep';
import CatMonitorar from './components/catMonitorar';
import ConfirmCategory from './components/confirmCategory';
import Pay from './components/pay/index';
import styles from './styles.module.scss';

const Welcome = () => {
  const [step, setStep] = useState(0);
  const [fullName, setFullName] = useState('');
  const [inputKeyword, setInputKeyword] = useState(false);
  const [idChildren, setIdChildren] = useState('');
  const [totalWidth, setTotalWidth] = useState();
  const userPlan = useSelector(({ auth }) => auth.user.plan);

  const stepHandler = {
    0: <FirstStep onClick={() => setStep((currentStep) => currentStep + 1)} />,
    1: <CatMonitorar
      onClick={() => setStep((currentStep) => currentStep + 1)}
      setFullName={setFullName}
      setInputKeyword={setInputKeyword}
      inputKeyword={inputKeyword}
      idChildren={idChildren}
      setIdChildren={setIdChildren}

    />,
    2: <ConfirmCategory
      onClick={() => setStep((currentStep) => currentStep + 1)}
      onBack={() => setStep((currentStep) => currentStep - 1)}
      fullName={fullName}
      setInputKeyword={setInputKeyword}
      inputKeyword={inputKeyword}
      idChildren={idChildren}
      setIdChildren={setIdChildren}
    />,
    3: <Pay
      onClick={() => setStep((currentStep) => currentStep + 1)}
      onBack={() => setStep((currentStep) => currentStep - 1)}
    />,

    4: <></>,

  };
  useEffect(() => {
    const widthContainer = document.getElementById('stepperContainer').clientWidth;
    setTotalWidth(widthContainer / (4 / step));
  }, [step]);

  useEffect(() => {
    if (userPlan !== null) {
      window.location.replace('/sales');
    }
  }, [userPlan]);

  const stepNames = {
    0: 'Boas Vindas',
    1: 'Monitorar',
    2: 'Confirmar',
    3: 'Pagamento',
    4: 'Feito!',
  };

  return (

    <Container>
      <Card
        style={{
          maxWidth: '1100px', minHeight: '500px', boxShadow: '0 0 0.5rem rgba(112,112,112, 0.4)', margin: '0 auto',
        }}
      >
        <CardBody
          className={styles.cardBody}
          style={{ textAlign: 'center' }}
        >
          <div
            id="stepperContainer"
            style={{
              height: '1px', width: '94%', marginLeft: '2.4rem', position: 'relative',
            }}
          >
            <div style={{
              width: totalWidth, height: '1px', backgroundColor: '#D8DBDD', position: 'absolute', left: '0px', top: '31.5px',
            }}
            />
          </div>
          <div className={styles.stepper}>
            {Object.keys(stepHandler).map((item) => (
              <div className={styles.step}>
                <span className={step >= Number(item)
                  ? styles.stepDotActive : styles.stepDotInactive}
                />
                <span className={step >= Number(item)
                  ? styles.stepText : styles.stepTextWhite}
                >
                  {stepNames[item]}

                </span>
              </div>
            ))}
          </div>
          {stepHandler[step]}
        </CardBody>
      </Card>
    </Container>

  );
};

export default Welcome;
