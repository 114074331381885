import React, { useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import CategoryPlan from './components/categoryPlan';

// import Page1 from './components/page1';
import Page2 from './components/page2';
import styles from './styles.module.scss';

const CancelPlan = () => {
  const [step, setStep] = useState(0);

  const stepHandler = {
    // 0: <Page1 onClick={() => setStep((currentStep) => currentStep + 1)} />,
    0: <Page2
      onClick={() => setStep((currentStep) => currentStep + 1)}
    />,
    1: <CategoryPlan
      onClick={() => setStep((currentStep) => currentStep + 1)}
    />,
    // 2: <Page3
    //   onClick={() => setStep((currentStep) => currentStep + 1)}
    // />,
  };

  return (
    <Container>
      <Card
        style={{
          maxWidth: '1200px', minHeight: '550px', boxShadow: '0 0 0.5rem rgba(112,112,112, 0.4)',
        }}
      >
        <CardBody
          className={styles.cardBody}
          style={{ textAlign: 'center', margin: '0' }}
        >
          <div className={styles.stepper} />
          {stepHandler[step]}
        </CardBody>
      </Card>
    </Container>
  );
};

export default CancelPlan;
