/* eslint-disable no-unused-vars */
import { CategoriesTypes } from './categories.action';

const INITIAL_STATE = {
  list: [],
  isFetching: false,
  error: false,
  errors: [],

  page: [],
  paging: {
    error: false,
    errors: [],
    fetching: false,
    offset: 0,
    page: 1,
    limit: 30,
    totalItems: 0,
    maxPage: 0,
  },

  ordering: {
    column: null,
    orderby: null,
    availableOrderby: [],
  },

  tree: {},

  filters: [],
  filtersLoading: false,

  categoryPlan: 0,
  wasPlanUpdated: false,
  isFetchingCategoryOnboarding: false,
};

const onFetching = (state) => ({ ...state, isFetching: true, errors: [] });

const onError = (state, action) => {
  const { errors } = action.payload;
  return {
    ...state,
    error: true,
    errors,
    isFetching: false,
  };
};

const onGetReceive = (state, action) => {
  const { list } = action.payload;

  return {
    ...state,
    list,
    isFetching: false,
    error: false,
    errors: [],
  };
};

const onPageError = (state, action) => {
  const { errors } = action.payload;
  const { paging } = state;
  return {
    ...state,
    paging: {
      ...paging,
      error: true,
      errors,
    },
  };
};

const onPageFetch = (state) => {
  const { paging } = state;

  return {
    ...state,
    paging: {
      ...paging,
      fetching: true,
      error: false,
      errors: [],
    },
  };
};

const onPageReceive = (state, action) => {
  const oldPaging = state.paging;
  const { page, paging, ordering } = action.payload;

  return {
    ...state,
    page,
    ordering,
    paging: {
      ...oldPaging,
      fetching: false,
      error: false,
      errors: [],
      ...paging,
    },
  };
};

const updateArray = (arr, newItem) => {
  const newArr = [].concat(arr);
  const itemI = newArr.findIndex((c) => c.id === newItem.id);
  if (itemI >= 0) newArr[itemI] = { ...newArr[itemI], ...newItem };

  return newArr;
};

const onTracking = (state, action) => {
  const { category } = action.payload;
  const { list: oldList, page: oldPage } = state;

  const page = updateArray(oldPage, category);
  const list = updateArray(oldList, category);

  return { ...state, page, list };
};

const onTree = (state, action) => {
  const tree = action.payload;
  return { ...state, tree };
};

const onNode = (state, action) => {
  try {
    const { data } = action.payload;
    if (!data) return state;

    const tree = { ...state.tree };
    const { node } = action.payload.data;

    const l1Index = tree.children
      .findIndex((n) => node.pathFromRoot.some((p) => p === n.id));
    if (l1Index < 0) return state;
    const level1 = tree.children[l1Index];
    if (level1.id === node.id) {
      tree.children[l1Index] = node;
      return { ...state, tree };
    }

    const l2Index = level1.children
      .findIndex((n) => node.pathFromRoot.some((p) => p === n.id));
    if (l2Index < 0) return state;
    const level2 = level1.children[l2Index];
    if (level2.id === node.id) {
      level1.children[l2Index] = node;
      return { ...state, tree };
    }

    const l3Index = level2.children
      .findIndex((n) => node.pathFromRoot.some((p) => p === n.id));
    if (l3Index < 0) return state;
    const level3 = level2.children[l3Index];
    if (level3.id === node.id) {
      level2.children[l3Index] = node;
      return { ...state, tree };
    }

    const l4Index = level3.children
      .findIndex((n) => node.pathFromRoot.some((p) => p === n.id));
    if (l4Index < 0) return state;
    const level4 = level3.children[l4Index];
    if (level4.id === node.id) {
      level3.children[l4Index] = node;
      return { ...state, tree };
    }

    return state;
  } catch {
    return state;
  }
};

const onFiltersError = (state) => ({ ...state, filtersLoading: false });

const onFilterFetch = (state, payload) => {
  if (!payload || !payload.payload || !payload.payload.length) {
    return { ...state, filtersLoading: true };
  }

  const filters = [].concat(state.filters);
  const [, level] = payload.payload[payload.payload.length - 1].key?.split('_L');
  filters.splice(Number(level + 1));

  return { ...state, filters, filtersLoading: true };
};

const onFilterReceive = (state, action) => {
  const { filters } = action.payload;

  return {
    ...state,
    filters,
    filtersLoading: false,
  };
};

const onPlanFetch = (state, action) => ({ ...state, categoryPlan: action.payload });

const planUpdate = (state, action) => ({ ...state, wasPlanUpdated: action.payload });

const onBoardingCategory = (state, action) => ({
  ...state,
  isFetchingCategoryOnboarding: action.payload,
});

const categoriesReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CategoriesTypes.FETCH: return onFetching(state);
    case CategoriesTypes.ERROR: return onError(state, action);
    case CategoriesTypes.GET_RECEIVE: return onGetReceive(state, action);
    case CategoriesTypes.PAGE_ERROR: return onPageError(state, action);
    case CategoriesTypes.PAGE_FETCH: return onPageFetch(state, action);
    case CategoriesTypes.PAGE_RECEIVE: return onPageReceive(state, action);
    case CategoriesTypes.TRACKING_RECEIVE: return onTracking(state, action);
    case CategoriesTypes.TREE_RECEIVE: return onTree(state, action);
    case CategoriesTypes.NODE_RECEIVE: return onNode(state, action);
    case CategoriesTypes.FILTERS_ERROR: return onFiltersError(state, action);
    case CategoriesTypes.FILTERS_FETCH: return onFilterFetch(state, action);
    case CategoriesTypes.FILTERS_RECEIVE: return onFilterReceive(state, action);
    case CategoriesTypes.NEWPLAN: return onPlanFetch(state, action);
    case CategoriesTypes.PLANUPTADE: return planUpdate(state, action);
    case CategoriesTypes.CATEGORYONBOARD: return onBoardingCategory(state, action);
    default: return state;
  }
};

export default categoriesReducers;
