import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as toastr } from 'react-redux-toastr';
import auth from './auth';
import Breadcrumb from './breadcrumb';
import categories from './categories';
import Layout from './layout';
import loaders from './loaders';
import sales from './sales';
import seller from './seller';
import trends from './trends';
import sellerConfig from './sellerconfig';

const reducers = combineReducers({
  auth,
  toastr,
  categories,
  loaders,
  sales,
  trends,
  seller,
  sellerConfig,

  // Used by Lexa-Dashboard template
  Layout,
  Breadcrumb,
});

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk)),
);

export default store;
