import { toastr } from 'react-redux-toastr';
import Api from '../../services/api';

export const LoadersTypes = {
  FETCH: '@loaders/fetch',
  ERROR: '@loaders/error',
  GET_RECEIVE: '@loaders/get/receive',

  PAGE_FETCH: '@loaders/page/fetch',
  PAGE_ERROR: '@loaders/page/error',
  PAGE_RECEIVE: '@loaders/page/receive',

  LOADER_RECEIVE: '@loaders/run/receive',
};

export const getLoaders = () => (dispatch) => {
  dispatch({ type: LoadersTypes.FETCH });

  Api.get('/loaders?limit=all')
    .then((resp) => {
      const payload = resp.data;
      dispatch({ type: LoadersTypes.GET_RECEIVE, payload });
    }).catch((err) => {
      toastr.error('Falha ao carregar carregadores.');
      const payload = err.response.data;
      dispatch({ type: LoadersTypes.ERROR, payload });
    });
};

export const getLoadersPage = ({
  page = 1,
  limit = 30,
  search = '',
  querys = [],
} = {}) => (dispatch) => {
  dispatch({ type: LoadersTypes.PAGE_FETCH });

  const query = [
    `limit=${limit}`,
    `page=${page}`,
    querys && querys.map((q) => `${q.key}=${q.value}`).join('&'),
    search && `search=${search}`,
  ]
    .filter((q) => q)
    .join('&');

  Api.get(`/loaders?${query}`)
    .then((resp) => {
      const payload = resp.data;
      dispatch({ type: LoadersTypes.PAGE_RECEIVE, payload });
    }).catch((err) => {
      toastr.error('Falha ao carregar tendências.');
      const payload = err.response.data;
      dispatch({ type: LoadersTypes.PAGE_ERROR, payload });
    });
};

export const postCreateLoader = ({ categoryId } = {}) => (dispatch) => {
  Api.post(`/loaders/${categoryId}`)
    .then((resp) => {
      const payload = resp.data;
      dispatch({ type: LoadersTypes.LOADER_RECEIVE, payload });
    }).catch(() => {
      toastr.error('Falha ao alterar carregar categoria.');
    });
};
