import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button, Row,
} from 'reactstrap';
import Categories from '../categories';
import Competition from '../competition';

const StepSettings = () => {
  const [step, setStep] = useState(0);
  const [verification, setVerification] = useState();
  const userID = useSelector(({ auth }) => auth.user.id);

  const stepHandler = {
    0: <Categories
      onClick={() => setStep((currentStep) => currentStep + 1)}
    />,
    1: <Competition
      onClick={() => setStep((currentStep) => currentStep + 1)}
    />,
  };
  useEffect(() => {
    if (userID === 179 || userID === 199) setVerification(true);
  }, []);

  return (
    <Row style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ margin: '0 auto' }}>
        <p style={{ color: '#567FAA' }}>Qual monitoramento você deseja configurar:</p>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2.5rem' }}>
          <Button
            style={{
              marginRight: '1rem', width: '50%', backgroundColor: step === 1 ? '#FFFFFF' : null, color: step === 1 ? '#567FAA' : null,
            }}
            onClick={() => setStep(0)}
          >
            Categoria
          </Button>
          {verification
            ? (
              <Button
                style={{ width: '50%', backgroundColor: step === 0 ? '#FFFFFF' : null, color: step === 0 ? '#567FAA' : null }}
                onClick={() => setStep(1)}
              >
                Concorrência
              </Button>
            )
            : (
              <Button disabled style={{ width: '50%' }}>Em breve</Button>
            ) }
        </div>
      </div>
      <div style={{
        width: '100%', height: '1px', backgroundColor: '#dee2e6', position: 'absolute', left: '0px', top: '11.6rem',
      }}
      />

      {stepHandler[step]}

    </Row>
  );
};

export default StepSettings;
