import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeTheme } from '../../store/layout';
import '../../libs/lexa-dashboard/src/theme.scss';

const LightTheme = React.lazy(() => import('./LightTheme'));
const DarkTheme = React.lazy(() => import('./DarkTheme'));

const Themes = () => {
  const currentTheme = useSelector((state) => state.Layout.theme);
  const dispatch = useDispatch();

  useEffect(() => {
    const localTheme = localStorage.getItem('@ml/theme');
    dispatch(changeTheme(localTheme));
  }, [dispatch]);

  return (
    <Suspense fallback={<></>}>
      {currentTheme === 'light' && <LightTheme />}
      {currentTheme === 'dark' && <DarkTheme />}
    </Suspense>
  );
};

export default Themes;
