import { LayoutTypes } from './layout.action';

const INITIAL_STATE = {
  theme: 'light',
  layoutType: 'horizontal',
  topbarTheme: 'light',
  leftSideBarTheme: 'dark',
  layoutWidth: 'fluid',
  leftSideBarType: 'default',
  isPreloader: false,
  is_toggle: true,
  show_rightsidebar: false,
  isMobile: false,
};

const layout = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LayoutTypes.CHANGE_LAYOUT: {
      return { ...state, layoutType: action.payload };
    }
    case LayoutTypes.CHANGE_SIDEBAR_THEME: {
      return { ...state, leftSideBarTheme: action.payload };
    }
    case LayoutTypes.CHANGE_PRELOADER: {
      return { ...state, isPreloader: action.payload };
    }
    case LayoutTypes.CHANGE_LAYOUT_WIDTH: {
      return { ...state, layoutWidth: action.payload.width };
    }
    case LayoutTypes.CHANGE_SIDEBAR_TYPE: {
      return { ...state, leftSideBarType: action.payload.sidebarType };
    }
    case LayoutTypes.CHANGE_TOPBAR_THEME: {
      return { ...state, topbarTheme: action.payload };
    }
    case LayoutTypes.ACTIVATE_NON_AUTH_LAYOUT: {
      return { ...state, ...action.payload };
    }
    case LayoutTypes.TOGGLE: {
      return { ...state, is_toggle: action.payload };
    }
    case LayoutTypes.TOGGLE_RIGHT_SIDEBAR: {
      return { ...state, show_rightsidebar: action.payload };
    }
    case LayoutTypes.HIDE_RIGHT_SIDEBAR: {
      return { ...state, show_rightsidebar: false };
    }
    case LayoutTypes.CHANGE_THEME: {
      return { ...state, theme: action.payload };
    }
    default: return state;
  }
};

export default layout;
