import React from 'react';
import PropTypes from 'prop-types';
import './ModalMonitorar.scss';
import {
  Button,
} from 'reactstrap';

const ModalComponent = ({
  title, children, ModalActive, widthHelper,
}) => (
  <div className="ContainerHelper">
    <div className="CardHelper" style={{ width: widthHelper }}>

      <header className="HeaderHelper">
        <div className="ModalTitle">
          {title}
        </div>
      </header>

      <main className="ContentHelper">
        {children}
      </main>
      <Button
        style={{
          height: '40px', width: '13rem', fontSize: '0.8rem', fontWeight: 'bold',
        }}
        color="primary"
        className="primary waves-effect waves-light"
        onClick={ModalActive}
      >
        Entendi
      </Button>

    </div>
  </div>
);

ModalComponent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
  ModalActive: PropTypes.bool,
  widthHelper: PropTypes.string,
};

ModalComponent.defaultProps = {
  title: '',
  children: '',
  ModalActive: '',
  widthHelper: '100%',
};
export default ModalComponent;
