import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import config from '../config';

const filteredToQuery = (filtered) => (
  Object.keys(filtered)
    .map((k) => {
      const v = filtered[k];
      if (!v) return null;
      return { key: k, value: v };
    })
    .filter((q) => q)
);

const useTableData = ({ reducer, action }) => {
  const [filtered, setFiltered] = useState({});
  const [searchInput, setSearch] = useState('');
  const rows = useSelector((state) => state[reducer].page);
  const isFetching = useSelector((state) => state[reducer].paging.fetching);
  const paging = useSelector((state) => state[reducer].paging || {});
  const ordering = useSelector((state) => state[reducer].ordering || {});
  const userIdRedux = useSelector(({ auth }) => auth.user.id);
  const dispatch = useDispatch();

  const onRequest = useCallback(({
    page = 1,
    querys = undefined,
    search = undefined,
    limit = undefined,
    order = undefined,
    orderby = undefined,
  } = {}) => {
    dispatch(action({
      page,
      limit,

      search,
      querys,

      order,
      orderby,
      userIdRedux,
    }));
  }, [dispatch, action]);

  const onChangeSearch = useCallback(() => {
    const querys = filteredToQuery(filtered);
    const { column: orderby, order } = ordering;

    onRequest({
      page: 1,
      limit: paging.limit,

      search: searchInput,
      querys,

      order,
      orderby,
    });
  }, [onRequest, searchInput, filtered, paging.limit, ordering]);

  const onKeyDown = useCallback((e) => {
    if (e.keyCode === 13) onChangeSearch();
  }, [onChangeSearch]);

  const onCancelSearch = useCallback(() => {
    const querys = filteredToQuery(filtered);
    const { column: orderby, order } = ordering;

    onRequest({
      page: 1,
      limit: paging.limit,

      search: '',
      querys,

      order,
      orderby,
    });
    setSearch('');
  }, [onRequest, filtered, paging.limit, ordering]);

  const onChangeOrder = useCallback(({ orderby, order }) => {
    const querys = filteredToQuery(filtered);

    onRequest({
      page: 1,
      limit: paging.limit,

      search: searchInput,
      querys,

      order,
      orderby,
    });
  }, [onRequest, filtered, searchInput, paging.limit]);

  const onPageChange = useCallback(({ selected }) => {
    const querys = filteredToQuery(filtered);
    const { column: orderby, order } = ordering;

    onRequest({
      page: selected + 1,
      limit: paging.limit,

      search: searchInput,
      querys,

      order,
      orderby,
    });
  }, [onRequest, searchInput, filtered, ordering, paging.limit]);

  const onChangeFilter = useCallback((e, key) => {
    const { value } = e.target;
    const { id, keyword } = JSON.parse(value);

    const newFiltered = typeof keyword === 'string' ? { [key.key]: id, keyword }
      : { ...filtered, [key.key]: id };

    const querys = filteredToQuery(newFiltered);
    const { column: orderby, order } = ordering;

    setFiltered(newFiltered);

    onRequest({
      page: 1,
      limit: paging.limit,

      search: searchInput,
      querys,

      order,
      orderby,
    });
  }, [onRequest, filtered, searchInput, paging.limit, ordering]);

  const onChangeFilterCategories = useCallback((e, key) => {
    const { value } = e.target;
    const newFiltered = { ...filtered, [key]: value };

    const [, level] = key.split('_L');

    for (let index = Number(level) + 1; index <= 4; index += 1) {
      delete newFiltered[`category_L${index}`];
      // newFiltered[`category_L${index}`] = null;
    }

    const querys = filteredToQuery(newFiltered);
    const { column: orderby, order } = ordering;

    setFiltered(newFiltered);

    onRequest({
      page: 1,
      limit: paging.limit,

      search: searchInput,
      querys,

      order,
      orderby,
    });
  }, [onRequest, filtered, searchInput, paging.limit, ordering]);

  const getExportUrl = useCallback((path, accept, limit, user) => {
    const orderby = ordering?.column;
    const order = ordering?.order;
    const querys = filteredToQuery(filtered);

    const query = [
      `accept=${accept}`,
      `userIdRedux=${user}`,
      'page=1',
      `limit=${limit || 1000}`,
      searchInput && `search=${searchInput}`,
      querys.map((q) => `${q.key}=${q.value}`).join('&'),
      order && `order=${order}`,
      orderby && `orderby=${orderby}`,
    ].filter((f) => f).join('&');

    return `${config.baseURL}/${path}?${query}`;
  }, [filtered, ordering, searchInput]);

  const onSetDefaultQuery = useCallback((query) => {
    const querys = filteredToQuery(query);
    setFiltered(query);

    onRequest({ querys });
  }, [onRequest]);

  const clearFilter = () => {
    setFiltered({ category_L0: '' });
  };

  useEffect(() => {
    onRequest();
  }, [onRequest]);

  return {
    // data
    rows,
    isFetching,
    setSearch,
    getExportUrl,
    onRequest,
    // querys
    paging,
    ordering,
    searchInput,
    filtered,

    // handlers
    onChangeFilter,
    onChangeFilterCategories,
    onChangeOrder,
    onPageChange,
    onChangeSearch,
    onKeyDown,
    onCancelSearch,
    onSetDefaultQuery,
    clearFilter,
  };
};

export default useTableData;
