/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Container from 'reactstrap/lib/Container';
import { Link, useLocation } from 'react-router-dom';
import logoDark from '../../../assets/images/Logo_MA_Grey.png';
import logoWhite from '../../../assets/images/Logo_MA_White.svg';
import NavbarSignin from './NavbarSignin';
import Navbar from './Navbar';
import ProfileMenu from '../Menus/profileMenu';
import './topbar.css';

const Topbar = ({ bgColor, isAccountCreated }) => {
  // const [searching, setSearching] = useState(false);
  const [stepperCount, setStepperCount] = useState(2);
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  // const newUser = JSON.parse(sessionStorage.getItem('@userAuth'))?.user.newlyCreated;
  const newUser = sessionStorage.getItem('@newlyCreated');
  // console.log('state user:', useSelector((state) => state));
  useEffect(() => {
    document.body.setAttribute('data-layout', 'horizontal');
    const bodyColor = () => document.querySelector('body').setAttribute('style', `background-color: ${bgColor} !important`);
    bodyColor();
    if (!user?.id || newUser) {
      setStepperCount(2);
    } else {
      setStepperCount(1);
    }
  }, [user, bgColor, newUser]);

  return (
    <>
      <nav className="navbar-header" style={{ borderBottom: stepperCount === 1 ? '1px solid #dee2e6' : '' }}>
        <Container fluid className="d-flex justify-content-between ">
          <div style={{ flex: 1 }}>
            <div
              style={{
                pointerEvents: location.pathname.split('/')[1] === 'success'
            || location.pathname.split('/')[1] === 'activating-account'
            || location.pathname === '/reactivate-account'
            || location.pathname === '/user'
            || location.pathname === '/block-mobile'
            || location.pathname === '/first-steps' ? 'none' : 'auto',
              }}
            >
              <Link to="/home" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={stepperCount === 1 ? logoDark : logoWhite} alt="" height="35" />
                </span>
                <span className="logo-lg">
                  <img src={stepperCount === 1 ? logoDark : logoWhite} alt="" height="35" />
                </span>
              </Link>

              <Link to="/home" className="logo logo-light">
                <span className="logo-sm">
                  <img src={stepperCount === 1 ? logoDark : logoWhite} alt="" height="35" />
                </span>
                <span className="logo-lg">
                  <img src={stepperCount === 1 ? logoDark : logoWhite} alt="" height="35" />
                </span>
              </Link>
            </div>

            {/* <button
              type="button"
              className="btn btn-sm px-3 font-size-24 d-lg-none header-item waves-effect waves-light"
              // onClick={openLeftMenuCallBack}
              data-toggle="collapse"
              data-target="#topnav-menu-content"
            >
              <i className="mdi mdi-menu" />
            </button> */}
          </div>

          {{
            // Logged in
            1: (
              <>
                <div style={{ flex: 8 }}>
                  <NavbarSignin />
                </div>
                <div className="profileMenu" style={{ flex: 1 }}>
                  <ProfileMenu />
                </div>
              </>
            ),
            // Logged out
            2: (
              <>
                <div style={{ flex: 5 }}>
                  <Navbar isAccountCreated={isAccountCreated} />
                </div>
                <div />
              </>
            ),
          }[stepperCount]}

        </Container>
      </nav>
    </>
  );
};

// Topbar.propTypes = {
//   openLeftMenuCallBack: PropTypes.func.isRequired,
//   isMenuOpened: PropTypes.bool.isRequired,
// };
Topbar.defaultProps = {
  isAccountCreated: false,
};

Topbar.propTypes = {
  bgColor: PropTypes.string.isRequired,
  isAccountCreated: PropTypes.bool,
};

export default Topbar;
