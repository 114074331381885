import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getFaturamento, getStatus, getStripeSessionById } from '../../services/users';
import Loading from '../loading';

const VerificationUser = () => {
  const userEmail = useSelector(({ auth }) => auth.user.email);
  const userSession = useSelector(({ auth }) => auth.user.sessionID);

  async function getStripeSession() {
    await getStripeSessionById(userSession);
  }

  async function status() {
    await getStatus(userEmail);
  }

  async function faturamento() {
    try {
      const response = await getFaturamento(userEmail);
      const cancelPlan = response?.user.plan === 0;
      setTimeout(() => {
        // if (cancelPlan) history.push('/reactivate-account');
        if (!cancelPlan) window.location.replace('/categories');
        if (cancelPlan) window.location.replace('/reactivate-account');
      }, 800);
    } catch (error) {
      setTimeout(() => {
        window.location.replace('/reactivate-account');
      }, 800);
    }
  }

  useEffect(() => {
    getStripeSession();
    setTimeout(async () => {
      if (userEmail) {
        await status();
        await faturamento();
      }
    }, 500);
  }, [userEmail]);

  return (

    <Loading />

  );
};

export default VerificationUser;
