import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { createCheckout } from '../../../../services/users';

const Pay = ({ onBack }) => {
  const userEmail = useSelector(({ auth }) => auth.user.email);

  const handleClick = async () => {
    const response = await createCheckout('price_1L7jVfExpBzPZXyvfABfV2E7', userEmail);
    window.location = response;
  };
  const voltar = () => {
    onBack();
  };

  return (
    <>
      <div className={styles.title}>
        Confirme que você é um usuário real.
      </div>
      <p className={styles.p}>
        Para garantir que você é um vendedor de verdade, conclua seu pagamento.
        <br />
        Ao clicar em continuar, teste gratuitamente por um mês incluindo o cupom:
        {' '}
        <b>
          VENDEDORACELERADO
        </b>
      </p>
      <Row style={{ marginTop: '1rem' }}>

        <Col
          xs="auto"
          style={{
            display: 'flex', flexDirection: 'column', margin: '0 auto', width: '300px',
          }}
        >
          <section className={styles.selects}>
            <div className={styles.left}> Plano selecionado </div>
            <div
              className={styles.right}
              style={{
                width: '110px',
                height: '110px',
                borderRadius: '0.5rem',
                color: '#ffff',
                fontWeight: '700',
                backgroundColor: '#B3B3A1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0',
              }}
            >
              SILVER
            </div>
          </section>
          <section className={styles.selects}>
            <div className={styles.left}>Assinatura Mensal</div>
            <div className={styles.right}>
              R$ 69,90
              <br />
              usuário/mês
            </div>
          </section>
          <div />
          <div style={{
            width: '100%', height: '1px', backgroundColor: '#23364A', position: 'relative', marginTop: '0.5rem',
          }}
          />
          <section className={styles.selects}>
            <div className={styles.left}>
              Categorias
              <br />
              Monitoradas
            </div>
            <div className={styles.right}>1 Categoria</div>
          </section>
          {/* <div
            style={{
              width: '100%', height: '1px', backgroundColor: '#23364A', position: 'relative',
            }}
          /> */}
          {/* <section className={styles.selects}>
            <div className={styles.left}>Estudo de tendências</div>
            <div
              style={{ marginRight: '1.4rem', marginBottom: '0.5rem' }}
              className={styles.right}
            >
              <Silver style={{ width: '25px', height: '25px' }} />

            </div>
          </section> */}
          <div style={{
            width: '100%', height: '1px', backgroundColor: '#23364A', position: 'relative', marginRight: '1rem', marginTop: '0.5rem',
          }}
          />
          <div>
            <Button
              color="primary"
              size="lg"
              style={{
                height: '50px',
                width: '250px',
                margin: '1.5rem auto',
                fontSize: '1rem',
                fontWeight: '650',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

              }}
              onClick={handleClick}
            >
              Avançar &gt;
            </Button>

          </div>
          <button
            type="button"
            style={{
              background: 'none',
              border: 'none',
              color: '#949ca3',
            }}
            onClick={voltar}
          >
            &lt;
            <u> Voltar</u>

          </button>
        </Col>
      </Row>

    </>
  );
};
Pay.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default Pay;
