import reducer from './sales.reducer';

export {
  SalesTypes,
  getSales,
  getSalesPage,
  getSalesFilter,
} from './sales.action';

export default reducer;
