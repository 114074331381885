/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const AppRoute = ({

  component: Component,
  privateRoute = true,
  layout: Layout,
  title,
  bgColor,
  isAccountCreated,
  ...rest
}) => {
  // const hasAuth = true;
  const hasAuth = useSelector((state) => state.auth.hasAuth);

  return (
    <Route
      {...rest}
      render={(props) => (
        privateRoute && !hasAuth ? (
          // eslint-disable-next-line react/prop-types
          <Redirect to="/login" from={props.location.pathname} />
        ) : (
          <Layout title={title} bgColor={bgColor} isAccountCreated={isAccountCreated}>
            <Component {...props} />
          </Layout>
        )
      )}
    />
  );
};

AppRoute.propTypes = {
  component: PropTypes.func.isRequired,
  privateRoute: PropTypes.bool.isRequired,
  layout: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  isAccountCreated: PropTypes.bool,
};

AppRoute.defaultProps = {
  bgColor: '#f5f7fa',
  isAccountCreated: false,
};

export default AppRoute;
