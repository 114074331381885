import { toastr } from 'react-redux-toastr';
import Api from '../../services/api';

export const CategoriesTypes = {
  FETCH: '@categories/fetch',
  ERROR: '@categories/error',
  GET_RECEIVE: '@categories/get/receive',
  ADD_RECEIVE: '@categories/add/receive',

  PAGE_FETCH: '@categories/page/fetch',
  PAGE_ERROR: '@categories/page/error',
  PAGE_RECEIVE: '@categories/page/receive',

  TRACKING_RECEIVE: '@categories/tracking/receive',

  TREE_RECEIVE: '@categories/tree/receive',
  NODE_RECEIVE: '@categories/node/receive',

  FILTERS_FETCH: '@categories/filters/fetch',
  FILTERS_ERROR: '@categories/filters/error',
  FILTERS_RECEIVE: '@categories/filters/receive',

  NEWPLAN: '@categories/newplan/fetch',
  PLANUPTADE: '@categories/planUptade/fetch',

  CATEGORYONBOARD: '@categories/categoryOnBoarding/fetch',
};

const toastrOptions = {
  onToastrClick: () => window.location.replace('https://app.mercadoacelerado.com.br/select-change-category'),
};

export const getCategories = () => (dispatch) => {
  dispatch({ type: CategoriesTypes.FETCH });

  Api.get('/categories?limit=all')
    .then((resp) => {
      const payload = resp.data;
      dispatch({ type: CategoriesTypes.GET_RECEIVE, payload });
    }).catch((err) => {
      toastr.error('Falha ao carregar categories.');
      const payload = err.response.data;
      dispatch({ type: CategoriesTypes.ERROR, payload });
    });
};

export const getCategoriesTree = () => (dispatch) => {
  dispatch({ type: CategoriesTypes.FETCH });

  Api.get('/categories/tree')
    .then((resp) => {
      const payload = resp.data;
      dispatch({ type: CategoriesTypes.TREE_RECEIVE, payload });
    }).catch((err) => {
      toastr.error('Falha ao carregar categories.');
      const payload = err.response.data;
      dispatch({ type: CategoriesTypes.ERROR, payload });
    });
};

export const getCategoryNode = ({ categoryId }) => (dispatch) => {
  Api.get(`/categories/tree/${categoryId}`)
    .then((resp) => {
      const payload = { data: resp.data };

      dispatch({ type: CategoriesTypes.NODE_RECEIVE, payload });
    }).catch((err) => {
      const payload = err?.response?.data;
      if (!payload) return;
      toastr.error('Falha ao carregar categories.');
      dispatch({ type: CategoriesTypes.ERROR, payload });
    });
};

export const putTrackingCategoryNode = ({
  categoryId,
  tracking,
}) => (dispatch) => {
  Api.put(`/categories/tree/tracking/${categoryId}`, { tracking })
    .then((resp) => {
      const payload = { data: resp.data };

      dispatch({ type: CategoriesTypes.NODE_RECEIVE, payload });
    }).catch((err) => {
      const payload = err?.response?.data;
      if (!payload) return;
      toastr.error('Falha ao carregar categories.');
      dispatch({ type: CategoriesTypes.ERROR, payload });
    });
};

export const getCategoriesFilter = (queries = []) => (dispatch) => {
  dispatch({ type: CategoriesTypes.FILTERS_FETCH });

  const params = queries
    .map((query) => `${query.key}=${query.value}`)
    .filter((q) => q)
    .join('&');

  Api.get(`/categories/filters?${params}`)
    .then((resp) => {
      const payload = resp.data;
      dispatch({ type: CategoriesTypes.FILTERS_RECEIVE, payload });
    }).catch((err) => {
      toastr.error('Você não possui monitoramentos ativos. Clique aqui para ativar.', toastrOptions);
      const payload = err.response.data;
      dispatch({ type: CategoriesTypes.FILTERS_ERROR, payload });
    });
};

export const getCategoriesPage = ({
  page = 1,
  limit = 30,
  search = '',
  querys = [],
  order = null,
  orderby = null,
  userIdRedux,
} = {}) => (dispatch) => {
  function containsCategoryByLevel(query, level) {
    if (!query) return false;

    return query.key.indexOf(level) !== -1;
  }

  // find category level filters
  if (querys.some((query) => containsCategoryByLevel(query, 'category'))) {
    dispatch(getCategoriesFilter(querys.filter((query) => containsCategoryByLevel(query, 'category'))));
  }

  dispatch({ type: CategoriesTypes.PAGE_FETCH });

  const query = [
    `limit=${limit}`,
    `page=${page}`,
    order && `order=${order}`,
    orderby && `orderby=${orderby}`,
    querys && querys.map((q) => `${q.key}=${q.value}`).join('&'),
    search && `search=${search}`,
    userIdRedux && `userIdRedux=${userIdRedux}`,
  ]
    .filter((q) => q)
    .join('&');

  Api.get(`/categories?${query}`)
    .then((resp) => {
      const payload = resp.data;
      dispatch({ type: CategoriesTypes.PAGE_RECEIVE, payload });
    }).catch((err) => {
      toastr.error('Falha ao carregar categories.');
      const payload = err.response?.data;
      dispatch({ type: CategoriesTypes.PAGE_ERROR, payload });
    });
};

export const putTrackingCategory = ({ id, tracking } = {}) => (dispatch) => {
  Api.put(`/categories/tracking/${id}`, { tracking })
    .then((resp) => {
      const payload = resp.data;
      dispatch({ type: CategoriesTypes.TRACKING_RECEIVE, payload });
    }).catch(() => {
      toastr.error('Falha ao alterar monitoramento.');
      const payload = { category: { id, tracking: tracking ? 0 : 1 } };
      dispatch({ type: CategoriesTypes.TRACKING_RECEIVE, payload });
    });
};

export const plansCategories = (id) => (dispatch) => {
  dispatch({ type: CategoriesTypes.NEWPLAN, payload: id });
};

export const planIsUptade = (plan) => (dispatch) => {
  dispatch({ type: CategoriesTypes.PLANUPTADE, payload: plan });
};

export const isFetchingCategoryOnboarding = (fetch) => (dispatch) => {
  dispatch({ type: CategoriesTypes.CATEGORYONBOARD, payload: fetch });
};
