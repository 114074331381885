/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable import/prefer-default-export */

import Api from './api';

export function getAllUsers() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = Api.get('users');
      return resolve(...(await response).data);
    } catch (error) {
      reject(error);
    }
  });
}

export function postUsers(form) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = Api.post('users', form);
      return resolve((await response).data);
    } catch (error) {
      reject(error);
    }
  });
}

export function changePasswordLogged({ userId, newPassword, password }) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = Api.post('users/changePassword', { userId, password, newPassword });
      return resolve((await response).data);
    } catch (error) {
      reject(error);
    }
  });
}

export function changeUserData({
  userId, name, sobrenome, cellPhone, company, email, senhaAtual,
}) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = Api.post('users/updateUser', {
        userId, name, sobrenome, cellPhone, company, email, senhaAtual,
      });

      return resolve((await response).data);
    } catch (error) {
      reject(error.response.data.msg);
    }
  });
}

export function planOfUser(userId) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api.get(`users/userPlan/${userId}`);
      return resolve(response.data);
    } catch (error) {
      reject(error.response.data.msg);
    }
  });
}

export function futurePlanOfUser(planId) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api.get(`users/userPlanFuture/${planId}`);
      return resolve(response.data);
    } catch (error) {
      reject(error.response.data.msg);
    }
  });
}

export function approvedPlanOfUser(planId, userId) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api.post('users/paymentStatus', { planId, userId });
      return resolve(response.data);
    } catch (error) {
      reject(error.response.data.msg);
    }
  });
}
export function rejectPlanOfUser(userId) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api.post('/paymentStatusReject', { userId });
      return resolve(response.data);
    } catch (error) {
      reject(error.response.data.msg);
    }
  });
}

export function trackedUserCategories(userId) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api.get(`users/userTrackedCategories/${userId}`);
      return resolve(response.data);
    } catch (error) {
      reject(error.response.data.msg);
    }
  });
}

export function createCheckout(price, email) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api.post('stripe/createCheckout', { price, email });
      // console.log(response);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
}

export function reactiveCreateCheckout(price, email) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api.post('stripe/reactiveCreateCheckout', { price, email });
      // console.log(response);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
}

export function switchCategoriesTracked(userId, id, keyword) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api.post('users/switchCategoriesTracked', {
        userId, id, keyword,
      });
      return resolve(response.data);
    } catch (error) {
      reject(error.response.data.msg);
    }
  });
}
export function resetCategories(userId) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api.post('users/resetCategories', { userId });
      return resolve(response.data);
    } catch (error) {
      reject(error.response.data.msg);
    }
  });
}

// eslint-disable-next-line camelcase
export function portal(email, userSession) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api.post('stripe/create-portal-session', { email, userSession });
      return resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function webhook() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api.post('/webhook');
      return resolve(response.data);
    } catch (error) {
      reject(error.response.data.msg);
    }
  });
}

export function getStripeSessionById(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api.get(`stripe/session/${id}`);
      // console.log(response);
      return resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getFaturamento(userEmail) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Api.get(`stripe/faturamento/${userEmail}`);
      return resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getStatus(userEmail) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Api.get(`stripe/status/${userEmail}`);
      return resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
}
