import Api from './api';

export default async function getDashboard(categoryId) {
  try {
    const { data } = await Api.get(`dashboard/${categoryId}`);
    return data;
  } catch (error) {
    return {};
  }
}
