import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Card, CardBody } from 'reactstrap';
import { ReactComponent as Reactive } from '../../assets/images/Reactive.svg';
import { mobileWarning } from '../../services/auth';
import Progress from '../../components/progress';

const BlockMobile = () => {
  const [successEmail, setSuccessEmail] = useState(false);
  const [progressState, setProgressState] = useState(false);

  const email = useSelector(({ auth }) => auth.user.email);

  const mobileWarningEmail = async () => {
    if (email) {
      setProgressState(true);
      const response = await mobileWarning(email);
      toast.success(response.msg);
      setSuccessEmail(true);
      setProgressState(false);
    }
  };

  return (
    <>
      <Card>
        <CardBody style={{ textAlign: 'center' }}>
          <h1 style={{
            fontWeight: '650', color: '#567FAA', fontSize: '1.8rem', marginTop: '1rem',
          }}
          >
            A nossa Plataforma foi criada para uso no computador.
          </h1>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Reactive style={{ width: '300px', margin: '2.5rem auto' }} />
          </div>

          <p style={{ fontSize: 'min(4.7vw, 3.2125rem)', color: '#23364A' }}>
            Abra em seu navegador de preferência e saiba como
            os dados podem te ajudar a tomar as melhores decisões

          </p>
          <div className="d-flex align-items-center justify-content-center py-3 mb-0">
            {progressState
              ? (
                <Progress />
              )
              : (
                <Button
                  color="primary"
                  size="lg"
                  style={{ height: '55px', width: '270px', display: successEmail ? 'none' : '' }}
                  className="w-md font-weight-bold waves-effect waves-light mt-2"
                  onClick={mobileWarningEmail}
                >
                  Enviar link no e-mail

                </Button>
              )}

          </div>
          {successEmail
            ? (
              <>
                <p>E-mail enviado com sucesso</p>
              </>
            )
            : (
              ''
            )}
        </CardBody>
      </Card>
    </>

  );
};

export default BlockMobile;
