/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col, Row, Card, CardBody, Button,
} from 'reactstrap';
import Media from 'react-media';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { useHistory } from 'react-router-dom';
import ModalComponent from '../../components/modal/ModalComponent';
import { getSalesPage, getSalesFilter } from '../../store/sales';
import Table from '../../components/table';
import TextSearch from '../../components/textSearch';
import SelectSearch from '../../components/selectSearch';
import ExportTable from '../../components/exportTable';
import useTableData from '../../hooks/useTableData';
import './sales.css';
import { isFetchingCategoryOnboarding } from '../../store/categories/categories.action';
import { ReactComponent as Raio } from '../../assets/images/Raio.svg';
import 'react-accessible-accordion/dist/fancy-example.css';
import { getStatus } from '../../services/users';

const SellerData = ({ item }) => {
  if (!item.seller) return <td />;
  return (
    <td>
      {decodeURIComponent(
        item.seller
          .replace('http://perfil.mercadolivre.com.br/', '')
          .replace('+', ' ')
          .replaceAll('_', ' '),
      )}
    </td>
  );
};

SellerData.defaultProps = {
  item: { seller: '' },
};

SellerData.propTypes = {
  item: PropTypes.shape({
    seller: PropTypes.string,
  }),
};

const LinkData = ({ item }) => (
  <td>
    <a
      href={`${item.permalink}`}
      style={{ color: '#384E5E', fontWeight: '700' }}
      target="_blank"
      rel="noopener noreferrer"
    >
      Ver
    </a>
  </td>
);

LinkData.propTypes = {
  item: PropTypes.shape({
    permalink: PropTypes.string.isRequired,
  }).isRequired,
};

const Conversion = ({ item }) => (
  <td>{item.buybox ? 'N/A' : `${Number(item.conversion * 100).toFixed(2)}%`}</td>
);

Conversion.defaultProps = {
  item: { conversion: '', buybox: false },
};

Conversion.propTypes = {
  item: PropTypes.shape({
    conversion: PropTypes.number,
    buybox: PropTypes.bool,
  }),
};
const ComparativeConversion = ({ item }) => {
  const verificationForItemBuybox = !!(
    item.toastifyWarning
    || item.comparative_conversion
    || item.comparative_conversion === 0
  );
  const currentConversion = (item.conversion * 100).toFixed(2);

  if (item.buybox && verificationForItemBuybox) {
    return <td>N/A</td>;
  }

  if (!item.buybox && Number(item.comparative_conversion) > Number(currentConversion)) {
    return (
      <td style={{ color: 'green' }}>
        {`${item.comparative_conversion}%`}
      </td>
    );
  }
  if (!item.buybox && Number(item.comparative_conversion) < Number(currentConversion)) {
    return (
      <td style={{ color: 'red' }}>
        {`${item.comparative_conversion}%`}
      </td>
    );
  }
  return <td>-</td>;
};

ComparativeConversion.defaultProps = {
  item: {
    conversion: '', buybox: false, comparative_conversion: null, toastifyWarning: false,
  },
};

ComparativeConversion.propTypes = {
  item: PropTypes.shape({
    conversion: PropTypes.number,
    buybox: PropTypes.bool,
    comparative_conversion: PropTypes.number,
    toastifyWarning: PropTypes.string,
  }),
};

const ThumbData = ({ item }) => (
  <td>
    <img
      src={item.thumbnail}
      alt={`${item.id}-thumb`}
      className="sales-image"
    />
  </td>
);

ThumbData.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
  }).isRequired,
};

const Price = ({ item, column }) => {
  const formated = useMemo(() => {
    const raw = item[column.id];
    const locale = Number(raw).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return locale;
  }, [item, column.id]);

  return <td>{formated}</td>;
};

Price.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
  }).isRequired,
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

const Visits = ({ item }) => {
  if (item.visits === 0) {
    return <td> 1 </td>;
  }
  if (item.visits) {
    return <td>{item.visits.toLocaleString('pt-BR')}</td>;
  }
  return (
    <td>{item.buybox ? '-' : item.visits}</td>
  );
};

Visits.defaultProps = {
  item: { buybox: false, visits: 0 },
};

Visits.propTypes = {
  item: PropTypes.shape({
    buybox: PropTypes.bool,
    visits: PropTypes.number,
  }),
};

const Buybox = ({ item }) => (
  <td>{item.buybox ? <p style={{ color: '#F28500' }}>SIM</p> : 'NÃO'}</td>
);

Buybox.defaultProps = {
  item: { buybox: false },
};

Buybox.propTypes = {
  item: PropTypes.shape({
    buybox: PropTypes.bool,
  }),
};

const GMV = ({ item, column }) => {
  const formated = useMemo(() => {
    const raw = item[column.id];
    const locale = Number(raw).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return locale;
  }, [item, column.id]);

  return <td>{item.buybox ? 'N/A' : formated}</td>;
};

GMV.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    GMV: PropTypes.string.isRequired,
    buybox: PropTypes.bool,
  }).isRequired,
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

const CreateAt = ({ item }) => (
  <td>
    {item.created_at
      && item.created_at.split('T')[0].split('-').reverse().join('/')}
  </td>
);

CreateAt.defaultProps = {
  item: { created_at: '' },
};

CreateAt.propTypes = {
  item: PropTypes.shape({
    created_at: PropTypes.string,
  }),
};

const ComparativeSells = ({ item }) => {
  if (item.comparative_sells > 0) {
    return (
      <td style={{ color: 'green' }}>
        +
        {item.comparative_sells.toLocaleString('pt-BR')}
      </td>
    );
  }
  return <td>-</td>;
};

ComparativeSells.defaultProps = {
  item: { comparative_sells: null },
};

ComparativeSells.propTypes = {
  item: PropTypes.shape({
    comparative_sells: PropTypes.number,
    buybox: PropTypes.bool,
  }),
};

const Free = ({ item }) => (
  <td>{item.free_shipping === '0' ? 'NÃO' : <p style={{ color: '#00a650' }}>SIM</p>}</td>
);

Free.defaultProps = {
  item: { free_shipping: false },
};

Free.propTypes = {
  item: PropTypes.shape({
    free_shipping: PropTypes.bool,
  }),
};

const ListingType = ({ item }) => (
  <td>{item.listing_type_id === 'gold_pro' ? 'Premium' : 'Clássico'}</td>
);

ListingType.defaultProps = {
  item: { listing_type_id: false },
};

ListingType.propTypes = {
  item: PropTypes.shape({
    listing_type_id: PropTypes.bool,
  }),
};

const ComparativeGMV = ({ item }) => {
  const verificationForItemBuybox = !!(
    item.toastifyWarning
    || item.comparative_gmv
    || item.comparative_gmv === 0
  );
  const locale = Number(item.comparative_gmv).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  if (item.buybox && verificationForItemBuybox) {
    return <td>N/A</td>;
  }
  if (!item.buybox && item.comparative_gmv > 0) {
    return (
      <td style={{ color: 'green' }}>
        +
        {' '}
        {locale}
      </td>
    );
  }
  return <td>-</td>;
};

ComparativeGMV.defaultProps = {
  item: {
    comparative_gmv: null, gmv: null, buybox: true, toastifyWarning: false,
  },
};

ComparativeGMV.propTypes = {
  item: PropTypes.shape({
    comparative_gmv: PropTypes.number,
    gmv: PropTypes.number,
    buybox: PropTypes.bool,
    title: PropTypes.string,
    toastifyWarning: PropTypes.string,
  }),
};

const ComparativeVisits = ({ item }) => {
  if (item.comparative_visits > 0) {
    return (
      <td style={{ color: 'green' }}>
        {`+${item.comparative_visits.toLocaleString('pt-BR')}`}
      </td>
    );
  }
  return <td>-</td>;
};

ComparativeVisits.defaultProps = {
  item: { comparative_visits: null, buybox: false },
};

ComparativeVisits.propTypes = {
  item: PropTypes.shape({
    comparative_visits: PropTypes.number,
    buybox: PropTypes.bool,
  }),
};

const StockAvailable = ({ item }) => {
  const { real_available_quantity: stockAvailable } = item;
  if (stockAvailable === 0) {
    return <td> 1 </td>;
  }
  if (stockAvailable) {
    return <td>{stockAvailable.toLocaleString('pt-BR') || '-'}</td>;
  }
  return <td> - </td>;
};

StockAvailable.defaultProps = {
  item: { real_available_quantity: 1 },
};

StockAvailable.propTypes = {
  item: PropTypes.shape({
    real_available_quantity: PropTypes.number,
  }),
};

const Sells = ({ item }) => {
  if (item.real_sold_quantity) {
    const formatedSell = item.real_sold_quantity.toLocaleString('pt-BR');
    return (
      <td>
        {formatedSell}
      </td>
    );
  }
  return <td> - </td>;
};

Sells.defaultProps = {
  item: { real_sold_quantity: 0 },
};

Sells.propTypes = {
  item: PropTypes.shape({
    real_sold_quantity: PropTypes.number.isRequired,
  }),
};

const FormatedId = ({ item }) => {
  const formatedId = item.id.split('-')[0];
  return (
    <td>
      {' '}
      {formatedId}
      {' '}
    </td>
  );
};

FormatedId.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

const columns = [
  { id: 'itemRelevance', title: 'POSIÇÃO NAS BUSCAS' },
  { id: 'thumbnail', title: 'IMAGEM', Component: ThumbData },
  { id: 'title', title: 'TÍTULO', style: { minWidth: '500px' } },
  { id: 'id', title: 'ID DO ANÚNCIO', Component: FormatedId },
  { id: 'brand', title: 'MARCA' },
  { id: 'seller', title: 'VENDEDOR', Component: SellerData },
  { id: 'buybox', title: 'CATÁLOGO', Component: Buybox },
  {
    id: 'price',
    title: 'PREÇO ATUAL',
    Component: Price,
    style: { minWidth: '300px' },
  },
  { id: 'listing_type_id', title: 'TIPO DE ANÚNCIO', Component: ListingType },
  { id: 'logistic_type', title: 'TIPO DE ENVIO' },
  { id: 'free_shipping', title: 'FRETE GRÁTIS', Component: Free },

  { id: 'real_sold_quantity', title: 'VENDAS TOTAIS', Component: Sells },
  {
    id: 'comparative_sells',
    title: 'VENDAS PERÍODO',
    Component: ComparativeSells,
    style: { minWidth: '180px' },
  },
  { id: 'real_available_quantity', title: 'ESTOQUE DISPONÍVEL', Component: StockAvailable },
  { id: 'gmv', title: 'FATURAMENTO TOTAL', Component: GMV },
  {
    id: 'comparative_gmv',
    title: 'FATURAMENTO PERÍODO',
    Component: ComparativeGMV,
    style: { minWidth: '250px' },
  },
  { id: 'visits', title: 'VISITAS TOTAIS', Component: Visits },
  {
    id: 'comparative_visits',
    title: 'VISITAS PERÍODO',
    Component: ComparativeVisits,
  },
  { id: 'conversion', title: 'CONVERSÃO', Component: Conversion },
  {
    id: 'conversionSell',
    title: 'CONVERSÃO PERÍODO',
    Component: ComparativeConversion,
  },
  {
    id: 'created_at',
    title: 'CRIADO EM',
    Component: CreateAt,
  },
  { id: 'permalink', title: 'LINK', Component: LinkData },
];

const Sales = () => {
  const dispatch = useDispatch();
  const [targetFilter, setTargetFilter] = useState('');
  const [targetKeyForFilter, setTargetKeyForFilter] = useState();
  const [informativeModal, setInformativeModal] = useState(false);
  const [responseStatus, setResponseStatus] = useState();
  const [responsePayment, setResponsePayment] = useState();
  const userEmail = useSelector(({ auth }) => auth.user.email);
  const { filters } = useSelector(({ sales }) => sales);
  const {
    isFetchingCategoryOnboarding: onboardCategoryFetch,
  } = useSelector(({ categories }) => categories);
  const { id: userIdRedux, plan: userPlan } = useSelector(({ auth }) => auth.user);

  async function status() {
    const response = await getStatus(userEmail);
    setResponseStatus(response.status);
  }

  useEffect(() => {
    if (userPlan === null) {
      window.location.replace('/first-steps');
    }
    if (userPlan === 0) {
      window.location.replace('reactivate-account');
    }
    setTimeout(() => {
      status();
    }, 400);
    setTimeout(() => {
      if (responseStatus === 'past_due') setResponsePayment(true);
    }, 1000);
  }, []);

  const {
    rows,
    isFetching,
    paging,
    ordering,
    searchInput,
    filtered,
    setSearch,
    onChangeSearch,
    onKeyDown,
    onCancelSearch,
    onChangeFilter,
    onChangeOrder,
    onPageChange,
  } = useTableData({ reducer: 'sales', action: getSalesPage });

  useEffect(() => {
    dispatch(getSalesFilter({
      id: userIdRedux,
      valueOfFilter: targetFilter,
      keyOfFilter: targetKeyForFilter,
    }));
  }, [rows]);

  const history = useHistory();

  return (
    <Row className="w-100">
      <Media query={{ minWidth: '780px' }}>
        {(matches) => (matches ? (
          <Col xs="12">
            <h3 style={{ color: '#567FAA', fontWeight: 'bold' }}>
              Os anúncios mais relevantes da categoria
            </h3>
            <div style={{ display: 'flex' }}>
              <Card xs="7" className="mr-1" style={{ flex: 4 }}>
                <CardBody
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    background: '#567faa',
                    padding: '0.7rem 0',
                  }}
                >
                  <SelectSearch
                    filtered={filtered}
                    filters={filters}
                    onChangeFilter={onChangeFilter}
                    setStateForBrandFilter={setTargetFilter}
                    setStateForBrandKeyFilter={setTargetKeyForFilter}
                    type="sales"
                  />
                  <TextSearch
                    label="Busca"
                    searchInput={searchInput}
                    setSearch={setSearch}
                    onKeyDown={onKeyDown}
                    onChangeSearch={onChangeSearch}
                    onCancelSearch={onCancelSearch}
                  />
                </CardBody>
              </Card>
              <Card xs="5" style={{ flex: 1 }}>
                <CardBody
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#567FAA',
                    padding: '0.7rem 0',
                  }}
                >
                  <ExportTable
                    user={userIdRedux}
                    filtered={filtered}
                    limitForQuery={paging.totalItems}
                    searchInput={searchInput}
                  />
                </CardBody>
              </Card>
              <Card sm="5" style={{ flex: 0.5 }}>
                <Button
                  className="search-btn"
                  onClick={() => setInformativeModal(true)}
                  style={{
                    height: '5.7em', width: '100%', borderRadius: '0.5rem', marginLeft: '0.25rem',
                  }}
                >
                  <Raio style={{ height: '45%' }} />
                  {' '}
                  <br />
                  <strong style={{ color: '#A4E5F9', fontSize: 'min(0.8vw, 0.8125rem)' }}>Glossário</strong>
                  {' '}
                  <br />
                  <div style={{ fontSize: 'min(0.6vw, 0.7125rem)' }}>Mercado Acelerado</div>
                </Button>
              </Card>
            </div>
            <Card>
              <CardBody>
                <Table
                  showPaging
                  columns={columns}
                  rows={rows}
                  isFetching={isFetching}
                  paging={paging}
                  onPageChange={onPageChange}
                  onChangeOrder={onChangeOrder}
                  ordering={ordering}
                />
              </CardBody>
            </Card>
          </Col>
        ) : (
          <Col xs="12">
            <Card>
              <CardBody>
                <TextSearch
                  label="Pesquisa"
                  searchInput={searchInput}
                  setSearch={setSearch}
                  onKeyDown={onKeyDown}
                  onChangeSearch={onChangeSearch}
                  onCancelSearch={onCancelSearch}
                />

                <SelectSearch
                  filtered={filtered}
                  filters={filters}
                  onChangeFilter={onChangeFilter}
                />

                <ExportTable
                  user={userIdRedux}
                  filtered={filtered}
                  limitForQuery={paging.totalItems}
                />

                <Table
                  showPaging
                  columns={columns}
                  rows={rows}
                  isFetching={isFetching}
                  paging={paging}
                  onPageChange={onPageChange}
                  onChangeOrder={onChangeOrder}
                  ordering={ordering}
                />
              </CardBody>
            </Card>
          </Col>
        ))}
      </Media>
      {
          onboardCategoryFetch && (
            <SweetAlert
              info
              title="Seus dados estão sendo carregados."
              confirmBtnText="Entendi!"
              confirmBtnBsStyle="primary"
              confirmBtnStyle={{ border: 'none' }}
              focusConfirmBtn={false}
              onConfirm={() => dispatch(isFetchingCategoryOnboarding(false))}
            >
              Aguarde alguns instantes, isso demora em média 5 minutos
            </SweetAlert>
          )
        }

      {
      responsePayment && (
        <ModalComponent
          notShowHeader
          ModalActive={() => setResponsePayment(true)}
          width="55%"
          height="55%"
        >
          <div className="mainChangePlan">
            <h2 className="h2ChangePlan">
              Seu Pagamento falhou
            </h2>
            <div className="divPChangePlan">
              <p className="pChangePlan">
                Por favor, revise sua forma de pagamento para voltar a usar o Dashboard do Mercado Acelerado e não perder informações relevantes para o seu negócio.
              </p>

            </div>
            <Button
              className="btnChangePlan"
              color="primary"
              size="lg"
              type="submit"
              block
              onClick={() => history.push('/portal-stripe')}
            >
              Revisar
            </Button>
          </div>
        </ModalComponent>
      )
}
      {informativeModal && (
        <ModalComponent
          notShowHeader
          ModalActive={() => setInformativeModal(false)}
          width="50%"
          height="90%"
          padding="0.8rem"
          heightContainer="100%"
          flexDirectionContainer="column"
        >
          <div style={{
            width: '95%', height: '100%', backgroundColor: 'white', borderRadius: '0.5rem', overflowY: 'auto',
          }}
          >
            <p style={{ textAlign: 'justify', margin: '1rem 2rem', fontFamily: 'Roboto' }}>
              <h3>
                <Raio style={{ width: '2rem' }} />
                <strong style={{ color: '#567FAA' }}>GLOSSÁRIO MERCADO ACELERADO</strong>
              </h3>

              <Accordion style={{ border: 'none' }}>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>Categoria</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>

                    Aqui você poderá filtrar as categorias monitoradas ou analisar todas juntas. Para monitorar mais categorias é muito fácil, é só clicar em “Configurações” e em “Clique aqui para monitorar mais categorias”. Caso você tenha categorias disponíveis, será direcionado automaticamente para o fluxo de seleção. Caso contrário, você poderá fazer o upgrade da sua conta. Use os dados para as melhores decisões de negócio!

                  </AccordionItemPanel>
                </AccordionItem>
                <br />

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>Marcas</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>

                    Ao utilizar esse filtro você poderá pesquisar por marcas específicas entre os anúncios mais relevantes da categoria.

                  </AccordionItemPanel>
                </AccordionItem>

                <br />
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>Período Comparativo</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>

                    Para uma melhor análise da categoria, possibilitamos você avaliar os resultados históricos e nos últimos 7, 15 e 30 dias. Para ter a visão destes períodos comparativos, é importante começar a monitorar a categoria e com o passar das semanas estes valores serão apresentados na relação dos anúncios.

                  </AccordionItemPanel>
                </AccordionItem>

                <br />
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>Busca (barra de busca)</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    Disponibilizamos esta barra de busca para você encontrar o que procura com mais facilidade. Busque por título, palavra-chave, nome do vendedor ou marca. É só incluir o termo a ser buscado e pressionar o enter de seu teclado. Para remover a busca, clique no “limpar” ao lado.
                  </AccordionItemPanel>
                </AccordionItem>
                <br />

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>Exportar dados da tabela</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    Caso deseje baixar os resultados das categorias monitoradas, basta clicar em Exportar dados da tabela. Ao clicar, todos os resultados da sua busca serão exportados para um arquivo em Excel (xlsx) e o download será disponibilizado.
                  </AccordionItemPanel>
                </AccordionItem>
                <br />

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>Posição nas buscas</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    Esta é a posição que o anúncio ocupa entre os principais anúncios da categoria. Quanto mais relevante, melhor o posicionamento e maiores as chances de vender. Busque sempre melhorar a posição dos seus anúncios, trabalhando suas condições e atributos.
                  </AccordionItemPanel>
                </AccordionItem>

                <br />

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>Imagem</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    Aqui mostramos a primeira foto do anúncio, para vê-la em alta resolução clique em “Ver” ao final desta linha. O importante é prezar por fotos de qualidade, alta resolução (1200px X 1200px), fundo branco, sem marca d’agua, molduras ou textos e que mostre só o produto. Isso conta muito para a relevância do seu anúncio.
                  </AccordionItemPanel>
                </AccordionItem>
                <br />

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>Marca</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    Esta é a marca do produto vendido. Tem muito vendedor que não preenche 100% a ficha técnica do seu anúncio. Garanta todos os atributos preenchidos, assim como a marca, para ter mais relevância e visibilidade perante a concorrência.
                  </AccordionItemPanel>
                </AccordionItem>
                <br />

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>Vendedor</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    Este é o vendedor responsável pelo anúncio. Nos casos de anúncio catálogo (identificados na coluna ao lado), o vendedor será o ganhador atual no catálogo do produto ofertado.
                  </AccordionItemPanel>
                </AccordionItem>
                <br />

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>Catálogo</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    O catálogo é uma nova forma de vender. Cada produto que está no nosso catálogo tem apenas um anúncio que aparece no início dos resultados de busca, para que os compradores possam encontrar o que procuram mais rápido.

                    <br />
                    <br />
                    Todos os vendedores que oferecem o mesmo produto competem entre si para ser o vendedor de destaque do produto. Participar é grátis e se oferecer as melhores condições de venda, preço e experiência, você ganha.
                  </AccordionItemPanel>
                </AccordionItem>
                <br />

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>ID do Anúncio</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    Este é o código único de identificação do anúncio. Cada anúncio tem o seu, como se fosse um CPF. A identificação sempre começa com a sigla MLB (Mercado Livre Brasil) seguido por um número único.
                  </AccordionItemPanel>
                </AccordionItem>
                <br />

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>Preço atual</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    É o preço atual do anúncio na semana. Ele pode mudar de uma semana para outra. Fique de olho nos seus concorrentes e veja se seu preço está adequado ao mercado. No caso do catálogo, será apresentado o preço do ganhador na modalidade.
                  </AccordionItemPanel>
                </AccordionItem>
                <br />

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>Vendas totais</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    Aqui apresentamos a quantidade de vendas totais que este anúncio teve historicamente desde a sua criação. Nos casos do catálogo, as vendas totais correspondem ao catálogo como um todo e não ao vendedor ganhador.
                  </AccordionItemPanel>
                </AccordionItem>
                <br />

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>Vendas período</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    Estas são as vendas que este anúncio teve no período comparativo selecionado. Podemos avaliar os últimos 7, 15 e 30 dias. Para ter a visão destes períodos, é importante começar a monitorar a categoria e com o passar das semanas estes valores serão apresentados. Focamos nas vendas do mês atual para uma melhor análise, pois se olhamos só o passado, podem ter casos de anúncios que venderam muito há anos, mas hoje não vendem nada. Um produto de sucesso vende toda semana.
                  </AccordionItemPanel>
                </AccordionItem>
                <br />

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>Estoque disponível</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    Esta é a quantidade de estoque que o anúncio possui. Para os casos de catálogo, o estoque apresentado será o do ganhador atual do catálogo. Lembre-se: profundidade de estoque conta pontos para sua relevância.
                  </AccordionItemPanel>
                </AccordionItem>
                <br />

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>Faturamento total</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    Nesta coluna apresentamos o faturamento total deste anúncio, ou seja, quanto faturou o anúncio desde a sua criação. Para os anúncios em catálogo os dados não serão apresentados e aparecerão como N/A. Isto se deve a variação dos ganhadores semana a semana. Mas não se preocupe, com as Vendas Totais você já enxerga se o produto tem tração nas vendas.
                  </AccordionItemPanel>
                </AccordionItem>
                <br />

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>Faturamento período</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    Nesta coluna apresentamos o faturamento deste anúncio no período comparativo selecionado, ou seja, quanto faturou o anúncio nos últimos 7, 15 e 30 dias. Para os anúncios em catálogo os dados não serão apresentados e aparecerão como N/A. Isto se deve a variação dos ganhadores semana a semana. Mas não se preocupe, com as Vendas Período você já enxerga se o produto tem tração nas vendas.
                  </AccordionItemPanel>
                </AccordionItem>
                <br />

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>Visitas totais</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    Esta é a quantidade de visitas totais que este anúncio recebeu desde a sua criação. Em anúncios catálogo, esta é a quantidade de visitas que o vencedor recebeu desde que passou a competir na modalidade.
                  </AccordionItemPanel>
                </AccordionItem>
                <br />

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>Visitas período</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    Esta é a quantidade de visitas totais que este anúncio recebeu nos últimos 7, 15 e 30 dias. Em anúncios catálogo, esta é a quantidade de visitas que o ganhador recebeu conforme o período comparativo selecionado.
                  </AccordionItemPanel>
                </AccordionItem>
                <br />

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>Conversão</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    Nesta coluna apresentamos uma das métricas mais importantes no universo das vendas online: a conversão. Esta é uma visão histórica e para calcular a métrica dividimos as vendas totais pelas visitas totais. Quanto maior o número, maior a conversão e relevância deste anúncio. Anúncios com boas conversões e volumes de vendas consideráveis têm maior exposição. Para os anúncios em catálogo os dados não serão apresentados e aparecerão como N/A.
                  </AccordionItemPanel>
                </AccordionItem>
                <br />

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>Conversão período</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    Assim como a conversão histórica do anúncio, nesta coluna apresentamos uma das métricas mais importantes no universo das vendas online: a conversão no período comparativo (7, 15 e 30 dias). Para calcular dividimos as vendas do período comparativo selecionado pelas visitas do período comparativo selecionado, quanto maior o número maior a conversão e relevância deste anúncio. Fique de olho e acompanhe o quanto uma melhoria no anúncio impacta a sua conversão. Para os anúncios em catálogo os dados não serão apresentados e aparecerão como N/A.
                  </AccordionItemPanel>
                </AccordionItem>
                <br />

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>Criado em</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    Esta é a data de criação do anúncio. É muito importante entendermos desde quando este anúncio está no ar e se as vendas foram em um passado distante ou estão acontecendo nos últimos dias.
                  </AccordionItemPanel>
                </AccordionItem>
                <br />

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ backgroundColor: 'white' }}>
                      <strong>Link</strong>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    Clique em “Ver” para navegar no anúncio e aproveite para entender a qualidade e competitividade da publicação. Esta é uma das principais fontes de diferenciação e melhorias para seu anúncio ser matador. Recomendamos analisar os seguintes pontos:  Título, Fotos, Vídeo, Preço, Condições de Pagamento, Condições de Envio, Descrição, Avaliações, Perguntas/Respostas e Disponibilidade de estoque. Use os dados ao seu favor para adequar a sua oferta, ganhar relevância e ser líder na sua categoria.
                  </AccordionItemPanel>
                </AccordionItem>
                <br />

              </Accordion>
            </p>
          </div>
          <div>
            <Button
              color="primary"
              size="lg"
              type="submit"
              onClick={() => setInformativeModal(false)}
              style={{ width: '16rem', height: '3rem', marginTop: '1rem' }}
            >
              Entendi
            </Button>
          </div>
        </ModalComponent>
      )}
    </Row>
  );
};

export default Sales;
