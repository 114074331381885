/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getStripeSessionById, portal } from '../../services/users';
import Loading from '../loading/Loading';

const redirectPortal = () => {
  // const [responseFaturamento, setResponseFaturamento] = useState();
  const userEmail = useSelector(({ auth }) => auth.user.email);
  const userSession = useSelector(({ auth }) => auth.user.sessionID);
  const userPlan = useSelector(({ auth }) => auth.user.plan);
  const [alertErrorStripePortal, setAlertErrorStripePortal] = useState(false);

  // async function faturamento() {
  //   const response = await getFaturamento(userEmail);
  //   window.location = response;
  // }
  async function getStripeSession() {
    await getStripeSessionById(userSession);
  }

  let response;
  const portalClick = async () => {
    try {
      response = await portal(userEmail, userPlan);
      window.location = response;
    } catch (err) {
      setAlertErrorStripePortal(true);
    }
  };

  const handlePortalError = () => {
    window.location.replace('/first-steps');
    setAlertErrorStripePortal(false);
  };

  useEffect(() => {
    getStripeSession();
    setTimeout(() => {
      if (userEmail) {
        setTimeout(() => {
          portalClick();
        }, 500);
      }
    }, 500);
  }, [userEmail]);

  if (alertErrorStripePortal) {
    return (
      <SweetAlert
        error
        title="Erro ao associar seu e-mail ao pagamento"
        confirmBtnText="Certo"
        confirmBtnBsStyle="primary"
        confirmBtnStyle={{ border: 'none' }}
        focusConfirmBtn={false}
        onConfirm={handlePortalError}
      >
        Seu email não foi encontrado como um usuário pagador em nossa plataforma,
        você será direcionado para preencher o onboarding novamente.
        <br />
        <br />
        <br />
        Caso já tenha feito o pagamento,
        {' '}
        <br />
        {' '}
        entre em contato no e-mail:
        <address>
          <a
            href="mailto:atendimento@mercadoacelerado.com.br"
          >
            atendimento@mercadoacelerado.com.br
          </a>
        </address>
      </SweetAlert>
    );
  }

  return (
    <Loading />
  );
};

export default redirectPortal;
