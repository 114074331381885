import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
} from 'reactstrap';
import { getFaturamento } from '../../../../services/users';
import { restoreAuth } from '../../../../store/auth/auth.action';
import styles from './styles.module.scss';

const Page2 = (props) => {
  // eslint-disable-next-line react/prop-types
  const { onClick } = props;
  const dispatch = useDispatch();

  const userEmail = useSelector(({ auth }) => auth.user.email);

  const { token } = JSON.parse(sessionStorage.getItem('@userAuth'));

  async function faturamento() {
    const responseUser = await getFaturamento(userEmail);
    dispatch(restoreAuth({ user: responseUser?.user, token }));
  }

  useEffect(() => {
    setTimeout(() => { faturamento(); }, 1000);
  }, []);

  return (
    <>
      <section className={styles.container}>

        <div className={styles.title}>Uma pena você ter saído</div>
        <div className={styles.subTitle}>Sua assinatura foi cancelada</div>
        <div className={styles.content}>
          <p>
            Dados de mercado ajudam as empresas diariamente a fazerem novos investimentos,
            {' '}
            <br />
            melhorarem seus times, otimizar a operação e o principal:
            <strong style={{ color: '#F28500' }}>
              {' '}
              trazer vendas e dinheiro
              {' '}
              <br />
              para o negócio!
            </strong>
          </p>
          <p>
            Mas ainda dá tempo, as portas do Dashboard do Mercado Acelerado
            {' '}
            <strong>
              sempre estarão
              {' '}
              <br />
              {' '}
              abertas para os nossos vendedores!
            </strong>
          </p>

        </div>

        <div>

          <Button
            onClick={onClick}
            color="primary"
            size="lg"
            style={{
              height: '45px',
              width: '250px',
              fontSize: '0.8rem',
              fontWeight: '650',

            }}
          >
            Reativar minha conta
          </Button>

        </div>
      </section>
    </>
  );
};

export default Page2;
