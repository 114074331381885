const env = process.env.NODE_ENV;
const isProduction = env === 'production';

const baseURL = {
  development: 'http://localhost:3333',
  // development: 'http://165.232.55.15',
  production: 'https://mercadoacelerado.app.doctorlib.com.br',
}[env];

const config = {
  isProduction,
  baseURL,
};

// eslint-disable-next-line no-console
console.log(config);

export default config;
