import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, CardBody } from 'reactstrap';
import { ReactComponent as EmBreveG } from '../../assets/images/EmBreveG.svg';

const EmBreve = () => {
  const userPlan = useSelector(({ auth }) => auth.user.plan);

  if (userPlan === null) window.location.replace('/first-steps');

  const onClick = () => {
    window.location.replace('/sales');
  };

  return (
    <Card style={{
      minWidth: '1100px', height: '600px', textAlign: 'center', marginTop: '1.8rem',
    }}
    >
      <CardBody>
        <div>
          <div>
            <h2 style={{
              color: '#F28500', fontWeight: '600', fontSize: '1.6rem', marginTop: '1rem',
            }}
            >
              Estamos trabalhando para você acelerar ao máximo.
            </h2>
            <h3 style={{ color: '#23364A', fontWeight: '600', fontSize: '1.2rem' }}>Em breve novidades, análises e recomendações que te ajudarão a vender muito mais!</h3>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <EmBreveG style={{ margin: '0 auto', width: '350px', paddingBottom: '5rem' }} />
            <Button
              style={{
                marginTop: '26.25rem', position: 'absolute', width: '450px', height: '50px',
              }}
              size="lg"
              color="primary"
              className="font-weight-bold waves-effect waves-light"
              onClick={onClick}
            >
              Ir para os Mais Vendidos
            </Button>
          </div>
        </div>

      </CardBody>
    </Card>

  );
};

export default EmBreve;
