import reducer from './trends.reducer';

export {
  TrendsTypes,
  getTrends,
  getTrendsFilter,
  getTrendsPage,
} from './trends.action';

export default reducer;
