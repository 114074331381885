import Api from './api';
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable import/prefer-default-export */

export async function addTrackedSeller(
  userId, newSellerToTrack,
) {
  console.log({ newSellerToTrack });
  try {
    const { data } = await Api.post(`seller/addTrackedSeller/${userId}`,
      { newSellerToTrack });
    return data;
  } catch (error) {
    return error;
  }
}

export async function deleteTrackedSeller(userId, id) {
  try {
    console.log({ userId, id });
    const { data } = await Api.delete('/seller/deleteTrackedSeller',
      {
        data: { userId, id },
      });
    return data;
  } catch (error) {
    return error;
  }
}

export async function sellerName(sellerId) {
  try {
    const { data } = await Api.get(`/seller/sellerName/${sellerId}`);
    return data;
  } catch (error) {
    return error;
  }
}

export async function trackedSeller(userId) {
  try {
    const { data } = await Api.get(`/seller/trackedSellers/${userId}`);
    return data;
  } catch (error) {
    return error;
  }
}

export async function itemsSeller(arrayOfNewsSellers) {
  try {
    const { data } = await Api.post('/seller/fetch', arrayOfNewsSellers);
    return data;
  } catch (error) {
    return error;
  }
}

export async function numberOfTrackedSellers(userId) {
  try {
    const { data } = await Api.get(`/seller/numberOfTrackedSellers/${userId}`);
    return data;
  } catch (error) {
    return error.response.data.msg;
  }
}

export async function crawlerSellerItems({ id: userId }) {
  try {
    const { data } = await Api.post('seller/crawler', { userId });
    return data;
  } catch (error) {
    return error;
  }
}
