import { setApiToken } from '../../services/api';
import { AuthActions } from './auth.action';

const INITIAL_STATE = {
  user: {},
  token: null,
  isFetching: false,
  error: false,
  errors: [],
  msg: '',

  /**
   * Using for navigation
   */
  hasAuth: false,
  isLoading: true,
  categoriesUserTracking: [],
};

const authReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthActions.SIGNIN: {
      return {
        ...state,
        error: true,
        errors: [],
        isFetching: true,
      };
    }

    case AuthActions.RECEIVE_AUTH: {
      const { user, token, remember } = action.payload;
      const hasAuth = Boolean(user && token);

      if (hasAuth) {
        setApiToken(token);
        const storePayload = JSON.stringify({ user, token });
        sessionStorage.setItem('@userAuth', storePayload);
        try {
          if (remember) {
            localStorage.setItem('@userAuth', storePayload);
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      }

      return {
        ...state,
        hasAuth,
        user,
        token,
        error: false,
        errors: [],
        isFetching: false,
        isLoading: false,
      };
    }

    case AuthActions.ID_CATEGORIES: {
      return {
        ...state,
        categoriesUserTracking: [...action.payload],
      };
    }

    case AuthActions.ERROR_AUTH: {
      const { errors, msg } = action.payload;
      return {
        ...state,
        error: true,
        errors,
        msg,
        isFetching: false,
      };
    }

    case AuthActions.SIGNOUT: {
      try {
        localStorage.removeItem('@userAuth');
        sessionStorage.removeItem('@userAuth');
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }

      return {
        ...state,
        hasAuth: false,
        user: {},
        token: null,
        error: false,
        errors: [],
        isFetching: false,
      };
    }

    case AuthActions.ERROR_CLEAN: {
      return {
        ...state,
        error: false,
        errors: [],
        msg: '',
        isFetching: false,
      };
    }

    default:
      return state;
  }
};

export default authReducers;
