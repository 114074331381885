import { BreadcrumbTypes } from './breadcrumb.action';

const INITIAL_STATE = {
  title: 'Dashboard',
  breadcrumbItems: [
    { title: 'Lexa', link: '#' },
    { title: 'Dashboard', link: '#' },
    { title: 'Dashboard', link: '#' },
  ],
};

const breadcrumb = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BreadcrumbTypes.SET_BREADCRUMB_ITEMS: {
      return {
        ...state,
        title: action.payload.title,
        breadcrumbItems: action.payload.items,
      };
    }

    default: return state;
  }
};

export default breadcrumb;
