const BreadcrumbTypes = {
  SET_BREADCRUMB_ITEMS: '@breadcrumb/SET_BREADCRUMB_ITEMS',
};

const setBreadcrumbItems = (title, items) => ({
  type: BreadcrumbTypes.SET_BREADCRUMB_ITEMS,
  payload: { title, items },
});

export {
  BreadcrumbTypes,
  setBreadcrumbItems,
};
