import React from 'react';

const Progress = () => (
  <div className="spinner-chase">
    <div className="chase-dot" />
    <div className="chase-dot" />
    <div className="chase-dot" />
    <div className="chase-dot" />
    <div className="chase-dot" />
    <div className="chase-dot" />
  </div>
);

export default Progress;
