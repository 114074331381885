import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { restoreAuth } from '../../store/auth';
import Progress from '../../components/progress';
import './loading.css';

const Loading = () => {
  const dispatch = useDispatch();

  const loginWhenRemember = async () => {
    const user = (await JSON.parse(sessionStorage.getItem('@userAuth')) ?? await JSON.parse(localStorage.getItem('@userAuth'))) || undefined;
    dispatch(restoreAuth(user));
  };

  useEffect(() => {
    loginWhenRemember();
  }, []);

  return (
    <div id="preloader">
      <div id="status">
        <Progress />
      </div>
    </div>
  );
};

export default Loading;
