/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Label } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { getCategoriesFilter } from '../../store/categories';
import styles from './selectChangeSeller.module.scss';
import ModalMonitorar from '../welcomeStepper/components/modalMonitor/ModalMonitorar';
import gifForAlert from '../../assets/images/gifSweetAlert.gif';
import Progress from '../../components/progress';
import { ReactComponent as Close } from '../../assets/images/close_cross_icon.svg';
// import { ReactComponent as Arrow } from '../../assets/images/Arrow-right.svg';
import { ReactComponent as Voltar } from '../../assets/images/Arrow-back.svg';
import {
  addTrackedSeller,
  crawlerSellerItems,
  itemsSeller,
  numberOfTrackedSellers, sellerName, trackedSeller,
} from '../../services/seller';

const SelectChangeSeller = () => {
  const history = useHistory();
  const userId = useSelector(({ auth }) => auth.user.id);
  const [newInput, setNewInput] = useState(false);
  const [sellerN, setSellerN] = useState(false);
  const [idValor, setIdValor] = useState(null);
  const [noSeller, setNoSeller] = useState(null);
  const [verificationButton, setVerificationButton] = useState(true);
  const [verificationButtonConfirmSeller, setVerificationButtonConfirmSeller] = useState(true);
  const [sellerOfUser, setSellerOfUser] = useState([]);
  const [newSellerToTrack, setNewSellerToTrack] = useState([]);
  const [maxSeller, setMaxSeller] = useState(null);
  const [showStartLoader, setShowStartLoader] = useState(false);
  const [modalQuestionMark, setModalQuestionMark] = useState(false);
  const [progressStatus, setProgressStatus] = useState(false);
  const returnMsg = {
    0: 'Vendedores disponíveis: 0',
    1: 'Vendedor disponível: 1',
  };
  const restNumber = maxSeller - sellerOfUser.length;

  const dispatch = useDispatch();

  function getProperty(prop) {
    if (returnMsg[prop] !== undefined) {
      return returnMsg[prop];
    }
    return `Vendedores disponíveis: ${prop}`;
  }

  const popCategoryFromTrackingArray = (setArray, array, target) => {
    setArray(array.filter(
      (i) => i.id !== target.id || i.keyword !== target.keyword,
    ));
  };

  const handleClick = () => {
    setIdValor('');
    setNewInput(false);
    setVerificationButtonConfirmSeller(false);
    setVerificationButton(true);
    setSellerOfUser(
      [...sellerOfUser,
        {
          id: idValor,
          names: sellerN,
          newCategories: true,
        },
      ],
    );
    setNewSellerToTrack(
      [...newSellerToTrack,
        {
          id: idValor,
          sellerName: sellerN,
        },
      ],
    );
    setTimeout(() => {
      setIdValor(null);
    }, 400);
  };

  const handleClickStopTracking = (e) => {
    setVerificationButtonConfirmSeller(false);
    popCategoryFromTrackingArray(setSellerOfUser, sellerOfUser, e);
    popCategoryFromTrackingArray(setNewSellerToTrack, newSellerToTrack, e);
  };

  const handleClickConfirm = async () => {
    setProgressStatus(true);
    setVerificationButtonConfirmSeller(true);
    const isDoneGettingData = await itemsSeller(newSellerToTrack);
    await addTrackedSeller(
      userId,
      newSellerToTrack,
    );
    setProgressStatus(false);
    setShowStartLoader(true);
    console.log(isDoneGettingData);
    if (!isDoneGettingData?.itemExists) {
      console.log('entrou');
      await crawlerSellerItems(userId);
    }
  };

  const redirectPortal = () => {
    window.location.href = 'https://app.mercadoacelerado.com.br/portal-stripe';
  };

  const handleSweetAlert = () => {
    setTimeout(() => {
      history.push('/seller');
      setShowStartLoader({
        active: false,
      });
    }, 1200);
  };

  const back = () => {
    history.push('/categories');
  };

  async function catchUserAlreadyCategories() {
    const responseUser = await trackedSeller(userId);
    console.log(responseUser);
    const { maxSellers } = await numberOfTrackedSellers(userId);

    const arrayData = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < responseUser.length; i++) {
      const item = responseUser[i];
      arrayData.push({
        id: item.seller_id,
        names: item.seller_name,
      });
    }
    setSellerOfUser(arrayData);
    setMaxSeller(maxSellers);
    if (arrayData.length !== 0) {
      setVerificationButtonConfirmSeller(true);
    }
  }

  useEffect(() => {
    dispatch(getCategoriesFilter());
    catchUserAlreadyCategories();
  }, []);

  useEffect(() => {
    if (sellerOfUser.length === 0) {
      setVerificationButtonConfirmSeller(true);
    }
  }, [sellerOfUser.length]);

  const onKeyDown = async (e) => {
    if (e.keyCode === 13) {
      const valor = e.target.value;
      setIdValor(valor);
      const response = await sellerName(valor);
      if (response.sellerName) setNewInput(true);
      setSellerN(response.sellerName);
      if (!response.sellerName) {
        setNoSeller(true);
        setSellerN('Vendedor não encontrado!');
      }
      if (response.sellerName) setVerificationButton(false);
    }
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <Button
          className="w-0 no-bg waves-effect waves-light"
          style={{ width: '50px' }}
          onClick={back}
        >
          <Voltar style={{ width: '25px', marginLeft: '0.5rem' }} />
        </Button>
        <div className={styles.gridMainContainer}>
          <div className={styles.leftData}>
            <div className={styles.filterCategories}>
              <div className={styles.headerSelectSearch}>
                <p>
                  Qual novo vendedor deseja monitorar?
                </p>
                <Button
                  style={{
                    borderRadius: '50%',
                    backgroundColor: '#395C81',
                    width: '10px',
                    height: '25px',
                    fontWeight: '500',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '15px',
                  }}
                  onClick={() => setModalQuestionMark(true)}
                >
                  ?
                </Button>
              </div>
              <div style={{ display: 'flex' }}>
                <Input
                  style={{
                    background: '#395c81',
                    color: '#ecf2f6',
                    border: 'none',
                    fontSize: 'min(0.8vw, 0.8125rem)',
                    width: '17.7rem',
                    height: '2rem',
                    marginLeft: '3.5rem',
                  }}
                  type="text"
                  placeholder="Buscar por ID do Vendedor"
                  onKeyDown={onKeyDown}
                  restNumber={restNumber}
                  value={idValor}
                />
                <Button
                  style={{
                    marginLeft: '1.5rem',
                    color: 'black',
                    backgroundColor: 'transparent',
                    border: 'none',
                    fontSize: '110%',
                    borderRadius: '50%',
                  }}
                  onClick={() => history.go(0)}
                >
                  X
                </Button>
              </div>
              {newInput && (
                <Label
                  style={{
                    background: '#395c81',
                    color: '#ecf2f6',
                    border: 'none',
                    fontSize: 'min(0.8vw, 0.8125rem)',
                    width: '17.7rem',
                    height: '2rem',
                    marginTop: '1rem',
                    borderRadius: '0.2rem',
                    display: 'flex',
                    justifyContent: 'start',
                    paddingLeft: '0.8rem',
                    alignItems: 'center',

                  }}
                >
                  {sellerN}
                </Label>
              )}

              {noSeller && (
                <p style={{ fontSize: '120%', marginTop: '1rem' }}>
                  {sellerN}
                </p>
              )}

              <Button
                onClick={handleClick}
                disabled={verificationButton}
                color="primary"
                size="lg"
                style={{
                  height: '45px',
                  width: '250px',
                  margin: '0 auto',
                  fontSize: '0.8rem',
                  marginTop: '1rem',
                  fontWeight: '650',

                }}
              >
                Continuar &gt;
              </Button>

            </div>
          </div>

          <div className={styles.rightData}>
            <div className={styles.selectCategories}>

              <p>Vendedores escolhidos</p>

              {sellerOfUser?.map((e) => (

                <div className={styles.mainDiv}>
                  <div className={styles.orangeCircle}>
                    <div className={styles.orangeInside}>
                      ID do vendedor:
                      {' '}
                      {e.id}
                      {' '}
                      <div>
                        &gt;
                      </div>
                      {' '}
                      <div style={{ color: 'orange' }}>
                        {e.names}
                      </div>
                    </div>
                    {/* <div
                      className={styles.orangeInside}
                      style={
                        {
                          color: 'orange',
                          wordBreak: e.keyword === 'Todos Resultados ' ? 'keep-all' : 'break-all',
                        }
                    }
                    >
                      {e.seller_name}
                    </div> */}
                  </div>
                  {e?.newCategories && (
                    <div
                      onClick={() => handleClickStopTracking(e)}
                      style={{
                        marginLeft: '10px', color: 'orange', cursor: 'pointer', marginBottom: '0.5rem',
                      }}
                    >
                      <Close width="10px" height="10px" />
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className={styles.bottomContainer}>

              <div className={styles.numberOfCategories}>
                <p>
                  {' '}
                  Vendedores monitorados:
                  {' '}
                  {sellerOfUser.length}
                  {' '}
                  {' '}
                  |
                  {' '}
                  <span style={{ color: '#F28500' }}>
                    {getProperty(restNumber)}
                  </span>
                </p>

              </div>
              <div className={styles.btnContainer}>
                {progressStatus
                  ? (
                    ''
                  )
                  : (
                    <Button
                      className={styles.btnPlan}
                      onClick={redirectPortal}
                    >
                      Revisar plano para mais Vendedores
                    </Button>
                  )}

                {progressStatus
                  ? (
                    <Progress />
                  )
                  : (
                    <Button
                      color="primary"
                      className={styles.btnConfirm}
                      onClick={handleClickConfirm}
                      disabled={verificationButtonConfirmSeller}
                    >
                      Confirmar Vendedores
                    </Button>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showStartLoader && (
        <SweetAlert
          custom
          title="Vendedores adicionados!"
          customIcon={gifForAlert}
          confirmBtnBsStyle="success"
          onConfirm={handleSweetAlert}
        >
          Aguarde alguns minutos enquanto trazemos os dados para você.
        </SweetAlert>
      )}
      {modalQuestionMark && (
        <ModalMonitorar ModalActive={() => setModalQuestionMark(false)} widthHelper="60%" title="">
          <div>
            <p className="fontCardModal">
              <strong style={{ color: '#567FAA' }}>Importante: </strong>
              L1 considerada a categoria Macro, para
              <br />
              informações mais assertivas sobre as vendas de mercado,
              <br />
              solicitamos que escolha a partir de L3. Caso a categoria não
              <br />
              possua níveis de sub-categorias L3 e L4, selecionar L2.
            </p>
            <div className="containerColumnsModal">
              <div className="columnsModal">
                <div className="LModal">L1</div>
                <div className="categoryModal">
                  Saúde
                </div>
              </div>
              <div className="crevronModal">&gt;</div>
              <div className="columnsModal">
                <div className="LModal">L2</div>
                <div className="categoryModal">
                  Equipamento Médico
                </div>
              </div>
              <div className="crevronModal">&gt;</div>
              <div className="columnsModal">
                <div className="LModal">L3</div>
                <div className="categoryModal">
                  Médicos
                </div>
              </div>
              <div className="crevronModal">&gt;</div>
              <div className="columnsModal">
                <div className="LModal"> L4</div>
                <div className="categoryModal">
                  Máscaras Cirurgicas
                </div>

              </div>
            </div>
          </div>
        </ModalMonitorar>
      )}
    </>
  );
};

export default SelectChangeSeller;
