/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable import/prefer-default-export */
import { toast } from 'react-toastify';
import Api from './api';

export function numberOfCategoriesUser(userId) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api.get(`users/categoriesOfUser/${userId}`);
      return resolve(response.data);
    } catch (error) {
      reject(error.response.data.msg);
    }
  });
}

export async function pullNewCategories(categoriesOfUser, onBoarding) {
  let formatedArray = false;
  if (!onBoarding) {
    formatedArray = categoriesOfUser.map((item) => ({
      ...{
        category_id: item.id,
        keyword: item.keyword === 'Todos Resultados ' ? false : item.keyword,
      },
    }));
  }

  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api.post('/categories/fetchItemsByCategory', formatedArray || [categoriesOfUser]);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
}

export function startCrawler(userId = '', isForOnboarding) {
  return new Promise(async (resolve, reject) => {
    !isForOnboarding
      && toast.info(
        'Seus dados estão sendo carregados. Aguarde alguns instantes, isso demora em média 5 minutos',
        {
          autoClose: 25000,
        },
      );
    try {
      const response = await Api.post('/categories/startCrawler', { userId });
      toast.success('Dados carregados!');
      setTimeout(() => {
        window.location.reload();
      }, 5000);
      return resolve(response.data);
    } catch (error) {
      reject(error.response.data.msg);
    }
  });
}

export function changeAllUserCategories(
  userId,
  newCategoriesToTrack = '',
  maxCategories,
) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api.post(
        `/categories/changeUserCategories/${userId}`,
        { newCategoriesToTrack, maxCategories },
      );
      return resolve(response.data);
    } catch (error) {
      reject(error.response.data.msg);
    }
  });
}

export function postSilverPlan(userId) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api.post(`/categories/postSilverPlan/${userId}`);
      return resolve(response.data);
    } catch (error) {
      reject(error.response.data.msg);
    }
  });
}
