/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col, Row, Card, CardBody,
} from 'reactstrap';
import Media from 'react-media';
import SweetAlert from 'react-bootstrap-sweetalert';
import Table from '../../components/table';
import SelectSearch from '../../components/selectSearch';
import ExportTableSeller from '../../components/exportTableSeller';
import useTableData from '../../hooks/useTableData';
import './seller.css';
import { isFetchingCategoryOnboarding } from '../../store/categories/categories.action';
import 'react-accessible-accordion/dist/fancy-example.css';
import { getSellerFilter, getSellerPage } from '../../store/seller';
import TextSearch from '../../components/textSearch';
import { crawlerSellerItems } from '../../services/seller';

const SellerData = ({ item }) => {
  if (!item.seller) return <td />;
  return (
    <td>
      {decodeURIComponent(
        item.seller
          .replace('http://perfil.mercadolivre.com.br/', '')
          .replace('+', ' ')
          .replaceAll('_', ' '),
      )}
    </td>
  );
};

SellerData.defaultProps = {
  item: { seller: '' },
};

SellerData.propTypes = {
  item: PropTypes.shape({
    seller: PropTypes.string,
  }),
};

const LinkData = ({ item }) => (
  <td>
    <a
      href={`${item.permalink}`}
      style={{ color: '#384E5E', fontWeight: '700' }}
      target="_blank"
      rel="noopener noreferrer"
    >
      Ver
    </a>
  </td>
);

LinkData.propTypes = {
  item: PropTypes.shape({
    permalink: PropTypes.string.isRequired,
  }).isRequired,
};

const Conversion = ({ item }) => (
  <td>{item.buybox ? 'N/A' : `${Number(item.conversion * 100).toFixed(2)}%`}</td>
);

Conversion.defaultProps = {
  item: { conversion: '', buybox: false },
};

Conversion.propTypes = {
  item: PropTypes.shape({
    conversion: PropTypes.number,
    buybox: PropTypes.bool,
  }),
};
const ComparativeConversion = ({ item }) => {
  const verificationForItemBuybox = !!(
    item.toastifyWarning
    || item.comparative_conversion
    || item.comparative_conversion === 0
  );
  const currentConversion = (item.conversion * 100).toFixed(2);

  if (item.buybox && verificationForItemBuybox) {
    return <td>N/A</td>;
  }

  if (!item.buybox && Number(item.comparative_conversion) > Number(currentConversion)) {
    return (
      <td style={{ color: 'green' }}>
        {`${item.comparative_conversion}%`}
      </td>
    );
  }
  if (!item.buybox && Number(item.comparative_conversion) < Number(currentConversion)) {
    return (
      <td style={{ color: 'red' }}>
        {`${item.comparative_conversion}%`}
      </td>
    );
  }
  return <td>-</td>;
};

ComparativeConversion.defaultProps = {
  item: {
    conversion: '', buybox: false, comparative_conversion: null, toastifyWarning: false,
  },
};

ComparativeConversion.propTypes = {
  item: PropTypes.shape({
    conversion: PropTypes.number,
    buybox: PropTypes.bool,
    comparative_conversion: PropTypes.number,
    toastifyWarning: PropTypes.string,
  }),
};

const ThumbData = ({ item }) => (
  <td>
    <img
      src={item.thumbnail}
      alt={`${item.id}-thumb`}
      className="sales-image"
    />
  </td>
);

ThumbData.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
  }).isRequired,
};

const Price = ({ item, column }) => {
  const formated = useMemo(() => {
    const raw = item[column.id];
    const locale = Number(raw).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return locale;
  }, [item, column.id]);

  return <td>{formated}</td>;
};

Price.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
  }).isRequired,
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

const Visits = ({ item }) => {
  if (item.visits === 0) {
    return <td> 1 </td>;
  }
  if (item.visits) {
    return <td>{item.visits.toLocaleString('pt-BR')}</td>;
  }
  return (
    <td>{item.buybox ? '-' : item.visits}</td>
  );
};

Visits.defaultProps = {
  item: { buybox: false, visits: 0 },
};

Visits.propTypes = {
  item: PropTypes.shape({
    buybox: PropTypes.bool,
    visits: PropTypes.number,
  }),
};

const Buybox = ({ item }) => (
  <td>{item.buybox ? <p style={{ color: '#F28500' }}>SIM</p> : 'NÃO'}</td>
);

Buybox.defaultProps = {
  item: { buybox: false },
};

Buybox.propTypes = {
  item: PropTypes.shape({
    buybox: PropTypes.bool,
  }),
};

const Free = ({ item }) => (
  <td>{item.free_shipping ? <p style={{ color: '#F28500' }}>SIM</p> : 'NÃO'}</td>
);

Free.defaultProps = {
  item: { free_shipping: false },
};

Free.propTypes = {
  item: PropTypes.shape({
    free_shipping: PropTypes.bool,
  }),
};

const GMV = ({ item, column }) => {
  const formated = useMemo(() => {
    const raw = item[column.id];
    const locale = Number(raw).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return locale;
  }, [item, column.id]);

  return <td>{item.buybox ? 'N/A' : formated}</td>;
};

GMV.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    GMV: PropTypes.string.isRequired,
    buybox: PropTypes.bool,
  }).isRequired,
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

const CreateAt = ({ item }) => (
  <td>
    {item.created_at
      && item.created_at.split('T')[0].split('-').reverse().join('/')}
  </td>
);

CreateAt.defaultProps = {
  item: { created_at: '' },
};

CreateAt.propTypes = {
  item: PropTypes.shape({
    created_at: PropTypes.string,
  }),
};

const ComparativeSells = ({ item }) => {
  if (item.comparative_sells > 0) {
    return (
      <td style={{ color: 'green' }}>
        +
        {item.comparative_sells.toLocaleString('pt-BR')}
      </td>
    );
  }
  return <td>-</td>;
};

ComparativeSells.defaultProps = {
  item: { comparative_sells: null },
};

ComparativeSells.propTypes = {
  item: PropTypes.shape({
    comparative_sells: PropTypes.number,
    buybox: PropTypes.bool,
  }),
};

const ComparativeGMV = ({ item }) => {
  const verificationForItemBuybox = !!(
    item.toastifyWarning
    || item.comparative_gmv
    || item.comparative_gmv === 0
  );
  const locale = Number(item.comparative_gmv).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  if (item.buybox && verificationForItemBuybox) {
    return <td>N/A</td>;
  }
  if (!item.buybox && item.comparative_gmv > 0) {
    return (
      <td style={{ color: 'green' }}>
        +
        {' '}
        {locale}
      </td>
    );
  }
  return <td>-</td>;
};

ComparativeGMV.defaultProps = {
  item: {
    comparative_gmv: null, gmv: null, buybox: true, toastifyWarning: false,
  },
};

ComparativeGMV.propTypes = {
  item: PropTypes.shape({
    comparative_gmv: PropTypes.number,
    gmv: PropTypes.number,
    buybox: PropTypes.bool,
    title: PropTypes.string,
    toastifyWarning: PropTypes.string,
  }),
};

const ComparativeVisits = ({ item }) => {
  if (item.comparative_visits > 0) {
    return (
      <td style={{ color: 'green' }}>
        {`+${item.comparative_visits.toLocaleString('pt-BR')}`}
      </td>
    );
  }
  return <td>-</td>;
};

ComparativeVisits.defaultProps = {
  item: { comparative_visits: null, buybox: false },
};

ComparativeVisits.propTypes = {
  item: PropTypes.shape({
    comparative_visits: PropTypes.number,
    buybox: PropTypes.bool,
  }),
};

const StockAvailable = ({ item }) => {
  const { real_available_quantity: stockAvailable } = item;
  if (stockAvailable === 0) {
    return <td> 1 </td>;
  }
  if (stockAvailable) {
    return <td>{stockAvailable.toLocaleString('pt-BR') || '-'}</td>;
  }
  return <td> - </td>;
};

StockAvailable.defaultProps = {
  item: { real_available_quantity: 1 },
};

StockAvailable.propTypes = {
  item: PropTypes.shape({
    real_available_quantity: PropTypes.number,
  }),
};

const Sells = ({ item }) => {
  if (item.real_sold_quantity) {
    const formatedSell = item.real_sold_quantity.toLocaleString('pt-BR');
    return (
      <td>
        {formatedSell}
      </td>
    );
  }
  return <td> - </td>;
};

Sells.defaultProps = {
  item: { real_sold_quantity: 0 },
};

Sells.propTypes = {
  item: PropTypes.shape({
    real_sold_quantity: PropTypes.number.isRequired,
  }),
};

const ListingType = ({ item }) => (
  <td>{item.listing_type_id === 'gold_pro' ? 'Premium' : 'Clássico'}</td>
);

ListingType.defaultProps = {
  item: { listing_type_id: false },
};

ListingType.propTypes = {
  item: PropTypes.shape({
    listing_type_id: PropTypes.bool,
  }),
};

const FormatedId = ({ item }) => {
  const formatedId = item.id.split('-')[0];
  return (
    <td>
      {' '}
      {formatedId}
      {' '}
    </td>
  );
};

FormatedId.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

const columns = [
  // { id: 'itemRelevance', title: 'POSIÇÃO NAS BUSCAS' },
  { id: 'thumbnail', title: 'IMAGEM', Component: ThumbData },
  { id: 'title', title: 'TÍTULO', style: { minWidth: '500px' } },
  { id: 'id', title: 'ID DO ANÚNCIO', Component: FormatedId },
  { id: 'brand', title: 'MARCA' },
  { id: 'seller_name', title: 'VENDEDOR' },
  { id: 'buybox', title: 'CATÁLOGO', Component: Buybox },
  {
    id: 'price',
    title: 'PREÇO ATUAL',
    Component: Price,
    style: { minWidth: '300px' },
  },
  { id: 'listing_type_id', title: 'TIPOS DE ANÚNCIO', Component: ListingType },
  { id: 'logistic_type', title: 'TIPO DE ENVIO' },
  { id: 'free_shipping', title: 'FRETE GRÁTIS', Component: Free },
  { id: 'real_sold_quantity', title: 'VENDAS TOTAIS', Component: Sells },
  {
    id: 'comparative_sells',
    title: 'VENDAS PERÍODO',
    Component: ComparativeSells,
    style: { minWidth: '180px' },
  },
  { id: 'real_available_quantity', title: 'ESTOQUE DISPONÍVEL', Component: StockAvailable },
  { id: 'gmv', title: 'FATURAMENTO TOTAL', Component: GMV },
  {
    id: 'comparative_gmv',
    title: 'FATURAMENTO PERÍODO',
    Component: ComparativeGMV,
    style: { minWidth: '250px' },
  },
  { id: 'visits', title: 'VISITAS TOTAIS', Component: Visits },
  {
    id: 'comparative_visits',
    title: 'VISITAS PERÍODO',
    Component: ComparativeVisits,
  },
  { id: 'conversion', title: 'CONVERSÃO', Component: Conversion },
  {
    id: 'conversionSell',
    title: 'CONVERSÃO PERÍODO',
    Component: ComparativeConversion,
  },
  {
    id: 'created_at',
    title: 'CRIADO EM',
    Component: CreateAt,
  },
  { id: 'permalink', title: 'LINK', Component: LinkData },
];

const Seller = () => {
  const [targetFilter, setTargetFilter] = useState('');
  const [targetKeyForFilter, setTargetKeyForFilter] = useState();
  const { filters } = useSelector(({ seller }) => seller);
  const {
    isFetchingCategoryOnboarding: onboardCategoryFetch,
  } = useSelector(({ categories }) => categories);
  const { id: userIdRedux, plan: userPlan } = useSelector(({ auth }) => auth.user);

  useEffect(() => {
    if (userPlan === null) {
      window.location.replace('/first-steps');
    }
    if (userPlan === 0) {
      window.location.replace('reactivate-account');
    }
  }, []);

  const dispatch = useDispatch();
  const {
    rows,
    isFetching,
    paging,
    ordering,
    searchInput,
    filtered,
    setSearch,
    onChangeSearch,
    onKeyDown,
    onCancelSearch,
    onChangeFilter,
    onChangeOrder,
    onPageChange,
  } = useTableData({ reducer: 'seller', action: getSellerPage });

  async function crawlerSeller() {
    await crawlerSellerItems(userIdRedux, true);
  }

  useEffect(() => {
    if (userPlan === null) {
      window.location.replace('/first-steps');
    }
  }, []);
  useEffect(() => {
    crawlerSeller();
    dispatch(getSellerFilter({
      id: userIdRedux,
      valueOfFilter: targetFilter,
      keyOfFilter: targetKeyForFilter,
    }));
  }, [rows]);

  return (
    <Row className="w-100">
      <Media query={{ minWidth: '780px' }}>
        {(matches) => (matches ? (
          <Col xs="12">
            <h3 style={{ color: '#567FAA', fontWeight: 'bold' }}>
              Os anúncios dos vendedores monitorados
            </h3>
            <div style={{ display: 'flex' }}>
              <Card xs="7" className="mr-1" style={{ flex: 4 }}>
                <CardBody
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    background: '#567faa',
                    padding: '0.7rem 0',
                  }}
                >
                  <SelectSearch
                    filtered={filtered}
                    filters={filters}
                    onChangeFilter={onChangeFilter}
                    setStateForBrandFilter={setTargetFilter}
                    setStateForBrandKeyFilter={setTargetKeyForFilter}
                    type="seller"
                  />
                  <TextSearch
                    label="Busca"
                    searchInput={searchInput}
                    setSearch={setSearch}
                    onKeyDown={onKeyDown}
                    onChangeSearch={onChangeSearch}
                    onCancelSearch={onCancelSearch}
                  />
                </CardBody>
              </Card>
              <Card xs="5" style={{ flex: 1 }}>
                <CardBody
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#567FAA',
                    padding: '0.7rem 0',
                  }}
                >
                  <ExportTableSeller
                    user={userIdRedux}
                    filtered={filtered}
                    limitForQuery={paging.totalItems}
                  />
                </CardBody>
              </Card>
            </div>
            <Card>
              <CardBody>
                <Table
                  showPaging
                  columns={columns}
                  rows={rows}
                  isFetching={isFetching}
                  paging={paging}
                  onPageChange={onPageChange}
                  onChangeOrder={onChangeOrder}
                  ordering={ordering}
                />
              </CardBody>
            </Card>
          </Col>
        ) : (
          <Col xs="12">
            <Card>
              <CardBody>
                <SelectSearch
                  filtered={filtered}
                  filters={filters}
                  onChangeFilter={onChangeFilter}
                />

                <ExportTableSeller
                  user={userIdRedux}
                  filtered={filtered}
                  limitForQuery={paging.totalItems}
                />

                <Table
                  showPaging
                  columns={columns}
                  rows={rows}
                  isFetching={isFetching}
                  paging={paging}
                  onPageChange={onPageChange}
                  onChangeOrder={onChangeOrder}
                  ordering={ordering}
                />
              </CardBody>
            </Card>
          </Col>
        ))}
      </Media>
      {
          onboardCategoryFetch && (
            <SweetAlert
              info
              title="Seus dados estão sendo carregados."
              confirmBtnText="Entendi!"
              confirmBtnBsStyle="primary"
              confirmBtnStyle={{ border: 'none' }}
              focusConfirmBtn={false}
              onConfirm={() => dispatch(isFetchingCategoryOnboarding(false))}
            >
              Aguarde alguns instantes, isso demora em média 5 minutos
            </SweetAlert>
          )
        }
    </Row>
  );
};

export default Seller;
