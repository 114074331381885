import React from 'react';
import { Collapse } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';

const Navbar = ({ isMenuOpened, isAccountCreated }) => {
  const location = useLocation();

  const getActive = (pathname) => {
    const isSame = pathname === location.pathname;
    if (isSame) return 'active';

    const paths = location.pathname.split('/');
    const somePath = paths.some((path) => path === pathname);

    return somePath ? 'active' : '';
  };

  return (
    <div className="topnav">
      <nav
        className="navbar-light navbar-expand-lg topnav-menu"
        id="navigation"
      >
        <Collapse
          isOpen={isMenuOpened}
          className="navbar-collapse"
          style={{ flexDirection: 'row-reverse' }}
          id="topnav-menu-content"
        >
          { !isAccountCreated ? (
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className={`nav-link ${getActive('/login')}`} to="login">
                  Login
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${getActive('/register')}`} to="register">
                  Cadastre-se
                </Link>
              </li>
            </ul>
          ) : ''}
        </Collapse>
      </nav>
      <ToastContainer />
    </div>
  );
};

Navbar.defaultProps = {
  isAccountCreated: false,
};

Navbar.propTypes = {
  isMenuOpened: PropTypes.bool.isRequired,
  isAccountCreated: PropTypes.bool,
};

export default Navbar;
