import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const ConfirmCategory = ({
  fullName,
  inputKeyword,
  // eslint-disable-next-line react/prop-types
  onClick: onClickProp,
  // eslint-disable-next-line react/prop-types
  onBack: back,
  idChildren,
}) => {
  // const { onClick } = props;
  const keyword = !inputKeyword ? 'Todos Resultados' : inputKeyword;
  const concatFullName = `${fullName}  >  `;
  const formatedFullName = concatFullName.split('> ').filter((i) => i !== ' ');
  const arrayCategories = [];

  function onClick() {
    arrayCategories.push({ id: idChildren, keyword: inputKeyword });
    localStorage.setItem('CategoriesForUser', JSON.stringify(arrayCategories));
    onClickProp();
  }
  function voltar() {
    back();
  }

  return (

    <section>
      <>
        <div className={styles.chosen}>Confirme a categoria escolhida:</div>
        <p className={styles.p}>
          Agora só falta confirmar se os seus monitoramentos selecionados estão corretos.
        </p>
        <div className={styles.resume}>
          Resumo das categorias monitoradas
        </div>

        <div className={styles.mainDiv}>
          <div className={styles.orangeCircle}>
            {formatedFullName?.map((item) => (
              <>
                <div className={styles.orangeInside}>
                  {item}
                </div>
                {'>'}
              </>
            ))}
            <div
              className={styles.orangeInside}
              style={{ color: 'orange' }}
            >
              {keyword}
            </div>
          </div>
        </div>

        <p className={styles.p2}>
          *Caso você queira selecionar mais categorias, você terá essa opção após o setup da conta.
        </p>
        <Button
          onClick={() => onClick()}
          color="primary"
          size="lg"
          style={{
            height: '45px',
            width: '250px',
            margin: '0 auto',
            marginBottom: '20px',
            fontSize: '1rem',
            fontWeight: '650',
          }}
        >
          Confirmar Categoria &gt;
        </Button>
        <div>

          <button
            type="button"
            className={styles.button}
            onClick={voltar}
          >
            &lt;
            <u> Voltar</u>

          </button>
        </div>
      </>
    </section>
  );
};
ConfirmCategory.defaultProps = {
  fullName: '',
  inputKeyword: '',
  idChildren: '',

};

ConfirmCategory.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fullName: PropTypes.string,
  inputKeyword: PropTypes.bool,
  idChildren: PropTypes.string,

};

export default ConfirmCategory;
