import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
// import Photo from '../../assets/images/Logo_MA.png';
import Media from 'react-media';
import { ReactComponent as ArrowRight } from '../../assets/images/Arrow-right.svg';
import ImgHome from '../../assets/images/imgHome.png';
import './Home.modules.scss';

function Home() {
  const hasAuth = useSelector((state) => state.auth.hasAuth);
  const location = useLocation();
  const history = useHistory();
  const user = localStorage.getItem('@userAuth');
  useEffect(() => {
    if (user) {
      history.push('/categories');
    }
  }, [history, user]);

  const goRegister = () => {
    history.push('/register');
  };
  useEffect(() => {
    if (!hasAuth) return;
    const { from } = location.state || { from: { pathname: '/sales' } };
    history.replace(from);
  }, [location.state, hasAuth, history]);

  return (

    <Media query={{ minWidth: '781px' }}>
      {(matches) => (matches ? (
        <div
          className="home"
        >
          <div className="home__text-container">
            <h1 style={{ fontSize: '2.2rem', color: 'white' }}>
              Dados que te ajudam a tomar decisões e acelerar suas vendas dentro do Mercado Livre!
            </h1>
            <p style={{ fontSize: '1.05rem', marginTop: '2rem' }}>
              Monitore sua categoria, descubra quais produtos anunciar, conheça os
              itens mais vendidos, saiba o que a concorrência está oferecendo e
              como se diferenciar dos demais.
            </p>
            <Button
              onClick={goRegister}
              size="lg"
              block
              className="d-flex justify-content-between align-items-center py-3 px-4"
              style={{
                background: '#395C81',
                border: 'none',
                width: '300px',
                height: '60px',
                marginTop: '2rem',
              }}
            >
              {' '}
              Comece hoje gratuitamente
              <span aria-hidden>
                <ArrowRight />
              </span>
            </Button>
          </div>
          <div className="home__img-container">
            <img src={ImgHome} alt="" style={{ width: '500px', height: '480px' }} />
          </div>
        </div>
      ) : (
        <div className="home-mobile">

          <div className="home__text-container-mobile">
            <h1 style={{
              fontSize: 'min(7vw, 5.9125rem)', color: 'white', fontWeight: 'bold', textAlign: 'center',
            }}
            >
              Dados que te ajudam a tomar decisões e acelerar
              suas vendas dentro do Mercado Livre!

            </h1>
            <p style={{
              fontSize: 'min(4vw, 3.2125rem)', margin: '4rem 0 4rem 0', color: 'white', textAlign: 'center',
            }}
            >
              {' '}
              Monitore sua categoria, descubra quais produtos anunciar, conheça os
              itens mais vendidos, saiba o que a concorrência está oferecendo e
              como se diferenciar dos demais.

            </p>
            <div className="containerButton-mobile">
              <Button
                onClick={goRegister}
                size="lg"
                block
                style={{
                  background: '#F28500',
                  border: 'none',
                  width: '300px',
                  height: '60px',
                  margin: '0 auto',
                }}
              >
                {' '}
                Comece hoje gratuitamente
              </Button>
            </div>

          </div>

        </div>
      ))}

    </Media>
  );
}

export default Home;
