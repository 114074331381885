import React, { useEffect, useState, useCallback } from 'react';
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Media from 'react-media';
import { useLocation } from 'react-router-dom';
import { signout } from '../../../store/auth';
import { ReactComponent as User } from '../../../assets/images/User.svg';
// import ToggleTheme from '../../themes/ToggleTheme';
import './profileMenu.css';

const ProfileMenu = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = useCallback(() => { setOpen((value) => !value); }, []);
  const onSignout = useCallback(() => { dispatch(signout()); }, [dispatch]);
  const location = useLocation();
  const [plan, setPlan] = useState();
  const [colorPlan, setColorPlan] = useState('');

  const convertPlan = (userPlan) => {
    if (userPlan === 0) {
      setPlan('-');
      setColorPlan('#B3B3A1');
    }
    if (userPlan === 1) {
      setPlan('Silver');
      setColorPlan('#B3B3A1');
    }
    if (userPlan === 2) {
      setPlan('Gold');
      setColorPlan('#ECB42D');
    }
    if (userPlan === 3) {
      setPlan('Platinum');
      setColorPlan('#72848C');
    } if (userPlan === 4) {
      setPlan('Black');
      setColorPlan('#000000');
    } if (userPlan === 5) {
      setPlan('Diamond');
      setColorPlan('#B4BED1');
    } if (location.pathname === '/reactivate-account') {
      setPlan('-');
      setColorPlan('#B3B3A1');
    } if (location.pathname.split('/')[1] === 'success') {
      setPlan('Silver');
      setColorPlan('#B3B3A1');
    }
  };

  function name(value) {
    return value.split(' ').shift();
  }

  const getActive = (pathname) => {
    const isSame = pathname === location.pathname;
    if (isSame) return 'activeProfileMenu';

    const paths = location.pathname.split('/');
    const somePath = paths.some((path) => path === pathname);

    return somePath ? 'activeProfileMenu' : '';
  };

  useEffect(() => {
    convertPlan(user.plan);
  }, []);

  return (
    <Dropdown isOpen={isOpen} toggle={toggleOpen} className="d-inline-block ">

      <DropdownToggle
        tag="button"
        className="btn header-item waves-effect rounded-bottom "
        id="page-header-user-dropdown"
        aria-haspopup="true"
        style={{ color: '#949CA3' }}

      >

        <div className="d-inline-block profileMenu" style={{ color: '#949ca3' }}>
          <User style={{ marginRight: '0.3rem' }} />
          {' '}
          {name(user.fullname)}
          ▾
        </div>

      </DropdownToggle>
      <DropdownMenu right>
        <Media query={{ minWidth: '780px' }}>
          {(matches) => (matches ? (
            <>

              <DropdownItem
                href="/user"
                className={`border-bottom font-weight-bold textColorProfileMenu ${getActive('/user')} pt-2`}
                style={{
                  pointerEvents: location.pathname.split('/')[1] === 'success'
          || location.pathname.split('/')[1] === 'activating-account'
          || location.pathname === '/first-steps' ? 'none' : 'auto',
                }}
              >
                Meus dados
              </DropdownItem>
              <DropdownItem
                href="/portal-stripe"
                className={`border-bottom font-weight-bold textColorProfileMenu ${getActive('/portal-stripe')} `}
                style={{
                  pointerEvents: location.pathname.split('/')[1] === 'success'
          || location.pathname.split('/')[1] === 'activating-account'
          || location.pathname === '/first-steps'
                    ? 'none' : 'auto',
                }}
              >
                <div className="displayProfileMenu">

                  <span>
                    Meu plano
                  </span>
                  <p className="silverProfileMenu" style={{ color: colorPlan }}>
                    {plan}
                  </p>
                </div>
              </DropdownItem>

              <DropdownItem
                tag="button"
                href="/logout"
                onClick={onSignout}
                className="pt-2 font-weight-bold textColorProfileMenu mt-4"
              >
                <i className=" font-size-17  align-middle" />
                {' '}
                Logout
              </DropdownItem>
            </>
          ) : (
            <>
              <DropdownItem
                style={{ textAlign: 'center' }}
                tag="button"
                href="/logout"
                onClick={onSignout}
                className="font-weight-bold textColorProfileMenu"
              >
                <i className=" font-size-15" />
                {' '}
                Logout
              </DropdownItem>
            </>
          ))}

        </Media>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileMenu;
