/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable import/prefer-default-export */

import Api from './api';

export function getPayment(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = Api.get(`mercadoPago/${id}`);
      return resolve((await response).data);
    } catch (error) {
      reject(error);
    }
  });
}

export function postUsers(form) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = Api.post('users', form);
      return resolve((await response).data);
    } catch (error) {
      reject(error);
    }
  });
}

export function changePasswordLogged({ userId, newPassword, password }) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = Api.post('users/changePassword', { userId, password, newPassword });
      return resolve((await response).data);
    } catch (error) {
      reject(error);
    }
  });
}

export function changeUserData({
  userId, name, sobrenome, cellPhone, company, email, senhaAtual,
}) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = Api.post('users/updateUser', {
        userId, name, sobrenome, cellPhone, company, email, senhaAtual,
      });

      return resolve((await response).data);
    } catch (error) {
      reject(error.response.data.msg);
    }
  });
}
