/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import SelectSearch from '../../components/selectSearch';
import useTableData from '../../hooks/useTableData';
import {
  changeAllUserCategories,
  pullNewCategories,
  startCrawler,
} from '../../services/categories';
import { trackedUserCategories } from '../../services/users';
import { getCategoriesFilter, getCategoriesPage } from '../../store/categories';
import styles from './selectChangeCategory.module.scss';
import ModalMonitorar from '../welcomeStepper/components/modalMonitor/ModalMonitorar';
import gifForAlert from '../../assets/images/gifSweetAlert.gif';
import Progress from '../../components/progress';
import { ReactComponent as Close } from '../../assets/images/close_cross_icon.svg';
// import { ReactComponent as Arrow } from '../../assets/images/Arrow-right.svg';
import { ReactComponent as Voltar } from '../../assets/images/Arrow-back.svg';

const SelectChangeCategory = () => {
  const history = useHistory();
  const filters = useSelector(({ categories }) => categories.filters);
  const userId = useSelector(({ auth }) => auth.user.id);
  const [fullName, setFullName] = useState('');
  const [inputKeyword, setInputKeyword] = useState(false);
  const [idChildren, setIdChildren] = useState('');
  const [verificationButton, setVerificationButton] = useState(true);
  const [verificationButtonConfirmCategory, setVerificationButtonConfirmCategory] = useState(true);
  const concatFullName = `${fullName}  >  `;
  const formatedFullName = concatFullName.split('> ');
  const [categoriesOfUser, setCategoriesOfUser] = useState([]);
  const [newCategoriesToTrack, setNewCategoriesToTrack] = useState([]);
  const [maxCategories, setMaxCategories] = useState(null);
  const [showStartLoader, setShowStartLoader] = useState(false);
  const [modalQuestionMark, setModalQuestionMark] = useState(false);
  const [progressStatus, setProgressStatus] = useState(false);
  const returnMsg = {
    0: 'Categorias disponíveis: 0',
    1: 'Categoria disponível: 1',
  };
  const restNumber = maxCategories - categoriesOfUser.length;

  const dispatch = useDispatch();
  const {
    filtered,
    clearFilter,
    onChangeFilterCategories,
  } = useTableData({ reducer: 'categories', action: getCategoriesPage });

  function getProperty(prop) {
    if (returnMsg[prop] !== undefined) {
      return returnMsg[prop];
    }
    return `Categorias disponíveis: ${prop}`;
  }

  const getKeywordName = (key) => {
    if (!key) {
      return 'Todos Resultados ';
    }
    return key;
  };

  const popCategoryFromTrackingArray = (setArray, array, target) => {
    setArray(array.filter(
      (i) => i.id !== target.id || i.keyword !== target.keyword,
    ));
  };

  const handleClick = () => {
    setVerificationButtonConfirmCategory(false);
    clearFilter();
    dispatch(getCategoriesFilter());
    setCategoriesOfUser(
      [...categoriesOfUser,
        {
          id: idChildren,
          names: formatedFullName.filter((i) => i !== ' '),
          keyword: getKeywordName(inputKeyword),
          newCategories: true,
        },
      ],
    );
    setNewCategoriesToTrack(
      [...newCategoriesToTrack,
        {
          id: idChildren,
          keyword: getKeywordName(inputKeyword),
        },
      ],
    );
    setInputKeyword(false);
  };

  const handleClickStopTracking = (e) => {
    setVerificationButtonConfirmCategory(false);
    popCategoryFromTrackingArray(setCategoriesOfUser, categoriesOfUser, e);
    popCategoryFromTrackingArray(setNewCategoriesToTrack, newCategoriesToTrack, e);
  };

  const handleClickConfirm = async () => {
    setProgressStatus(true);
    setVerificationButtonConfirmCategory(true);
    const isDoneGettingData = await pullNewCategories(categoriesOfUser);
    await changeAllUserCategories(
      userId,
      newCategoriesToTrack,
      maxCategories,
    );
    setProgressStatus(false);
    setShowStartLoader(true);
    if (!isDoneGettingData?.itemExists) {
      await startCrawler(userId);
    }
  };

  const redirectPortal = () => {
    window.location.href = 'https://app.mercadoacelerado.com.br/portal-stripe';
  };

  const handleSweetAlert = () => {
    setTimeout(() => {
      history.push('/sales');
      setShowStartLoader({
        active: false,
      });
    }, 1200);
  };

  const back = () => {
    history.push('/categories');
  };

  async function catchUserAlreadyCategories() {
    const responseUser = await trackedUserCategories(userId);

    const arrayData = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < responseUser.categoriesData.length; i++) {
      const item = responseUser.categoriesData[i];
      arrayData.push({
        id: item.category_id,
        names: item.full_name,
        keyword: getKeywordName(item.keyword),
      });
    }
    setCategoriesOfUser(arrayData);
    setMaxCategories(responseUser.maxCategories);
    if (arrayData.length !== 0) {
      setVerificationButtonConfirmCategory(true);
    }
  }

  useEffect(() => {
    dispatch(getCategoriesFilter());
    catchUserAlreadyCategories();
  }, []);

  useEffect(() => {
    if (categoriesOfUser.length === 0) {
      setVerificationButtonConfirmCategory(true);
    }
  }, [categoriesOfUser.length]);
  return (
    <>
      <div className={styles.mainContainer}>
        <Button
          className="w-0 no-bg waves-effect waves-light"
          style={{ width: '50px' }}
          onClick={back}
        >
          <Voltar style={{ width: '25px', marginLeft: '0.5rem' }} />
        </Button>
        <div className={styles.gridMainContainer}>
          <div className={styles.leftData}>
            <div className={styles.filterCategories}>
              <div className={styles.headerSelectSearch}>
                <p>
                  Qual nova categoria deseja monitorar?
                </p>
                <Button
                  style={{
                    borderRadius: '50%',
                    backgroundColor: '#395C81',
                    width: '10px',
                    height: '25px',
                    fontWeight: '500',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '15px',
                  }}
                  onClick={() => setModalQuestionMark(true)}
                >
                  ?
                </Button>
              </div>
              <SelectSearch
                vertical
                pageSelectCategories
                filtered={filtered}
                filters={filters}
                onChangeFilterCategories={onChangeFilterCategories}
                type="categories"
                setIdChildren={setIdChildren}
                setInputKeyword={setInputKeyword}
                inputKeyword={inputKeyword}
                setFullName={setFullName}
                width="100%"
                restNumber={restNumber}
                setVerificationButton={setVerificationButton}
              />

              <Button
                onClick={handleClick}
                disabled={verificationButton}
                color="primary"
                size="lg"
                style={{
                  height: '45px',
                  width: '250px',
                  margin: '0 auto',
                  fontSize: '0.8rem',
                  marginTop: '1rem',
                  fontWeight: '650',

                }}
              >
                Continuar &gt;
              </Button>

            </div>
          </div>

          <div className={styles.rightData}>
            <div className={styles.selectCategories}>

              <p>Categorias escolhidas</p>

              {categoriesOfUser?.map((e) => (

                <div className={styles.mainDiv}>
                  <div className={styles.orangeCircle}>
                    {e.names?.map((item) => (
                      <>
                        <div className={styles.orangeInside}>
                          {item}
                        </div>
                        {'>'}
                      </>
                    ))}
                    <div
                      className={styles.orangeInside}
                      style={
                        {
                          color: 'orange',
                          wordBreak: e.keyword === 'Todos Resultados ' ? 'keep-all' : 'break-all',
                        }
                    }
                    >
                      {e.keyword}
                    </div>
                  </div>
                  {e?.newCategories && (
                  <div
                    onClick={() => handleClickStopTracking(e)}
                    style={{
                      marginLeft: '10px', color: 'orange', cursor: 'pointer', marginBottom: '0.5rem',
                    }}
                  >
                    <Close width="10px" height="10px" />
                  </div>
                  )}
                </div>
              ))}
            </div>

            <div className={styles.bottomContainer}>

              <div className={styles.numberOfCategories}>
                <p>
                  {' '}
                  Categorias monitoradas:
                  {' '}
                  {categoriesOfUser.length}
                  {' '}
                  {' '}
                  |
                  {' '}
                  <span style={{ color: '#F28500' }}>
                    {getProperty(restNumber)}
                  </span>
                </p>

              </div>
              <div className={styles.btnContainer}>
                {progressStatus
                  ? (
                    ''
                  )
                  : (
                    <Button
                      className={styles.btnPlan}
                      onClick={redirectPortal}
                    >
                      Revisar plano para mais categorias
                    </Button>
                  )}

                {progressStatus
                  ? (
                    <Progress />
                  )
                  : (
                    <Button
                      color="primary"
                      className={styles.btnConfirm}
                      onClick={handleClickConfirm}
                      disabled={verificationButtonConfirmCategory}
                    >
                      Confirmar categorias
                    </Button>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showStartLoader && (
      <SweetAlert
        custom
        title="Categorias adicionadas!"
        customIcon={gifForAlert}
        confirmBtnBsStyle="success"
        onConfirm={handleSweetAlert}
      >
        Aguarde alguns minutos enquanto trazemos os dados para você.
      </SweetAlert>
      )}
      {modalQuestionMark && (
        <ModalMonitorar ModalActive={() => setModalQuestionMark(false)} widthHelper="60%" title="">
          <div>
            <p className="fontCardModal">
              <strong style={{ color: '#567FAA' }}>Importante: </strong>
              L1 considerada a categoria Macro, para
              <br />
              informações mais assertivas sobre as vendas de mercado,
              <br />
              solicitamos que escolha a partir de L3. Caso a categoria não
              <br />
              possua níveis de sub-categorias L3 e L4, selecionar L2.
            </p>
            <div className="containerColumnsModal">
              <div className="columnsModal">
                <div className="LModal">L1</div>
                <div className="categoryModal">
                  Saúde
                </div>
              </div>
              <div className="crevronModal">&gt;</div>
              <div className="columnsModal">
                <div className="LModal">L2</div>
                <div className="categoryModal">
                  Equipamento Médico
                </div>
              </div>
              <div className="crevronModal">&gt;</div>
              <div className="columnsModal">
                <div className="LModal">L3</div>
                <div className="categoryModal">
                  Médicos
                </div>
              </div>
              <div className="crevronModal">&gt;</div>
              <div className="columnsModal">
                <div className="LModal"> L4</div>
                <div className="categoryModal">
                  Máscaras Cirurgicas
                </div>

              </div>
            </div>
          </div>
        </ModalMonitorar>
      )}
    </>
  );
};

export default SelectChangeCategory;
