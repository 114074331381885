import { toastr } from 'react-redux-toastr';
import Api from '../../services/api';

export const SalesTypes = {
  FETCH: '@sales/fetch',
  ERROR: '@sales/error',
  GET_RECEIVE: '@sales/get/receive',

  PAGE_FETCH: '@sales/page/fetch',
  PAGE_ERROR: '@sales/page/error',
  PAGE_RECEIVE: '@sales/page/receive',

  FILTERS_FETCH: '@sales/filters/fetch',
  FILTERS_ERROR: '@sales/filters/error',
  FILTERS_RECEIVE: '@sales/filters/receive',

};

let objOfAlreadyUsingFilters = '';

export const getSales = () => (dispatch) => {
  dispatch({ type: SalesTypes.FETCH });

  Api.get('/sales?limit=all')
    .then((resp) => {
      const payload = resp.data;
      dispatch({ type: SalesTypes.GET_RECEIVE, payload });
    }).catch((err) => {
      toastr.error('Falha ao carregar vendas.');
      const payload = err.response.data;
      dispatch({ type: SalesTypes.ERROR, payload });
    });
};

export const getSalesPage = ({
  page = 1,
  limit = 10,
  search = '',
  querys = [],
  order = null,
  orderby = null,
  userIdRedux,
} = {}) => (dispatch) => {
  dispatch({ type: SalesTypes.PAGE_FETCH });

  const query = [
    `limit=${limit}`,
    `page=${page}`,
    order && `order=${order}`,
    orderby && `orderby=${orderby}`,
    querys && querys.map((q) => `${q.key}=${q.value}`).join('&'),
    search && `search=${search}`,
    userIdRedux && `userIdRedux=${userIdRedux}`,
  ]
    .filter((q) => q)
    .join('&');

  Api.get(`/sales?${query}`)
    .then((resp) => {
      const payload = resp.data;
      dispatch({ type: SalesTypes.PAGE_RECEIVE, payload });
    }).catch((err) => {
      toastr.error('Falha ao carregar vendas.');
      const payload = err.response.data;
      dispatch({ type: SalesTypes.PAGE_ERROR, payload });
    });
};

export const getSalesFilter = ({ id, valueOfFilter, keyOfFilter }) => (dispatch) => {
  dispatch({ type: SalesTypes.FILTERS_FETCH });
  const { value } = valueOfFilter;

  if (value && (keyOfFilter.key === 'category_id' || keyOfFilter.key === 'week_created')) {
    const { id: idValue, keyword } = JSON.parse(value);
    objOfAlreadyUsingFilters = typeof keyword === 'string'
      ? { ...objOfAlreadyUsingFilters, [keyOfFilter.key]: idValue, keyword }
      : { ...objOfAlreadyUsingFilters, [keyOfFilter.key]: idValue };
  }

  Api.post(`/sales/filter/${id}`, { objOfAlreadyUsingFilters })
    .then((resp) => {
      const payload = resp.data;
      dispatch({ type: SalesTypes.FILTERS_RECEIVE, payload });
    }).catch((err) => {
      toastr.error('Você não possui monitoramentos ativos.');
      const payload = err.response.data;
      dispatch({ type: SalesTypes.FILTERS_ERROR, payload });
    });
};
