import { toastr } from 'react-redux-toastr';
import Api from '../../services/api';

export const TrendsTypes = {
  FETCH: '@trends/fetch',
  ERROR: '@trends/error',
  GET_RECEIVE: '@trends/get/receive',

  PAGE_FETCH: '@trends/page/fetch',
  PAGE_ERROR: '@trends/page/error',
  PAGE_RECEIVE: '@trends/page/receive',

  FILTERS_FETCH: '@trends/filters/fetch',
  FILTERS_ERROR: '@trends/filters/error',
  FILTERS_RECEIVE: '@trends/filters/receive',
};

export const getTrends = () => (dispatch) => {
  dispatch({ type: TrendsTypes.FETCH });

  Api.get('/trends?limit=all')
    .then((resp) => {
      const payload = resp.data;
      dispatch({ type: TrendsTypes.GET_RECEIVE, payload });
    }).catch((err) => {
      toastr.error('Falha ao carregar tendências.');
      const payload = err.response.data;
      dispatch({ type: TrendsTypes.ERROR, payload });
    });
};

export const getTrendsPage = ({
  page = 1,
  limit = 30,
  search = '',
  querys = [],
} = {}) => (dispatch) => {
  dispatch({ type: TrendsTypes.PAGE_FETCH });

  const query = [
    `limit=${limit}`,
    `page=${page}`,
    querys && querys.map((q) => `${q.key}=${q.value}`).join('&'),
    search && `search=${search}`,
  ]
    .filter((q) => q)
    .join('&');

  Api.get(`/trends?${query}`)
    .then((resp) => {
      const payload = resp.data;
      dispatch({ type: TrendsTypes.PAGE_RECEIVE, payload });
    }).catch((err) => {
      toastr.error('Falha ao carregar tendências.');
      const payload = err.response.data;
      dispatch({ type: TrendsTypes.PAGE_ERROR, payload });
    });
};

export const getTrendsFilter = () => (dispatch) => {
  dispatch({ type: TrendsTypes.FILTERS_FETCH });

  Api.get('/trends/filters')
    .then((resp) => {
      const payload = resp.data;
      dispatch({ type: TrendsTypes.FILTERS_RECEIVE, payload });
    }).catch((err) => {
      toastr.error('Você não possui monitoramentos ativos.');
      const payload = err.response.data;
      dispatch({ type: TrendsTypes.FILTERS_ERROR, payload });
    });
};
