/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  Col,
  Card,
  CardBody,
  Button,
} from 'reactstrap';
import Media from 'react-media';
import {
  getCategoriesFilter,
} from '../../store/categories';
import { getLoaders, postCreateLoader } from '../../store/loaders';
import Table from '../../components/table';
import TextSearch from '../../components/textSearch';
import SelectSearch from '../../components/selectSearch';
import ExportTable from '../../components/exportTable';
import useTableData from '../../hooks/useTableData';
import './competition.css';
import ModalComponent from '../../components/modal/ModalComponent';
import {
  getFaturamento,
  getStatus,
  getStripeSessionById,
  portal,
  resetCategories,
} from '../../services/users';
import { categoriesOfUser, clearCategories } from '../../store/auth/auth.action';
import { restoreAuth } from '../../store/auth';
import gifStopTracking from '../../assets/images/gifdesligando.gif';
import switchGif from '../../assets/images/gifSweetAlert.gif';
import { getSellerConfigPage } from '../../store/sellerconfig';
import { deleteTrackedSeller, numberOfTrackedSellers } from '../../services/seller';

const TrackingData = ({
  item,
  setItem,
  onRequest,
  setNotAllowedDeleteCategory,
  setIsCategoryDeleted,
}) => {
  const [isFetchingSwitch, setIsFetchingSwitch] = useState();

  const onChangeSwitch = async () => {
    setIsFetchingSwitch(true);
    const response = await deleteTrackedSeller(item.user_id, parseInt(item.seller_id, 10));
    if (!response.changeCategory) {
      setNotAllowedDeleteCategory({ isActive: true, response });
      setIsFetchingSwitch(false);
      return;
    }
    setItem(false);
    setItem(null);
    setIsCategoryDeleted(true);
    onRequest();
  };

  return (
    <>
      <td>
        <Switch
          id={item.seller_id}
          onColor="#30E035"
          onChange={onChangeSwitch}
          checked={item.seller_id}
          disabled={isFetchingSwitch}
        />
      </td>
    </>
  );
};

TrackingData.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tracking: PropTypes.number.isRequired,
  }).isRequired,
  setItem: PropTypes.func.isRequired,
};

const isCategoryLoading = createSelector(
  (state) => state.loaders,
  (_, category) => category,
  (state, category) => {
    const loaders = state.list;
    const curCategory = loaders?.find(
      (loader) => loader.category_id === category.id,

    );
    return Boolean(curCategory?.status === 'loading');
  },
);

const LoadCategory = ({ item, showAlert }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => isCategoryLoading(state, item));

  const onLoadClick = useCallback(() => {
    dispatch(postCreateLoader({ categoryId: item.id }));
    showAlert();
  }, [dispatch, item, showAlert]);

  return (
    <>
      {isLoading ? (
        <Button color="primary" disabled>
          <i className="ti-reload" />
        </Button>
      ) : (
        // eslint-disable-next-line react/jsx-indent
        <Button color="primary" onClick={onLoadClick}>
          <i className="ti-download" />
        </Button>
        // eslint-disable-next-line indent
      )}
    </>
  );
};

LoadCategory.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  showAlert: PropTypes.func.isRequired,
};

const Competition = () => {
  const [trackingItem, setTrackingItem] = useState(null);
  const dispatch = useDispatch();
  const { filters } = useSelector(({ categories }) => categories);
  const userIdRedux = useSelector(({ auth }) => auth.user.id);
  const [categorieCount, setCategorieCount] = useState();
  const [maxCategory, setMaxCategory] = useState();
  const [modalChangePlan, setModalChangePlan] = useState(false);
  const [modalChangePlanDown, setModalChangePlanDown] = useState(false);
  const [sweetAlertError, setSweetAlertError] = useState(false);
  const [isCategoryDeleted, setIsCategoryDeleted] = useState(false);
  const [notAllowedDeleteCategory, setNotAllowedDeleteCategory] = useState(false);
  const [alertErrorStripePortal, setAlertErrorStripePortal] = useState(false);
  const [responseFaturamento, setResponseFaturamento] = useState();
  const [disabledButton, setDisabledButton] = useState(true);

  const {
    rows,
    isFetching,
    paging,
    ordering,
    searchInput,
    filtered,
    setSearch,
    onChangeSearch,
    onChangeFilter,
    onKeyDown,
    onCancelSearch,
    onChangeOrder,
    onPageChange,
    getExportUrl,
    onRequest,
  } = useTableData({ reducer: 'sellerConfig', action: getSellerConfigPage });

  const userEmail = useSelector(({ auth }) => auth.user.email);
  const userSession = useSelector(({ auth }) => auth.user.sessionID);
  const userPlan = useSelector(({ auth }) => auth.user.plan);
  const token = useSelector(({ auth }) => auth.token);
  const userId = useSelector(({ auth }) => auth.user.id);

  async function getSellerCount() {
    const {
      numberOfTrackedSellers: trackedSeller,
      maxSellers,
    } = await numberOfTrackedSellers(userId);
    setCategorieCount(trackedSeller);
    setMaxCategory(maxSellers);
    setDisabledButton(false);
  }

  async function faturamento() {
    try {
      const response = await getFaturamento(userEmail);
      setResponseFaturamento(response);
      const cancelPlan = response?.user.plan === 0;
      const upPlan = response?.user.plan > userPlan;
      const downPlan = response?.user.plan < userPlan;

      if (cancelPlan) {
        window.location.replace('/reactivate-account');
      }
      setModalChangePlan(upPlan);
      setModalChangePlanDown(downPlan);
    } catch (error) {
      return;
    } finally {
      getSellerCount();
    }
  }

  async function getStripeSession() {
    await getStripeSessionById(userSession);
  }
  async function status() {
    await getStatus(userEmail);
  }

  const resetSwitch = async () => {
    dispatch(restoreAuth({ user: responseFaturamento.user, token }));
    dispatch(clearCategories);
    await resetCategories(userIdRedux);
    setTimeout(() => {
      window.location.replace('/select-change-category');
      setModalChangePlanDown(false);
    }, 1000);
  };

  let response;
  const portalClick = async () => {
    setDisabledButton(true);
    const user = JSON.parse(sessionStorage.getItem('@userAuth')) ?? JSON.parse(localStorage.getItem('@userAuth'));
    const { email } = user.user;
    try {
      if (maxCategory > 0) {
        window.location.replace('/select-change-seller ');
        return;
      }
      response = await portal(email, userPlan);
      window.location = response;
    } catch (err) {
      setAlertErrorStripePortal(true);
      setDisabledButton(false);
    }
  };

  const handlePortalError = () => {
    window.location.replace('/first-steps');
    setAlertErrorStripePortal(false);
  };

  const formatedCreateAt = ({ item }) => (
    <td>
      {item.created_at
      && item.created_at.split('T')[0].split('-').reverse().join('/')}
    </td>
  );

  const totalItems = ({ item }) => {
    if (item.total_itens) {
      const formatedItemQuantity = item.total_itens.toLocaleString('pt-BR');
      return <td>{formatedItemQuantity}</td>;
    }
    return <td> - </td>;
  };

  const columns = useMemo(
    () => [
      { id: 'seller_id', title: 'ID' },
      { id: 'seller_name', title: 'Nome' },
      { id: 'total_itens', title: 'Total itens', Component: totalItems },
      { id: 'created_at', title: 'Monitorando desde', Component: formatedCreateAt },
      {
        id: 'user_id',
        title: 'Monitorando',
        Component: ({ item, column }) => (
          <TrackingData
            item={item}
            column={column}
            setItem={setTrackingItem}
            userId={userIdRedux}
            onRequest={onRequest}
            getCategoriesCount={() => getSellerCount()}
            setNotAllowedDeleteCategory={setNotAllowedDeleteCategory}
            setIsCategoryDeleted={setIsCategoryDeleted}
          />
        ),
      },
    ],
    [],
  );

  const handleClickError = async () => {
    setSweetAlertError(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const user = JSON.parse(localStorage.getItem('@userAuth'));
    const { email } = user.user;
    const res = await portal(email);
    window.location = res;
  };

  const handleClickModal = () => {
    dispatch(restoreAuth({ user: responseFaturamento.user, token }));
    setTimeout(() => {
      setModalChangePlan(false);
      window.onscroll = () => {};
      document.body.style.overflow = 'visible';
      window.location.replace('/select-change-seller');
    }, 1000);
  };

  useEffect(() => {
    if (userPlan === null) {
      window.location.replace('/first-steps');
    }
  }, []);

  useEffect(() => {
    dispatch(getLoaders());
    dispatch(getCategoriesFilter());
    dispatch(categoriesOfUser(userIdRedux));
  }, [trackingItem]);

  useEffect(() => {
    getStripeSession();
    setTimeout(async () => {
      if (userEmail) {
        await status();
        await faturamento();
      }
    }, 500);
  }, [userEmail]);

  useEffect(() => {
    getStripeSession();
  }, [userEmail]);

  useEffect(() => {
    if (modalChangePlan || modalChangePlanDown) {
      window.onscroll = () => {
        window.scrollTo(0, 0);
      };
      document.body.style.overflow = 'hidden';
    } else {
      window.onscroll = () => {};
      document.body.style.overflow = 'visible';
    }
  }, [modalChangePlan || modalChangePlanDown]);

  return (
    <>
      <Media query={{ minWidth: '780px' }}>
        {(matches) => (matches ? (
          <>

            <Col xs="12" style={{ maxWidth: '65.6rem', disoverflowY: modalChangePlan || modalChangePlanDown ? 'hidden' : 'inherit' }}>
              <h3 style={{ color: '#567faa', fontWeight: 'bold' }}>
                Os vendedores monitorados
              </h3>

              <div style={{ display: 'flex' }}>
                <Card xs="4">
                  <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    background: '#567faa',
                    height: '60px',
                    borderRadius: '10px',
                  }}
                  >
                    <TextSearch
                      label="Buscar"
                      searchInput={searchInput}
                      setSearch={setSearch}
                      onKeyDown={onKeyDown}
                      onChangeSearch={onChangeSearch}
                      onCancelSearch={onCancelSearch}
                    />
                  </CardBody>
                </Card>
                <div className="divAlignCategories" style={{ flex: 4 }}>
                  <div className="divMainCategories">
                    <p className="pMoreCategories">
                      Vendedores monitorados:
                      {' '}
                      {categorieCount}
                    </p>
                    <p>

                      {' '}
                      |
                    </p>
                    <p className="pMoreCategoriesSpan">

                      <span className="spanMoreCategories">
                        {' '}

                        Vendedores disponíveis:
                        {' '}
                        {maxCategory}
                      </span>
                    </p>

                    <Button
                      disabled={disabledButton}
                      style={{ fontWeight: 'bold', fontSize: '0.8rem' }}
                      color="primary"
                      className="ml-2 mb-2 btnMoreCategories"
                      onClick={portalClick}
                    >
                      Clique para monitorar novos vendedores

                    </Button>
                  </div>
                </div>
              </div>
              <Card>
                <CardBody>
                  <Table
                    showPaging
                    columns={columns}
                    rows={rows}
                    isFetching={isFetching}
                    paging={paging}
                    onPageChange={onPageChange}
                    ordering={ordering}
                    onChangeOrder={onChangeOrder}
                  />
                </CardBody>
              </Card>
            </Col>

            {sweetAlertError && (
            <SweetAlert
              error
              showCancel
              title="Você atingiu o limite de vendedores monitorados disponíveis"
              confirmBtnText="Monitorar mais vendedores"
              confirmBtnBsStyle="primary"
              confirmBtnStyle={{ border: 'none' }}
              focusConfirmBtn={false}
              cancelBtnText="Voltar"
              cancelBtnBsStyle="gray"
              cancelBtnStyle={{ border: '1px solid gray' }}
              onConfirm={handleClickError}
              onCancel={() => setSweetAlertError(false)}
            >
              Para acompanhar as vendas de mais vendedores, faça o upgrade do seu plano atual.
            </SweetAlert>
            )}
            {alertErrorStripePortal && (
            <SweetAlert
              error
              title="Erro ao associar seu e-mail ao pagamento"
              confirmBtnText="Certo"
              confirmBtnBsStyle="primary"
              confirmBtnStyle={{ border: 'none' }}
              focusConfirmBtn={false}
              onConfirm={handlePortalError}
            >
              Seu email não foi encontrado como um usuário pagador em nossa plataforma,
              você será direcionado para preencher o onboarding novamente.
              <br />
              <br />
              <br />
              Caso já tenha feito o pagamento,
              {' '}
              <br />
              {' '}
              entre em contato no e-mail:
              <address>
                <a
                  href="mailto:suporte@mercadoacelerado.com.br"
                >
                  suporte@mercadoacelerado.com.br
                </a>
              </address>
            </SweetAlert>
            )}
            {notAllowedDeleteCategory.isActive && (
            <SweetAlert
              error
              title={notAllowedDeleteCategory.response.msg}
              confirmBtnText="Entendi!"
              confirmBtnBsStyle="primary"
              confirmBtnStyle={{ border: 'none' }}
              focusConfirmBtn={false}
              onConfirm={() => setNotAllowedDeleteCategory(false)}
            >
              Aguarde até o dia
              {' '}
              {notAllowedDeleteCategory.response.date}
              {' '}
              para atualizar suas configurações ou faça o upgrade do seu
              plano para mais vendedores.
            </SweetAlert>
            )}
            {isCategoryDeleted && (
            <SweetAlert
              custom
              title="Vendedor removido!"
              customIcon={gifStopTracking}
              confirmBtnBsStyle="success"
              onConfirm={() => setIsCategoryDeleted(false)}
            >
              Suas configurações foram atualizadas com sucesso.
            </SweetAlert>
            )}
          </>
        ) : (
          <>
            <Col xs="12">
              <Card>
                <CardBody>

                  <SelectSearch
                    filtered={filtered}
                    filters={filters}
                    onChangeFilter={onChangeFilter}
                  />

                  <ExportTable path="categories" getExportUrl={getExportUrl} />

                  <Table
                    showPaging
                    columns={columns}
                    rows={rows}
                    isFetching={isFetching}
                    paging={paging}
                    onPageChange={onPageChange}
                    ordering={ordering}
                    onChangeOrder={onChangeOrder}
                  />
                </CardBody>
              </Card>
            </Col>
            {sweetAlertError && (
            <SweetAlert
              error
              showCancel
              title="Você atingiu o limite de vendedores monitorados disponíveis"
              confirmBtnText="Monitorar mais vendedores"
              confirmBtnBsStyle="primary"
              confirmBtnStyle={{ border: 'none' }}
              focusConfirmBtn={false}
              cancelBtnText="Voltar"
              cancelBtnBsStyle="gray"
              cancelBtnStyle={{ border: '1px solid gray' }}
              onConfirm={handleClickError}
              onCancel={() => setSweetAlertError(false)}
            >
              Para acompanhar as vendas de mais vendedores, faça o upgrade do seu plano atual.
            </SweetAlert>
            )}

            {alertErrorStripePortal && (
            <SweetAlert
              error
              title="Erro ao associar seu e-mail ao pagamento"
              confirmBtnText="Certo"
              confirmBtnBsStyle="primary"
              confirmBtnStyle={{ border: 'none' }}
              focusConfirmBtn={false}
              onConfirm={handlePortalError}
            >
              Seu email não foi encontrado como um usuário pagador em nossa plataforma,
              você será direcionado para preencher o onboarding novamente.
              <br />
              <br />
              <br />
              Caso já tenha feito o pagamento,
              {' '}
              <br />
              {' '}
              entre em contato no e-mail:
              <address>
                <a
                  href="mailto:atendimento@mercadoacelerado.com.br"
                >
                  atendimento@mercadoacelerado.com.br
                </a>
              </address>
            </SweetAlert>
            )}
            {notAllowedDeleteCategory.isActive && (
            <SweetAlert
              error
              title={notAllowedDeleteCategory.response.msg}
              confirmBtnText="Entendi!"
              confirmBtnBsStyle="primary"
              confirmBtnStyle={{ border: 'none' }}
              focusConfirmBtn={false}
              onConfirm={() => setNotAllowedDeleteCategory(false)}
            >
              Aguarde até o dia
              {' '}
              {notAllowedDeleteCategory.response.date}
              {' '}
              para atualizar suas configurações faça o upgrade do seu
              plano para mais vendedores.
            </SweetAlert>
            )}
            {isCategoryDeleted && (
            <SweetAlert
              custom
              title="Vendedor removido!"
              customIcon={gifStopTracking}
              confirmBtnBsStyle="success"
              onConfirm={() => setIsCategoryDeleted(false)}
            >
              Suas configurações foram atualizadas com sucesso.
            </SweetAlert>
            )}
          </>
        ))}
      </Media>

      {
      modalChangePlan && (
        <ModalComponent
          notShowHeader
          ModalActive={() => setModalChangePlan(true)}
          width="55%"
          height="55%"
        >
          <div className="mainChangePlan">
            <h2 className="h2ChangePlan">
              Você alterou o seu plano de vendedores monitorados
            </h2>
            <div className="divPChangePlan">
              <p className="pChangePlan">
                Revise agora mesmo e escolha seu(s) vendedores(s)
                para não perder nenhum dado
                relevante para o seu negócio digital!
              </p>

            </div>
            <Button
              className="btnChangePlan"
              color="primary"
              size="lg"
              type="submit"
              onClick={handleClickModal}
              block
            >
              Entendi
            </Button>
          </div>
        </ModalComponent>
      )
}

      {
      modalChangePlanDown && (
        <ModalComponent
          notShowHeader
          ModalActive={() => setModalChangePlanDown(true)}
          width="55%"
          height="55%"
        >
          <div className="mainChangePlan">
            <h2 className="h2ChangePlan">
              Você reduziu o número de vendedores monitorados.
            </h2>

            <div className="divPChangePlan">
              <p className="pChangePlan">
                Todos os monitoramentos foram desativados.
                Escolha novamente a(s) vendedores(es) que deseja monitorar.
              </p>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1.5rem' }}>
                <img src={switchGif} alt="" width={120} />
              </div>

            </div>
            <Button
              className="btnChangePlan"
              color="primary"
              size="lg"
              type="submit"
              onClick={resetSwitch}
              block
            >
              Entendi
            </Button>
          </div>
        </ModalComponent>
      )
    }
    </>

  );
};

export default Competition;
